/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import { useState, useEffect, useReducer } from 'react';
// Material Dashboard 2 React example components
import { Card, CircularProgress, Grid, Tooltip } from '@mui/material';
// import ResaleModal from 'components/ResaleModal';
import { useSelector } from 'react-redux';

// import Footer from '../../examples/Footer';
import { Link, useLocation } from 'react-router-dom';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import './style.css';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { CheckCircle, DownloadSimple, XCircle } from 'phosphor-react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import MDButton from 'components/MDButton';
import PaymentEvent from '../PaymentEvent';
import PaymentContest from '../PaymentContest/index.jsx';
import axios from 'axios';
import { baseUrl } from 'utils/base_url';
import fileDownload from 'js-file-download';

export default function TableHistoricPayment() {
  const [search, setSearch] = useState('');
  const [paymentListFiltred, setPaymentListFiltred] = useState([]);
  const [isFiltred, setIsFiltred] = useState(false);

  const role = useSelector((state) => state.auth.user.role);

  const myRows = [];
  let location = useLocation();
  location = location.state;

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  function handleSearchEvent(e) {
    if (e === 'Enter') {
      const paymentsFiltred = location.PaymentTrack.filter((payment) => {
        return payment.Status.toLowerCase().includes(search.toLowerCase());
      });

      if (search !== '') {
        setIsFiltred(true);
        successNotify('Filtro aplicado com sucesso!');
      } else {
        setIsFiltred(false);
      }
      setPaymentListFiltred(paymentsFiltred);
    }
  }

  function statusChanged(status) {
    if (status === 'analise') {
      return { message: 'Análise', class: 'payment pagamentoAnalise' };
    }

    if (status === 'pendente') {
      return { message: 'Pendente', class: 'payment pagamentoPendente' };
    }

    if (status === 'reprovado') {
      return { message: 'Recusado', class: 'payment pagamentoRecusado' };
    }

    if (status === 'confirmado') {
      return { message: 'Confirmado', class: 'payment pagamentoConfirmado' };
    }
    if (status === 'pago') {
      return { message: 'Pago', class: 'payment pagamentoPago' };
    }
    if (status === 'contestado') {
      return { message: 'Contestado', class: 'payment pagamentoRecusado' };
    }
    return { message: '', class: 'payment pagamentoPendente' };
  }

  function myTableData() {
    return {
      columns: [
        { Header: 'Tarefa', accessor: 'tarefa', width: '10%', align: 'left' },
        { Header: 'Status', accessor: 'status', width: '10%', align: 'left' },
        { Header: 'início', accessor: 'inicio', width: '10%', align: 'left' },
        { Header: 'Conclusão', accessor: 'conclusao', width: '10%', align: 'left' },
        { Header: 'Observações', accessor: 'note', width: '30%', align: 'left' },
        { Header: 'Operator', accessor: 'operator', width: '10%', align: 'left' },
        { Header: '', accessor: 'action', width: '10%', align: 'center' },
      ],
      rows: myRows,
    };
  }

  const downloadNF = (item) => {
    axios
      .get(`${baseUrl}/google/download/${item}`)
      .then((res) => {
        window.location.assign(res.data.webContentLink);
      })
      .then((err) => {
        console.log(err);
      });
  };

  const downloadCP = (item) => {
    axios
      .get(`${baseUrl}/google/download/${item}`)
      .then((res) => {
        window.location.assign(res.data.webContentLink);
      })
      .then((err) => {
        console.log(err);
      });
  };
  const downloadContestado = (item) => {
    axios
      .get(`${baseUrl}/google/download/${item}`)
      .then((res) => {
        window.location.assign(res.data.webContentLink);
      })
      .then((err) => {
        console.log(err);
      });
  };

  function renderMyRows(item) {
    return myRows.push({
      tarefa: (
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
            {item.track}
          </MDTypography>
        </MDBox>
      ),
      inicio: (
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
            {item.createdAt.split('T')[0].split('-').reverse().join('/')}
          </MDTypography>
        </MDBox>
      ),
      status: (
        <MDBox>
          <div
            style={{ fontWeight: 'bold', variant: 'caption', fontSize: '16px', color: 'white' }}
            className={statusChanged(item.Status).class}
          >
            {statusChanged(item.Status).message}
          </div>
        </MDBox>
      ),
      conclusao: (
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
            {item.updatedAt ? item.updatedAt?.split('T')[0].split('-').reverse().join('/') : ''}
          </MDTypography>
        </MDBox>
      ),
      note: (
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
            {item.note ? item.note : 'Sem observações'}
          </MDTypography>
        </MDBox>
      ),
      operator: (
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
            {item.opertaror.full_name}
          </MDTypography>
        </MDBox>
      ),
      action: (
        <MDBox width="100%" display="flex" alignItems="flex-end" flexDirection="column" border>
          <MDBox display="flex" alignItems="right" gap={1}>
            {item.Status === 'pendente' &&
            location.status === 'pendente' &&
            role === 'resaleAdmin' ? (
              <PaymentEvent payment={item} resaleID={location.id} />
            ) : null}
            {item.Status === 'analise' && location.status === 'analise' && role === 'admin' ? (
              <PaymentEvent payment={item} resaleID={location.id} />
            ) : null}
            {location.url_nf && item.Status === 'pendente' ? (
              <span>
                <MDButton
                  sx={{ ml: 0.5 }}
                  size="small"
                  variant="gradient"
                  color="success"
                  onClick={() => downloadNF(location.url_nf)}
                  iconOnly
                >
                  <Tooltip title="Download da Nota Fiscal">
                    <DownloadSimple weight="fill" size={16} />
                  </Tooltip>
                </MDButton>
              </span>
            ) : null}
            {item.Status === 'contestado' &&
            location.status === 'contestado' &&
            role === 'admin' ? (
              <span>
                <PaymentContest
                  payment={item}
                  value={location.value}
                  resaleId={location.resale_id}
                />
              </span>
            ) : null}
            {location.url_cp && item.Status === 'pago' ? (
              <span>
                <MDButton
                  sx={{ ml: 0.5 }}
                  size="small"
                  variant="gradient"
                  color="success"
                  onClick={() => downloadCP(location.url_cp)}
                  iconOnly
                >
                  <Tooltip title="Download da Comprovante de Pagamento">
                    <DownloadSimple weight="fill" size={16} />
                  </Tooltip>
                </MDButton>
              </span>
            ) : null}
            {location.url_fl && item.Status === 'contestado' ? (
              <span>
                <MDButton
                  sx={{ ml: 0.5 }}
                  size="small"
                  variant="gradient"
                  color="success"
                  onClick={() => downloadContestado(location.url_fl)}
                  iconOnly
                >
                  <Tooltip title="Download da Comprovante de Pagamento">
                    <DownloadSimple weight="fill" size={16} />
                  </Tooltip>
                </MDButton>
              </span>
            ) : null}
          </MDBox>
        </MDBox>
      ),
    });
  }

  if (isFiltred) {
    paymentListFiltred.forEach((item) => {
      renderMyRows(item);
    });
  } else {
    if (location.PaymentTrack.length > 0) {
      location.PaymentTrack.forEach((item) => {
        renderMyRows(item);
      });
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <MDBox mt={6} mb={3}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={1}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="info"
              display={{ sm: 'flex' }}
              alignItems={{ sm: 'center' }}
              justifyContent={{ sm: 'space-between' }}
            >
              <MDTypography variant="h6" color="white">
                Histórico
              </MDTypography>
              <div className="select" style={{ width: 'auto', marginRight: '30px' }}>
                <input
                  id="myInput"
                  className="myInput"
                  placeholder="Pagamentos..."
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={(e) => handleSearchEvent(e.key)}
                />
              </div>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={myTableData()}
                isSorted={true}
                entriesPerPage={false}
                noEndBorder
                pagination={{ variant: 'gradient', color: 'warning' }}
              />
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
