/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable */
import { Modal, Tooltip } from '@mui/material';

import axios from 'axios';
import { CheckCircle, Eye, Pencil, Question, Television, XCircle } from 'phosphor-react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { baseUrl } from '../../utils/base_url';
import MDButton from '../MDButton';

import './programmerStyle.css';
import FileUpload from 'react-material-file-upload';

export default function ProgrammerModal({
  data,
  title,
  variant,
  color,
  iconVariant,
  className,
  btnText,
  handleListProgrammsChange,
  modalOpen = false,
  showListGroups = true,
  handleEditProgrammer = () => ({}),
}) {
  const role = useSelector((state) => state.auth.user.role);
  const [programmerName, setProgrammerName] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [programmerImgUrl, setProgrammerImgUrl] = useState([]);
  const [programmerGroupChannel, setprogrammerGroupChannel] = useState([]);
  const [channelGroups, setChannelGroups] = useState([]);
  const [imgUpload, setImageUpload] = useState([]);
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  const isEditing = data?.id;

  const handleOpen = () => {
    setOpen(true);
    setProgrammerName(data?.name ? data.name : '');
    setProgrammerImgUrl(data?.url ? data.url : '');
    setprogrammerGroupChannel(data?.ChannelGroup ? data.ChannelGroup : '');
    setChannelGroups([]);
    setFormErrors({
      name: false,
      imgUrl: false,
    });
  };
  const handleClose = () => {
    setOpen(false);
    setProgrammerName('');
    setProgrammerImgUrl('');
    setprogrammerGroupChannel([]);
    setChannelGroups([]);
    handleEditProgrammer();
    setFormErrors({});
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  function handleProgrammer() {
    if (programmerName?.length < 3) {
      errorNotify('Nome deve conter ao menos 3 caracteres');
      document.getElementById('nome').focus();
      setFormErrors({ ...formErrors, name: true });
      return;
    }
    setFormErrors({ ...formErrors, name: false });

    const formData = new FormData();
    formData.append('file', imgUpload ? imgUpload[0] : undefined);

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        console.log(`Current progress:`, Math.round((event.loaded * 100) / event.total));
      },
    };
    if (imgUpload.length > 0) {
      axios.post(`${baseUrl}/google/upload`, formData, config).then((response) => {
        if (isEditing !== undefined) {
          axios
            .patch(`${baseUrl}/tv-programmer`, {
              id: data.id,
              name: programmerName,
              url: response.data.fileId.thumbnailLink,
              // ChannelGroup: programmerGroupChannel.map((item) => item.id),
            })
            .then((res) => {
              if (res.data.status === 400) {
                handleListProgrammsChange({
                  message: res.data.message,
                  success: false,
                });
              } else {
                handleListProgrammsChange({
                  message: res.data.message,
                  success: true,
                });
              }
              setTimeout(() => {
                handleClose();
              }, 500);
            })
            .catch((error) => console.log(error));
        } else {
          axios
            .post(`${baseUrl}/tv-programmer`, {
              name: programmerName,
              url: imgUpload,
              ChannelGroup: programmerGroupChannel.map((item) => item.id),
            })
            .then((res) => {
              if (res.data.status === 400) {
                handleListProgrammsChange({
                  message: res.data.message,
                  success: true,
                });
              } else {
                handleListProgrammsChange({
                  message: res.data.message,
                  success: true,
                });
              }
              setTimeout(() => {
                handleClose();
              }, 500);
            })
            .catch((error) => {
              handleListProgrammsChange({
                message: error.data.message,
                success: false,
              });
            });
        }
      });
    } else {
      if (isEditing !== undefined) {
        axios
          .patch(`${baseUrl}/tv-programmer`, {
            id: data.id,
            name: programmerName,
            url: programmerImgUrl,
            // ChannelGroup: programmerGroupChannel.map((item) => item.id),
          })
          .then((res) => {
            if (res.data.status === 400) {
              handleListProgrammsChange({
                message: res.data.message,
                success: false,
              });
            } else {
              handleListProgrammsChange({
                message: res.data.message,
                success: true,
              });
            }
            setTimeout(() => {
              handleClose();
            }, 500);
          })
          .catch((error) => console.log(error));
      } else {
        axios
          .post(`${baseUrl}/tv-programmer`, {
            name: programmerName,
            url: programmerImgUrl,
            // ChannelGroup: programmerGroupChannel.map((item) => item.id),
          })
          .then((res) => {
            if (res.data.status === 400) {
              handleListProgrammsChange({
                message: res.data.message,
                success: true,
              });
            } else {
              handleListProgrammsChange({
                message: res.data.message,
                success: true,
              });
            }
            setTimeout(() => {
              handleClose();
            }, 500);
          })
          .catch((error) => {
            handleListProgrammsChange({
              message: error.data.message,
              success: false,
            });
          });
      }
    }
  }

  useEffect(() => {
    axios.get(`${baseUrl}/channel-groups`).then((group) => {
      if (channelGroups?.length > 0) {
        group.data.payload.filter((item) => item.id !== channelGroups?.id);
      }
      setChannelGroups(group.data.payload);
    });
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <span>
        {role !== 'operatorTubaron' && (
          <MDButton
            sx={{ ml: 0.5 }}
            ize="medium"
            variant="gradient"
            color={color ? color : 'info'}
            onClick={handleOpen}
            iconOnly={iconVariant === 'edit'}
          >
            {iconVariant === 'edit' ? <Pencil weight="fill" size={16} /> : null}
            {iconVariant === 'see' ? <Eye weight="fill" size={20} /> : null}
            {btnText}
          </MDButton>
        )}
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ position: 'absolute', top: '0' }}
      >
        <form action="" className="operatorForm">
          <SnackbarProvider
            iconVariant={{
              success: (
                <CheckCircle
                  size={16}
                  color="#ffffff"
                  weight="fill"
                  style={{ marginRight: '10px' }}
                />
              ),
              error: (
                <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
              ),
            }}
            dense
            autoHideDuration={2500}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
          <div className="formHeader">
            <div className="formLogo">
              <Television size={28} color="#ffffff" weight="fill" />
            </div>
          </div>
          <h2 className="modalTitle">{title}</h2>
          <div className="programmerGrid">
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="nome">Nome: </label>
              <input
                value={programmerName}
                type="text"
                id="nome"
                className={` inputField ${formErrors.name ? 'error' : ''}`}
                onChange={
                  (e) => setProgrammerName(e.target.value)
                  // dispatch(setTariffFormState({ ...tariffForm, name: e.target.value }))
                }
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label
                htmlFor="img"
                style={{
                  marginBottom: 3,
                }}
                className="toolTip"
              >
                Logo do Programadora
                <Tooltip title="adicionar o logo da programadora">
                  <Question size={20} color="#ffffff" weight="fill" />
                </Tooltip>
              </label>
              <label style={{ padding: '0.5rem' }}></label>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {openUpload ? (
                  <MDButton
                    onClick={() => setOpenUpload(false)}
                    className="formBtn"
                    color="error"
                    variant="contained"
                  >
                    Fechar
                  </MDButton>
                ) : (
                  <MDButton
                    onClick={() => setOpenUpload(true)}
                    className="formBtn"
                    color="info"
                    variant="contained"
                  >
                    Upload
                  </MDButton>
                )}
              </div>
              {openUpload ? (
                <FileUpload
                  value={imgUpload}
                  id="nfUrl"
                  title="Arraste e solte seu comprovante, ou clique no botão para selecionar o arquivo."
                  sx={{ borderColor: '#fff', color: '#fff', marginTop: '0.5rem' }}
                  typographyProps={{ color: '#fff' }}
                  onChange={setImageUpload}
                  style={{ cursor: role !== 'resaleAdmin' ? 'not-allowed' : 'auto' }}
                />
              ) : null}
            </div>
          </div>
          <div className="buttonsCreateTariff">
            <MDButton
              onClick={() => handleClose()}
              className="formBtn"
              color="error"
              variant="contained"
            >
              Cancelar
            </MDButton>

            <MDButton
              onClick={() => handleProgrammer()}
              className="formBtn"
              color="success"
              variant="contained"
            >
              {isEditing ? 'Editar' : 'Criar'}
            </MDButton>
          </div>
        </form>
      </Modal>
    </div>
  );
}
