/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */

import { useContext, useState } from 'react';

import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
// import bgImage from '../../../assets/images/bg-sign-in-basic.jpeg';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { baseUrl } from 'utils/base_url';
import { selectDashboard, changeDashboard } from 'redux/dashboardSlice';
import { changeOrders } from 'redux/ordersSlice';
import { decodeToken } from 'react-jwt';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { CheckCircle, XCircle } from 'phosphor-react';
import { CircularProgress } from '@mui/material';
import MDButton from '../../../components/MDButton';
import MDInput from '../../../components/MDInput';
import MDTypography from '../../../components/MDTypography';
import BasicLayout from '../components/BasicLayout';

import logoImg from '../../../assets/images/tubaplayLogo.svg';

import MDBox from '../../../components/MDBox';
import { AuthContext } from '../../../context/auth';

import singBackImg from '../../../assets/images/BackTubaPlay.png';
import {
  setIsLoggedState,
  setTokenState,
  setUserState,
} from '../../../features/authentication/authSlice';

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const [passwordForgot, setPasswordForgot] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  function clearToken() {
    sessionStorage.removeItem('token');
    dispatch(setIsLoggedState(false));
    dispatch(setTokenState(null));
    dispatch(setUserState(null));
  }

  function setToken(token) {
    dispatch(setTokenState(token));
    dispatch(setIsLoggedState(true));
    const getDecodedToken = decodeToken(token);
    dispatch(setUserState(getDecodedToken));

    // eslint-disable-next-line dot-notation
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const initialState = {
    username: '',
    password: '',
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const [auth, setAuth] = useState(initialState);

  const { signIn } = useContext(AuthContext);

  async function thisSignIn(username, password) {
    const url = `${baseUrl}/login`;
    await axios
      .post(url, { username, password })
      .then((res) => {
        localStorage.setItem('token', res.data.access_token);
        setToken(res.data.access_token);
      })
      .catch((error) => {
        errorNotify(error.response.data);
      });
  }

  async function handlePasswordForgot(password = '') {
    const url = `${baseUrl}/forgot-password`;
    setLoading(true);
    await axios
      .post(url, { username: email, password })
      .then((res) => {
        successNotify(
          'Se Você tiver uma conta, você receberá um email com as instruções para redefinir sua senha'
        );
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          navigate('/sign-in');
        }, 1000);
      });
  }

  const handleSign = async () => {
    await thisSignIn(auth.username, auth.password);
    await axios.get(`${baseUrl}/dashboard`).then((data) => dispatch(changeDashboard(data.data)));
    await axios.get(`${baseUrl}/customer`).then((data) => {
      dispatch(changeOrders(data.data));
    });
  };

  return (
    <BasicLayout image={singBackImg}>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <Card>
        {loading ? (
          <div className="loading">
            <CircularProgress color="warning" size={60} />
          </div>
        ) : (
          <MDBox pt={4} pb={3} px={3}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#ccc',
              }}
            >
              <img src={logoImg} alt="" style={{ width: '180px', marginBottom: '22px' }} />
              <span style={{ fontSize: '16px', marginTop: '10px' }}>
                {passwordForgot ? 'Esqueceu sua senha? ' : null}
              </span>
              <span style={{ fontSize: '16px', marginBottom: '-10px' }}>
                {passwordForgot ? 'Digite seu email' : 'Digite seus dados de acesso'}
              </span>
            </div>
            <MDBox
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="dark"
              mx={2}
              p={0}
              mt={2}
              mb={2}
              textAlign="center"
            />
            {passwordForgot ? (
              <form action="forgotPassowrd" onSubmit={handlePasswordForgot}>
                <MDBox mb={2}>
                  <MDInput
                    type="email"
                    label="Email"
                    fullWidth
                    error={false}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSign();
                      }
                    }}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="warning"
                    fullWidth
                    onClick={() => {
                      handlePasswordForgot();
                    }}
                  >
                    Enviar
                  </MDButton>
                </MDBox>
                <MDBox display="flex" justifyContent="center" alignItems="center" mt={2}>
                  <MDButton
                    style={{
                      marginBottom: '-10px',
                      alignSelf: 'center',
                      backgroundColor: 'transparent',
                      fontSize: '12px',
                    }}
                    onClick={() => {
                      setPasswordForgot(false);
                    }}
                  >
                    voltar
                  </MDButton>
                </MDBox>
              </form>
            ) : (
              <form action="login" onSubmit={handleSign}>
                <MDBox mb={2}>
                  <MDInput
                    type="email"
                    label="Email"
                    fullWidth
                    error={false}
                    value={auth.username}
                    onChange={(e) => {
                      setAuth({ ...auth, username: e.target.value });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSign();
                      }
                    }}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    label="Senha"
                    fullWidth
                    value={auth.password}
                    onChange={(e) => {
                      setAuth({ ...auth, password: e.target.value });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSign();
                      }
                    }}
                  />
                </MDBox>
                {/* <MDBox display="flex" alignItems="center" ml={-1}>
                    <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MDTypography>
                  </MDBox> */}
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="warning"
                    fullWidth
                    onClick={() => {
                      handleSign();
                    }}
                  >
                    Entrar
                  </MDButton>
                </MDBox>
                <MDBox display="flex" justifyContent="center" alignItems="center" mt={2}>
                  <MDButton
                    style={{
                      marginBottom: '-10px',
                      alignSelf: 'center',
                      backgroundColor: 'transparent',
                      fontSize: '12px',
                    }}
                    onClick={() => {
                      setPasswordForgot(true);
                    }}
                  >
                    Esqueceu sua senha?
                  </MDButton>
                </MDBox>
              </form>
            )}
          </MDBox>
        )}
      </Card>
    </BasicLayout>
  );
}

export default Basic;
