/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */

import { Modal, Tooltip } from '@mui/material';
import MDButton from 'components/MDButton';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { CheckCircle, Coins, Pencil, Question, UploadSimple, XCircle } from 'phosphor-react';
import React, { useEffect, useState, useReducer } from 'react';
import './style.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { baseUrl } from 'utils/base_url';
import * as Switch from '@radix-ui/react-switch';
import FileUpload from 'react-material-file-upload';
import { useNavigate } from 'react-router-dom';

export default function PaymentEvent({ payment , resaleID}) {
  const [open, setOpen] = useState(false);
  const [nfUrl, setNfUrl] = useState(payment.url_nf);
  const [notes, setNotes] = useState(payment.note);
  const [urlComprovante, setUrlComprovante] = useState(payment.url_cp);
  const [openUploadNF, setOpenUploadNF] = useState(false);
  const [openUploadComprovante, setOpenUploadComprovante] = useState(false);
  const [aceito, setAceito] = useState(true);
  const [file, setFile] = useState(null);
  const role = useSelector((state) => state.auth.user.role);
  const [update, setupdate] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenUploadNF(false);
    setOpenUploadComprovante(false);
  };

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  function handlePaymentEventChange(data) {
    if (data.success === true) {
      successNotify(data.message);
      setTimeout(() => {
        setupdate();
      }, 1500);
    } else {
      errorNotify(data.message);
    }
  }

  function handleContest() {
    const formData = new FormData();
    formData.append('file', nfUrl ? nfUrl[0] : undefined);

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    axios
      .post(`${baseUrl}/google/upload`, formData, config)
      .then((response) => {
        axios
          .patch(`${baseUrl}/resale-payment/contest/${payment.resalePaymentId}`, {
            url_nf: response.data.fileId.id,
            note: notes,
          })
          .then((res) => {
            if (res.status === 200) {
              handlePaymentEventChange({ success: true, message: res.statusText });
              setTimeout(() => {
                handleClose();
                if(role === 'admin') {
                  navigate('/pagamentos', resaleID);
                }else{
                  navigate('/resale/pagamentos', resaleID);
                }
                
              }, 1000);
            } else {
              handlePaymentEventChange({ success: false, message: res.statusText });
            }
          })
          .catch((err) => {
            handlePaymentEventChange({ success: false, message: err.response?.data.message });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handlePayment() {
    const formData = new FormData();
    formData.append('file', urlComprovante ? urlComprovante[0] : undefined);
    formData.append('file', nfUrl ? nfUrl[0] : undefined);

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        console.log(`Current progress:`, Math.round((event.loaded * 100) / event.total));
      },
    };

    if (payment.Status === 'pendente') {
      axios
        .post(`${baseUrl}/google/upload`, formData, config)
        .then((response) => {
          axios
            .patch(`${baseUrl}/resale-payment/${payment.resalePaymentId}`, {
              url_nf: response.data.fileId.id,
              note: notes,
            })
            .then((res) => {
              if (res.status === 200) {
                handlePaymentEventChange({ success: true, message: res.statusText });
                setTimeout(() => {
                  handleClose();
                  navigate('/resale/pagamentos', resaleID );
                }, 1000);
              } else {
                handlePaymentEventChange({ success: false, message: res.statusText });
              }
            })
            .catch((err) => {
              handlePaymentEventChange({ success: false, message: err.response?.data.message });
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (payment.Status === 'analise') {
      if (aceito === true) {
        axios.post(`${baseUrl}/google/upload`, formData, config).then((response) => {
          axios
            .patch(`${baseUrl}/resale-payment/validate/${payment.resalePaymentId}`, {
              approves: aceito,
              note: notes,
              url_cp: response.data.fileId.id,
            })
            .then((res) => {
              if (res.status === 200) {
                handlePaymentEventChange({ success: true, message: res.statusText });
                setTimeout(() => {
                  handleClose();
                  navigate('/pagamentos', resaleID);
                }, 1000);
              } else {
                handlePaymentEventChange({ success: false, message: res.statusText });
              }
            })
            .catch((err) => {
              handlePaymentEventChange({ success: false, message: err.response.data });
            })
            .catch((err) => {
              console.log(err);
            });
        });
      } else {
        axios
          .patch(`${baseUrl}/resale-payment/validate/${payment.resalePaymentId}`, {
            approves: aceito,
          })
          .then((res) => {
            if (res.status === 200) {
              handlePaymentEventChange({ success: true, message: res.statusText });
              setTimeout(() => {
                handleClose();
              }, 1000);
            } else {
              handlePaymentEventChange({ success: false, message: res.statusText });
            }
          })
          .catch((err) => {
            handlePaymentEventChange({ success: false, message: err.response.data });
          });
      }
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <span>
        <MDButton
          sx={{ ml: 0.5 }}
          size="small"
          variant="gradient"
          color="info"
          onClick={handleOpen}
          iconOnly
        >
          <UploadSimple weight="fill" size={16} />
        </MDButton>
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form action="" className="operatorForm">
          <SnackbarProvider
            iconVariant={{
              success: (
                <CheckCircle
                  size={16}
                  color="#ffffff"
                  weight="fill"
                  style={{ marginRight: '10px' }}
                />
              ),
              error: (
                <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
              ),
            }}
            dense
            autoHideDuration={2500}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
          <div className="formHeader">
            <div className="formLogo">
              <Coins size={28} color="#ffffff" weight="fill" />
            </div>
          </div>
          <h2 className="modalTitle">
            {/* {payment.month_ref.split('T')[0].split('-').reverse().join('/')} */}
          </h2>
          <div className="tariffFormGrid">
            {payment.Status === 'pendente' ? (
              <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
                <label
                  htmlFor="img"
                  style={{
                    marginBottom: 3,
                  }}
                  className="toolTip"
                >
                  Nota Fiscal
                  <Tooltip title="anexar a nota ao seu drive tubaron e anexar o link do mesmo neste campo">
                    <Question size={20} color="#ffffff" weight="fill" />
                  </Tooltip>
                </label>
                <label style={{ padding: '0.5rem' }}>
                  Arquivo: {nfUrl ? nfUrl[0].path : ' sem anexo'}
                </label>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  {openUploadNF ? (
                    <MDButton
                      onClick={() => setOpenUploadNF(false)}
                      className="formBtn"
                      color="error"
                      variant="contained"
                    >
                      Fechar
                    </MDButton>
                  ) : (
                    <MDButton
                      onClick={() => setOpenUploadNF(true)}
                      className="formBtn"
                      color="info"
                      variant="contained"
                    >
                      Upload
                    </MDButton>
                  )}
                </div>
                {openUploadNF ? (
                  <FileUpload
                    value={nfUrl}
                    id="nfUrl"
                    title="Arraste e solte seu comprovante, ou clique no botão para selecionar o arquivo."
                    sx={{ borderColor: '#fff', color: '#fff', marginTop: '0.5rem' }}
                    typographyProps={{ color: '#fff' }}
                    onChange={setNfUrl}
                    style={{ cursor: role !== 'resaleAdmin' ? 'not-allowed' : 'auto' }}
                  />
                ) : null}
              </div>
            ) : null}
            {payment.Status === 'analise' ? (
              <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
                <label
                  htmlFor="img"
                  style={{
                    marginBottom: 3,
                  }}
                  className="toolTip"
                >
                  Comprovante
                  <Tooltip title="anexar a nota ao seu drive tubaron e anexar o link do mesmo neste campo">
                    <Question size={20} color="#ffffff" weight="fill" />
                  </Tooltip>
                </label>
                <label style={{ padding: '0.5rem' }}>
                  Arquivo:{urlComprovante ? urlComprovante[0].path : ' sem anexo'}
                </label>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  {openUploadComprovante ? (
                    <MDButton
                      onClick={() => setOpenUploadComprovante(false)}
                      className="formBtn"
                      color="error"
                      variant="contained"
                    >
                      Fechar
                    </MDButton>
                  ) : (
                    <MDButton
                      onClick={() => setOpenUploadComprovante(true)}
                      className="formBtn"
                      color="info"
                      variant="contained"
                    >
                      Upload
                    </MDButton>
                  )}
                </div>
                {openUploadComprovante ? (
                  <FileUpload
                    value={urlComprovante}
                    id="nfUrl"
                    sx={{ borderColor: '#fff', color: '#fff', marginTop: '0.5rem' }}
                    typographyProps={{ color: '#fff' }}
                    title="Arraste e solte seu comprovante, ou clique no botão para selecionar o arquivo."
                    onChange={setUrlComprovante}
                  />
                ) : null}
              </div>
            ) : null}
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label>Observações</label>
              <textarea
                className="paymentTextArea inputField"
                onChange={(event) => setNotes(event.target.value)}
              />
            </div>
          </div>
          <div className="buttonsCreateTariff">
            <MDButton
              onClick={() => handlePayment()}
              className="formBtn"
              color="success"
              variant="contained"
            >
              Enviar
            </MDButton>
            {role === 'resaleAdmin' && payment.Status === 'pendente' ? (
              <MDButton
                onClick={() => handleContest()}
                className="formBtn"
                color="error"
                variant="contained"
              >
                Contestar
              </MDButton>
            ) : null}
          </div>
        </form>
      </Modal>
    </div>
  );
}
