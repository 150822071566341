/* eslint-disable */
import axios from 'axios';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { baseUrl } from 'utils/base_url';
import RelatorieTableCommission from '../RelatorieTableCommission';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import SignatureTable from 'layouts/RelatoriesClients/SignatureTable';

export function FilterGeneric({ filterByPlan, filterByDate, filterByResale, filterByCity, route }) {
  const [cidade, setCidade] = useState('');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [pais, setPais] = useState('');
  const [planList, setPlanList] = useState([]);
  const [plan, setPlan] = useState('');
  const [monthYear, setMonthYear] = useState('');
  const [isARange, setIsARange] = useState('thisMonth');
  const [rangeStart, setRangeStart] = useState('');
  const [rangeEnd, setRangeEnd] = useState('');
  const [resaleList, setResaleList] = useState([]);
  const [resale, setResale] = useState('');
  const [generatedComissionRelatorie, setGeneratedComissionRelatorie] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const role = useSelector((state) => state.auth.user.role);

  useEffect(() => {
    setDisabled(!!(isARange === 'thisMonth' || isARange === 'lastMonth'));
  }, [isARange]);

  useEffect(async () => {
    axios.get(`${baseUrl}/plan/`).then((res) => setPlanList(res.data));
    if (role === 'admin') {
      axios.get(`${baseUrl}/resale/`).then((res) => setResaleList(res.data));
    }
  }, []);

  useEffect(async () => {
    await axios.get(`${baseUrl}/location/countries`).then((data) => setCountries(data.data));
  }, []);

  const findCities = async (id) => {
    await axios.get(`${baseUrl}/location/cities/${id}`).then((data) => {
      setCities(data.data);
    });
  };

  const findState = async (id) => {
    setPais(id);
    setStates([]);
    await axios.get(`${baseUrl}/location/states/${id}`).then((data) => {
      setStates(data.data);
    });
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };
  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const handleGenerateRelatorie = () => {
    setLoading(true);
    let newDate = '';
    if (filterByDate) {
      if (isARange === 'thisMonth') {
        const date = new Date();
        const year = new Date().getFullYear();
        let month = new Date().getMonth();
        month += 1;

        if (Number(month) < 10) {
          month = `0${month}`;
        }
        if (Number(month) === 0) {
          month = '12';
        }
        newDate = `${year}-${month}`;
      } else if (isARange === 'lastMonth') {
        const date = new Date();
        const year = new Date().getFullYear();
        let month = new Date().getMonth().toString();
        date.setMonth(Number(month));

        if (Number(month) < 10) {
          month = `0${month}`;
        }
        if (Number(month) === 0) {
          month = '12';
        }
        newDate = `${year}-${month}`;
      }
    }

    const data = {
      date: disabled ? (newDate === '' ? undefined : newDate) : monthYear,
      dateRange:
        rangeStart === ''
          ? undefined
          : {
              startDate: rangeStart,
              endDate: rangeEnd,
            },
      cityId: cidade === '' ? undefined : cidade,
      resaleId: +resale === 0 ? undefined : +resale,
      tariffPlanId: plan === '' ? undefined : plan,
    };
    axios
      .post(`${baseUrl}/relatories/${route}`, data)
      .then((res) => {
        if (res.data.payload) {
          successNotify(res.data.message);
          setList(res.data.payload);
        } else {
          errorNotify(res.data.message);
        }

        setGeneratedComissionRelatorie(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        errorNotify(err.message ? err.message : err.response.data.message);
      });
  };
  return (
    <div style={{ width: '100%', marginTop: '30px', gap: '0.5rem' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        <header className="relatorieHeaderFilters">
          <p> Filtrar por:</p>
          <div className="relatoriesdiv">
            <div className="comissionOptions">
              <div>
                {role === 'admin' ? (
                  <div>
                    {filterByResale ? (
                      <div>
                        <label htmlFor="revenda">Revenda: </label>
                        <select
                          name=""
                          id="revenda"
                          className="inputField"
                          onChange={(event) => setResale(event.target.value)}
                        >
                          <option value="">Selecione uma Revenda</option>
                          {resaleList.map((item) => (
                            <option key={item.id_ixc} value={item.id_ixc}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : null}
                    {filterByPlan ? (
                      <div>
                        <label htmlFor="plan">Plano: </label>
                        <select
                          name=""
                          id="plan"
                          className="inputField"
                          onChange={(event) => setPlan(event.target.value)}
                        >
                          <option value="1">Selecione um plano</option>
                          {planList.map((plano) => (
                            <option key={plano.id} value={plano.id}>
                              {plano.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <div>
                {filterByDate ? (
                  <div>
                    <label>Data:</label>
                    <select
                      name=""
                      id="data"
                      className="inputField"
                      onChange={(event) => {
                        setMonthYear('');
                        setRangeStart('');
                        setRangeEnd('');
                        setIsARange(event.target.value);
                      }}
                    >
                      <option value="thisMonth">Este mês</option>
                      <option value="lastMonth">Ultimo mẽs</option>
                      <option value="range">Range de Datas</option>
                      <option value="monthly">Mensal</option>
                    </select>
                    <div>
                      {disabled ? null : isARange === 'range' ? (
                        <div>
                          <label htmlFor="rangeStart">Ínicio</label>
                          <input
                            className="inputField"
                            type="date"
                            id="rangeStart"
                            onChange={(event) => {
                              setRangeStart(event.target.value);
                            }}
                          />
                          <label htmlFor="rangeEnd">Fim</label>
                          <input
                            className="inputField"
                            type="date"
                            id="rangeEnd"
                            onChange={(event) => {
                              setRangeEnd(event.target.value);
                            }}
                          />
                        </div>
                      ) : (
                        <div>
                          <label htmlFor="month">Mês</label>
                          <input
                            className="inputField"
                            type="month"
                            id="month"
                            onChange={(event) => {
                              setMonthYear(event.target.value);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
              <div>
                {filterByCity ? (
                  <div>
                    <label>Pais: </label>
                    <select
                      id="standard-select"
                      placeholder="seleciona uma opção"
                      onChange={(e) => {
                        findState(e.target.value);
                      }}
                      value={pais}
                      style={{ cursor: 'pointer', width: '20%' }}
                      className="inputField"
                    >
                      <option disabled value="">
                        Selecione uma opção
                      </option>
                      {countries.map((countri) => (
                        <option key={countri.id} value={countri.id}>
                          {countri.name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="nome">Estado:</label>
                    <select
                      id="standard-select"
                      defaultValue=""
                      placeholder="seleciona uma opção"
                      onChange={(e) => findCities(e.target.value)}
                      className="inputField"
                      style={{ width: '30%' }}
                    >
                      <option disabled>Selecione um país primeiro</option>
                      {states.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="nome">Cidade:</label>
                    <select
                      id="standard-select"
                      defaultValue=""
                      placeholder="seleciona uma opção"
                      onChange={(e) => setCidade(e.target.value)}
                      className="inputField"
                      style={{ width: '40%' }}
                    >
                      <option disabled>Selecione um país primeiro</option>
                      {cities.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="generateButtons">
            <MDButton
              color="success"
              variant="contained"
              size="small"
              onClick={handleGenerateRelatorie}
            >
              Gerar{' '}
            </MDButton>
          </div>
        </header>
      </div>
      {loading ? (
        <div className="loading">
          <CircularProgress color="warning" size={60} />
        </div>
      ) : generatedComissionRelatorie ? (
        <MDBox mx={2} mt={3} py={3} px={2} borderRadius="lg">
          {route === 'current-customers' ? <RelatorieTableCommission list={list} /> : null}
          {route === 'report' ? 'Construção' : null}
          {route === 'signature' ? <SignatureTable list={list} /> : null}
        </MDBox>
      ) : null}
    </div>
  );
}
