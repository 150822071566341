/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable */
// Images
import DataTable from 'examples/Tables/DataTable/index.js';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { CheckCircle, FileArrowDown, FileCsv, WarningOctagon, XCircle } from 'phosphor-react';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { baseUrl } from 'utils/base_url.js';
import MDButton from 'components/MDButton/index.js';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import generatePDF from 'utils/GeneratePDF';

export default function RelatorieTableCommission({ list }) {
  const [parameters, setParameters] = useState([]);
  const [cotacao, setCotacao] = useState(0);
  const role = useSelector((state) => state.auth.user.role);

  const componentRef = useRef();

  useEffect(() => {
    if (role === 'admin') {
      axios.get(`${baseUrl}/parameters`).then((res) => {
        setParameters(res.data);
      });
    }
  }, []);

  useEffect(() => {
    axios.get(`https://economia.awesomeapi.com.br/json/last/USD-BRL`).then((res) => {
      setCotacao(res.data.USDBRL.bid);
    });
  }, []);

  const columnsPDF =
    role === 'admin'
      ? [
          {
            nameClient: 'Cliente',
            nameResale: 'Resale',
            plan: 'Plano',
            city: 'Cidade',
            vencimento: 'Vencimento',
            dataPgto: 'Pagamento',
            valor: 'Valor',
            imposto: 'Imposto',
            CustoOperacional: 'Operacional',
            comissao: 'Comissão',
            totalLiquido: 'Total',
          },
        ]
      : [
          {
            nameClient: 'Cliente',
            nameResale: 'Resale',
            plan: 'Plano',
            city: 'Cidade',
            vencimento: 'Vencimento',
            dataPgto: 'Pagamento',
            valor: 'Valor',
          },
        ];

  const myRows = [];
  function createData(
    idClient,
    nameClient,
    nameResale,
    plan,
    city,
    vencimento,
    dataPgto,
    valor,
    imposto,
    CustoOperacional,
    comissao,
    totalLiquido
  ) {
    return {
      idClient,
      nameClient,
      nameResale,
      plan,
      city,
      vencimento,
      dataPgto,
      valor,
      imposto,
      CustoOperacional,
      comissao,
      totalLiquido,
    };
  }

  const rows = [];
  if (role === 'admin') {
    list.map((item) => {
      const temp = createData(
        item.id,
        item.razao,
        item.resale,
        item.plano,
        item.cidade,
        item.data_vencimento.split('-').reverse().join('/'),
        item.pagamento_data.split('-').reverse().join('/'),
        item.valor.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        }),
        ((item.valor * parameters[0]?.tax) / 100).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        }),
        ((item.valor * parameters[0]?.operational) / 100).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        }),
        ((item.valor * parameters[0]?.commision_seller) / 100).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        }),
        (
          item.valor -
          (item.valor * parameters[0]?.tax) / 100 -
          (item.valor * parameters[0]?.operational) / 100 -
          (item.valor * parameters[0]?.commision_seller) / 100 -
          (parameters[0]?.infomir * cotacao).toFixed(2)
        ).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        })
      );
      rows.push(temp);
    });
  } else {
    list.map((item) => {
      const temp = createData(
        item.idClient,
        item.nameClient,
        item.idResale,
        item.nameResale,
        item.plan,
        item.city,
        item.vencimento,
        item.dataPgto,
        item.valor.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        })
      );
      rows.push(temp);
    });
  }

  function renderMyRows(item) {
    if (role === 'admin') {
      return myRows.push({
        idCliente: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.idClient}
            </MDTypography>
          </MDBox>
        ),
        nameCliente: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.nameClient}
            </MDTypography>
          </MDBox>
        ),
        nameResale: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.nameResale}
            </MDTypography>
          </MDBox>
        ),
        vencimento: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.vencimento}
            </MDTypography>
          </MDBox>
        ),
        cidade: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.city}
            </MDTypography>
          </MDBox>
        ),
        plano: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.plan}
            </MDTypography>
          </MDBox>
        ),
        dataPgto: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.dataPgto}
            </MDTypography>
          </MDBox>
        ),
        valor: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.valor}
            </MDTypography>
          </MDBox>
        ),
        imposto: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.imposto}
            </MDTypography>
          </MDBox>
        ),
        CustoOperacional: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.CustoOperacional}
            </MDTypography>
          </MDBox>
        ),
        Comissao: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.comissao}
            </MDTypography>
          </MDBox>
        ),
        totalLiquido: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.totalLiquido}
            </MDTypography>
          </MDBox>
        ),
      });
    } else {
      return myRows.push({
        idCliente: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.idClient}
            </MDTypography>
          </MDBox>
        ),
        nameCliente: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.nameClient}
            </MDTypography>
          </MDBox>
        ),
        nameResale: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.nameResale}
            </MDTypography>
          </MDBox>
        ),
        vencimento: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.vencimento}
            </MDTypography>
          </MDBox>
        ),
        cidade: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.city}
            </MDTypography>
          </MDBox>
        ),
        plano: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.plan}
            </MDTypography>
          </MDBox>
        ),
        dataPgto: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.dataPgto}
            </MDTypography>
          </MDBox>
        ),
        valor: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.valor}
            </MDTypography>
          </MDBox>
        ),
      });
    }
  }

  if (rows.length > 0) {
    rows.forEach((item) => {
      renderMyRows(item);
    });
  }

  function myTableData() {
    if (role === 'admin') {
      return {
        columns: [
          { Header: 'ID Cliente', accessor: 'idCliente', width: '5%', align: 'center' },
          { Header: 'Cliente', accessor: 'nameCliente', width: '10%', align: 'center' },
          { Header: 'Resale', accessor: 'nameResale', width: '10%', align: 'left' },
          { Header: 'Cidade', accessor: 'cidade', width: '10%', align: 'left' },
          { Header: 'Plano', accessor: 'plano', width: '10%', align: 'left' },
          { Header: 'Vencimento', accessor: 'vencimento', width: '10%', align: 'left' },
          { Header: 'Pagamento', accessor: 'dataPgto', width: '10%', align: 'left' },
          { Header: 'Valor', accessor: 'valor', width: '10%', align: 'left' },
          { Header: 'Imposto', accessor: 'imposto', width: '10%', align: 'left' },
          { Header: 'Operacional', accessor: 'CustoOperacional', width: '10%', align: 'left' },
          { Header: 'Comissão', accessor: 'Comissao', width: '10%', align: 'left' },
          { Header: 'Total', accessor: 'totalLiquido', width: '10%', align: 'left' },
        ],
        rows: myRows,
      };
    }
    return {
      columns: [
        { Header: 'ID Cliente', accessor: 'idCliente', width: '5%', align: 'center' },
        { Header: 'Cliente', accessor: 'nameCliente', width: '10%', align: 'center' },
        { Header: 'Resale', accessor: 'nameResale', width: '10%', align: 'left' },
        { Header: 'Cidade', accessor: 'cidade', width: '10%', align: 'left' },
        { Header: 'Plano', accessor: 'plano', width: '10%', align: 'left' },
        { Header: 'Vencimento', accessor: 'vencimento', width: '10%', align: 'left' },
        { Header: 'Pagamento', accessor: 'dataPgto', width: '10%', align: 'left' },
        { Header: 'Valor', accessor: 'valor', width: '10%', align: 'left' },
      ],
      rows: myRows,
    };
  }

  return (
    <div ref={componentRef} style={{ display: 'flex', flexDirection: 'column' }}>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <div>
        {role === 'admin' ? (
          <div
            style={{
              display: 'flex',
              gap: '2rem',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '-0.8rem',
            }}
          >
            {list.length === 0 ? null : (
              <div style={{ display: 'flex', gap: '2rem', width: '100%'}}>
                <div style={{ display: 'flex', gap: '2rem', width: '100%', alignItems: 'center' }}>
                  <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
                    Imposto : {parameters[0]?.tax}%
                  </MDTypography>
                  <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
                    Infomir : R$: {(parameters[0]?.infomir * cotacao).toFixed(2)}
                  </MDTypography>
                  <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
                    Custo Operacional : {parameters[0]?.operational}%
                  </MDTypography>
                  <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
                    Comissão : {parameters[0]?.commision_seller}%
                  </MDTypography>
                </div>
                <div style={{ display: 'flex', gap: '0.5rem' , justifyContent: 'flex-end' }}>
                  <MDButton
                    color="success"
                    iconOnly
                    style={{ alignSelf: 'flex-end', marginTop: '0.5rem' }}
                  >
                    <CSVLink
                      filename="my-file.csv"
                      style={{ display: 'flex', alignItems: 'center' }}
                      data={rows}
                    >
                      <FileCsv size={16} color="white" fill="#fff" />
                    </CSVLink>
                  </MDButton>

                  <MDButton
                    color="info"
                    iconOnly
                    className="downloadButton"
                    style={{ alignSelf: 'flex-end', marginTop: '0.5rem' }}
                    onClick={() => generatePDF(rows, columnsPDF, true, role, 'Comissao')}
                  >
                    <FileArrowDown size={24} color="white" fill="#fff" />
                  </MDButton>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{ display: 'flex', gap: '0.5rem', width: '100%', justifyContent: 'flex-end' }}
          >
            <MDButton
              color="success"
              iconOnly
              style={{ alignSelf: 'flex-end', marginTop: '0.5rem' }}
            >
              <CSVLink
                filename="my-file.csv"
                style={{ display: 'flex', alignItems: 'center' }}
                data={rows}
              >
                <FileCsv size={16} color="white" fill="#fff" />
              </CSVLink>
            </MDButton>

            <MDButton
              color="info"
              iconOnly
              className="downloadButton"
              style={{ alignSelf: 'flex-end', marginTop: '0.5rem' }}
              onClick={() => generatePDF(rows, columnsPDF, true)}
            >
              <FileArrowDown size={24} color="white" fill="#fff" />
            </MDButton>
          </div>
        )}
      </div>
      {list.length === 0 ? (
        <div className="emptyIcon">
          <WarningOctagon size={200} weight="bold" />
          <span>Não Retornou Dados</span>
        </div>
      ) : (
        <DataTable
          table={myTableData()}
          isSorted={true}
          entriesPerPage
          noEndBorder
          pagination={{ variant: 'gradient', color: 'warning' }}
          showTotalEntries
        />
      )}
    </div>
  );
}
