/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable*/
import { Modal, Select, Tooltip } from '@mui/material';

import axios from 'axios';
import { CheckCircle, MonitorPlay, Pencil, Question, XCircle } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { baseUrl } from '../../utils/base_url';

import MDButton from '../MDButton';

import './channelStyle.css';
import FileUpload from 'react-material-file-upload';

export default function ChannelModal({
  data,
  title,
  variant,
  color,
  iconVariant,
  className,
  btnText,
  handleChannelListChange,
}) {
  const role = useSelector((state) => state.auth.user.role);
  const isEditing = data?.id;
  const [channelName, setChannelName] = useState('');
  const [channelNumber, setChannelNumber] = useState('');
  const [channelImgUrl, setChannelImgUrl] = useState('');
  const [channelUpload, setChannelUpload] = useState([]);
  const [channelGroup, setChannelGroup] = useState('');
  const [groups, setGroups] = useState([]);
  const [update, setupdate] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setChannelName(data?.name ? data.name : '');
    setChannelNumber(data?.number ? data.number : '');
    setChannelImgUrl(data?.url ? data.url : '');
    setChannelGroup(data?.channelGroupId ? data.channelGroupId : '');
    setupdate(false);
    setOpen(true);
  };
  const handleClose = () => {
    setChannelNumber('');
    setChannelName('');
    setChannelImgUrl('');
    setChannelGroup('');
    setOpen(false);
    setupdate(false);
  };

  useEffect(() => {
    axios.get(`${baseUrl}/channel-groups`).then((res) => setGroups(res.data.payload));
  }, [update]);

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const validator = (state) => {
    errorNotify(state.message);
    document.getElementById(state.id).classList.replace('inputField', 'inputFieldError');
  };

  function handleChannel() {
    if (channelName?.length < 3) {
      validator({ message: 'Nome deve conter ao menos 3 caracteres', id: 'channelName' });
    } else {
      document
        .getElementById('channelName')
        .classList.replace('inputFieldError', 'inputFieldSuccess');
    }

    if (!channelNumber > 0 && !channelNumber <= 9999) {
      validator({ message: 'Número do canal deve ser entre 1 e 9999', id: 'channelNumber' });
    } else {
      document
        .getElementById('channelNumber')
        .classList.replace('inputFieldError', 'inputFieldSuccess');
    }

    const expression =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);

    if (!channelGroup) {
      validator({ message: 'Selecione um Grupo', id: 'channelImgUrl' });
    }

    if (channelName?.length < 3 || !channelNumber > 0 || !channelGroup) {
      return;
    }

    if (channelUpload.length > 0) {
      const formData = new FormData();
      formData.append('file', channelUpload ? channelUpload[0] : undefined);

      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: (event) => {
          console.log(`Current progress:`, Math.round((event.loaded * 100) / event.total));
        },
      };
      axios.post(`${baseUrl}/google/upload`, formData, config).then((response) => {
        if (isEditing !== undefined) {
          axios
            .patch(`${baseUrl}/channels/`, {
              id: data.id,
              name: channelName,
              number: Number(channelNumber),
              url: channelUpload.length > 0 ? response?.data.fileId.thumbnailLink : channelImgUrl,
              channelGroupId: channelGroup,
            })
            .then((res) => {
              console.log(res);
              if (res.data.status === 400) {
                handleChannelListChange({
                  message: res.data,
                  success: false,
                });
              } else {
                handleChannelListChange({
                  message: res.data,
                  success: true,
                });
              }
              setTimeout(() => {
                handleClose();
              }, 1000);
            })
            .catch((error) => console.log(error));
        } else {
          axios
            .post(`${baseUrl}/channels`, {
              name: channelName,
              number: Number(channelNumber),
              url: response.data.fileId,
              channelGroupId: channelGroup,
            })
            .then((res) => {
              if (res.data.status === 400) {
                handleChannelListChange({
                  message: res.data,
                  success: false,
                });
              } else {
                handleChannelListChange({
                  message: 'Canal Criado com sucesso',
                  success: true,
                });
              }
              setTimeout(() => {
                handleClose();
              }, 1000);
            })
            .catch((error) => {
              errorNotify(
                error.response.data.message.length > 0
                  ? error.response.data.message[0]
                  : error.response.data.message
              );
            });
        }
      });
    } else {
      if (isEditing !== undefined) {
        axios
          .patch(`${baseUrl}/channels/`, {
            id: data.id,
            name: channelName,
            number: Number(channelNumber),
            url: channelImgUrl,
            channelGroupId: channelGroup,
          })
          .then((res) => {
            console.log(res);
            if (res.data.status === 400) {
              handleChannelListChange({
                message: res.data,
                success: false,
              });
            } else {
              handleChannelListChange({
                message: res.data,
                success: true,
              });
            }
            setTimeout(() => {
              handleClose();
            }, 1000);
          })
          .catch((error) => console.log(error));
      } else {
        axios
          .post(`${baseUrl}/channels`, {
            name: channelName,
            number: Number(channelNumber),
            url: response.data.fileId,
            channelGroupId: channelGroup,
          })
          .then((res) => {
            if (res.data.status === 400) {
              handleChannelListChange({
                message: res.data,
                success: false,
              });
            } else {
              handleChannelListChange({
                message: 'Canal Criado com sucesso',
                success: true,
              });
            }
            setTimeout(() => {
              handleClose();
            }, 1000);
          })
          .catch((error) => {
            errorNotify(
              error.response.data.message.length > 0
                ? error.response.data.message[0]
                : error.response.data.message
            );
          });
      }
    }
  }
  return (
    <div style={{ position: 'relative' }}>
      <span>
        {role !== 'operatorTubaron' && (
          <MDButton
            sx={{ ml: 0.5 }}
            ize="medium"
            variant="gradient"
            color={color ? color : 'info'}
            onClick={handleOpen}
            iconOnly={iconVariant === 'edit'}
          >
            {iconVariant === 'edit' ? <Pencil weight="fill" size={16} /> : null}
            {btnText}
          </MDButton>
        )}
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ position: 'absolute', top: '0' }}
      >
        <form action="" className="operatorForm">
          <SnackbarProvider
            iconVariant={{
              success: (
                <CheckCircle
                  size={16}
                  color="#ffffff"
                  weight="fill"
                  style={{ marginRight: '10px' }}
                />
              ),
              error: (
                <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
              ),
            }}
            dense
            autoHideDuration={2500}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
          <div className="formHeader">
            <div className="formLogo">
              <MonitorPlay size={28} color="#ffffff" weight="fill" />
            </div>
          </div>
          <h2 className="modalTitle">{title}</h2>
          <div className="tariffFormGrid">
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="nome">Nome: </label>
              <input
                value={channelName}
                type="text"
                id="channelName"
                className="inputField"
                onChange={
                  (e) => setChannelName(e.target.value)
                  // dispatch(setTariffFormState({ ...tariffForm, name: e.target.value }))
                }
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="channelNumber">Número:</label>
              <input
                value={channelNumber}
                id="channelNumber"
                className="inputField"
                onChange={(e) => setChannelNumber(e.target.value)}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label
                htmlFor="img"
                style={{
                  marginBottom: 3,
                }}
                className="toolTip"
              >
                Logo do Canal
                <Tooltip title="adicionar o logo da programadora">
                  <Question size={20} color="#ffffff" weight="fill" />
                </Tooltip>
              </label>
              <label style={{ padding: '0.5rem' }}></label>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {openUpload ? (
                  <MDButton
                    onClick={() => setOpenUpload(false)}
                    className="formBtn"
                    color="error"
                    variant="contained"
                  >
                    Fechar
                  </MDButton>
                ) : (
                  <MDButton
                    onClick={() => setOpenUpload(true)}
                    className="formBtn"
                    color="info"
                    variant="contained"
                  >
                    Upload
                  </MDButton>
                )}
              </div>
              {openUpload ? (
                <FileUpload
                  value={channelUpload}
                  id="nfUrl"
                  title="Arraste e solte seu comprovante, ou clique no botão para selecionar o arquivo."
                  sx={{ borderColor: '#fff', color: '#fff', marginTop: '0.5rem' }}
                  typographyProps={{ color: '#fff' }}
                  onChange={setChannelUpload}
                  style={{ cursor: role !== 'resaleAdmin' ? 'not-allowed' : 'auto' }}
                />
              ) : null}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
                className="formFields"
              >
                <label htmlFor="groupSelect">Grupo do Canal:</label>
                <select
                  id="groupSelect"
                  value={channelGroup}
                  className="inputField"
                  placeholder="seleciona uma opção"
                  defaultChecked
                  onChange={(e) => {
                    setChannelGroup(e.target.value);
                  }}
                >
                  <option value="">Escolha um Grupo</option>
                  {groups?.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="buttonsCreateTariff">
            <MDButton
              onClick={() => handleClose()}
              className="formBtn"
              color="error"
              variant="contained"
            >
              Cancelar
            </MDButton>

            <MDButton
              onClick={() => handleChannel()}
              className="formBtn"
              color="success"
              variant="contained"
              disabled={!channelGroup}
            >
              {isEditing ? 'Editar' : 'Criar'}
            </MDButton>
          </div>
        </form>
      </Modal>
    </div>
  );
}
