/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable*/
// import axios from 'axios';
// import DataTable from 'examples/Tables/DataTable';
// import Bill from 'layouts/customers/components/Bill';
/* eslint-disable */

import { CircularProgress, Icon } from '@mui/material';
import * as Switch from '@radix-ui/react-switch';
import axios from 'axios';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { CheckCircle, Pencil, XCircle } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ConfirmDeleteModal from '../../../components/ConfirmDeleteModal/index.jsx';
import MDBox from '../../../components/MDBox/index';
import MDButton from '../../../components/MDButton/index';
import MDTypography from '../../../components/MDTypography/index';
import { useMaterialUIController } from '../../../context/index';
import DataTable from '../../../examples/Tables/DataTable/index';
import { changeUpdate } from '../../../redux/resaleSlice';
import { changeUser } from '../../../redux/userSlice';
import { baseUrl } from '../../../utils/base_url';

import './styles.css';

export default function CustomerTable({ filtered, query, inactive, type }) {
  const myRows = [];
  const [users, setUsers] = useState([]);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl}/customer`).then((data) => {
      setUsers(data.data);
      setLoading(false);
    });
  }, [update]);

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const successNotify = (status) => {
    const thisMessage =
      status === 'S' ? `Cliente ativado com sucesso` : `Cliente desativado com sucesso`;
    enqueueSnackbar(thisMessage, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const deleteNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  function handleCustomer(value) {
    dispatch(changeUser(value));
  }

  const handleDeleteCustomer = async (id) => {
    await axios
      .delete(`${baseUrl}/customer/${id}`)
      .then((thisData) => {
        deleteNotify('Cliente deletado com sucesso!');
      })
      .catch((error) => {
        errorNotify(error.response.data.message);
      });
    setUpdate(!update);
  };

  function statusColor(status) {
    switch (status) {
      case 'Aguardando Assinatura':
        return 'contratoAguardandoAssinatura';
      case 'Ativo':
        return 'contratoAtivo';
      case 'Pré-contrato':
        return 'contratoPreContrato';
      case 'Sem contrato':
        return 'contratoSemContrato';
      case 'Inativo':
        return 'contratoInativo';
      case 'Desativado':
        return 'contratoInativo';
      case 'Negativado':
        return 'contratoNegativado';
      default:
        break;
    }
  }

  function myRowOperatorTubaron(item) {
    return myRows.push({
      name: (
        <Link to="/cliente" onClick={() => handleCustomer(item)} className="profileInfos">
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            flexDirection={{ xs: 'row', sm: 'row' }}
            mb={2}
          >
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {item.razao}
            </MDTypography>
          </MDBox>
        </Link>
      ),
      revenda: (
        <MDBox mb={1} lineHeight={1}>
          <MDTypography variant="caption" color="text">
            Revenda:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {item?.resale?.name}
            </MDTypography>
          </MDTypography>
        </MDBox>
      ),
      city: (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'row', sm: 'row' }}
          mb={2}
        >
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {item.city.name}
          </MDTypography>
        </MDBox>
      ),
      document: (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'row', sm: 'row' }}
          mb={2}
        >
          <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
            {item.cnpj_cpf}
          </MDTypography>
        </MDBox>
      ),
      plan: (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'row', sm: 'row' }}
          mb={2}
        >
          <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
            {item.plan}
          </MDTypography>
        </MDBox>
      ),
    });
  }

  function handleMyRows(item) {
    if (user.role === 'resaleAdmin' || user.role === 'operator') {
      return myRows.push({
        name: (
          <Link
            to="/cliente"
            onClick={() => {
              handleCustomer(item);
            }}
            className="profileInfos"
          >
            <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                flexDirection={{ xs: 'row', sm: 'row' }}
                mb={2}
              >
                <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                  {item.razao}
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={1}>
                <MDTypography variant="caption" color="text">
                  Cidade:&nbsp;&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="medium">
                    {item.city.name}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
              <MDBox mb={1} lineHeight={1}>
                <MDTypography variant="caption" color="text">
                  CPF:&nbsp;&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                    {item.cnpj_cpf}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
              <MDBox mb={1} lineHeight={1}>
                <MDTypography variant="caption" color="text">
                  Plano:&nbsp;&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                    {item.tb_account[0]?.tariff_plan.name
                      ? item.tb_account[0]?.tariff_plan.name
                      : 'Sem plano'}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
              <MDBox mb={1} lineHeight={1}>
                <MDTypography variant="caption" color="text">
                  Contrato:&nbsp;&nbsp;&nbsp;
                  <span
                    variant="caption"
                    fontWeight="medium"
                    className={statusColor(item.status_internet_ixc)}
                  >
                    {item.status_internet_ixc}
                  </span>
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
              <MDBox mb={1} lineHeight={1}>
                <MDTypography variant="caption" color="text">
                  Status Acesso:&nbsp;&nbsp;&nbsp;
                  <span
                    variant="caption"
                    fontWeight="medium"
                    className={statusColor(item.status_internet_ixc)}
                  >
                    {item.status_ixc}
                  </span>
                </MDTypography>
              </MDBox>
            </MDBox>
          </Link>
        ),
        action: (
          <MDBox width="100%" display="flex" alignItems="flex-end" flexDirection="column" border>
            <MDBox mb={3} />
            <MDBox
              display="flex"
              alignItems="right"
              mt={{ xs: 2, sm: 2 }}
              ml={{ xs: 5, sm: 0 }}
              sx={{ marginTop: '10px' }}
              gap={1}
            >
              <Link to="/cliente" style={{ color: '#fff' }}>
                <MDButton
                  color="info"
                  size="small"
                  style={{
                    display: 'flex',
                    gap: 5,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => {
                    handleCustomer(item);
                  }}
                >
                  <Pencil weight="fill" size={14} />
                  editar
                </MDButton>
              </Link>
              <MDBox mr={1}>
                <ConfirmDeleteModal deleteCustomer={() => handleDeleteCustomer(item.id)} />
              </MDBox>
            </MDBox>
          </MDBox>
        ),
      });
    }
    return myRows.push({
      name: (
        <Link to="/cliente" onClick={() => handleCustomer(item)} className="profileInfos">
          <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              flexDirection={{ xs: 'row', sm: 'row' }}
              mb={2}
            >
              <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                {item?.razao}
              </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={1}>
              <MDTypography variant="caption" color="text">
                Cidade:&nbsp;&nbsp;&nbsp;
                <MDTypography variant="caption" fontWeight="medium">
                  {item?.city?.name}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
            <MDBox mb={1} lineHeight={1}>
              <MDTypography variant="caption" color="text">
                CPF:&nbsp;&nbsp;&nbsp;
                <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                  {item?.cnpj_cpf}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
            <MDBox mb={1} lineHeight={1}>
              <MDTypography variant="caption" color="text">
                Plano:&nbsp;&nbsp;&nbsp;
                <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                  {item.tb_account[0]?.tariff_plan?.name
                    ? item.tb_account[0]?.tariff_plan.name
                    : 'Sem plano'}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
            <MDBox mb={1} lineHeight={1}>
              <MDTypography variant="caption" color="text">
                Contrato:&nbsp;&nbsp;&nbsp;
                <span
                  variant="caption"
                  fontWeight="medium"
                  className={statusColor(item.status_internet_ixc)}
                >
                  {item.status_internet_ixc}
                </span>
              </MDTypography>
            </MDBox>
            <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
              <MDBox mb={1} lineHeight={1}>
                <MDTypography variant="caption" color="text">
                  Status Acesso:&nbsp;&nbsp;&nbsp;
                  <span
                    variant="caption"
                    fontWeight="medium"
                    className={statusColor(item.status_ixc)}
                  >
                    {item.status_ixc}
                  </span>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Link>
      ),
      revenda: (
        <MDBox mb={1} lineHeight={1}>
          <MDTypography variant="caption" color="text">
            Revenda:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {item?.resale?.name}
            </MDTypography>
          </MDTypography>
        </MDBox>
      ),
      action: (
        <MDBox width="100%" display="flex" alignItems="flex-end" flexDirection="column" border>
          <MDBox mb={3} />
          <MDBox
            display="flex"
            alignItems="right"
            mt={{ xs: 2, sm: 2 }}
            ml={{ xs: 5, sm: 0 }}
            sx={{ marginTop: '10px' }}
            gap={1}
          >
            <Link to="/cliente" style={{ color: '#fff' }}>
              <MDButton
                color="info"
                size="small"
                style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'center' }}
                onClick={() => {
                  handleCustomer(item);
                }}
              >
                <Pencil weight="fill" size={14} />
                editar
              </MDButton>
            </Link>
            <MDBox mr={1}>
              <ConfirmDeleteModal deleteCustomer={() => handleDeleteCustomer(item.id)} />
            </MDBox>
          </MDBox>
        </MDBox>
      ),
    });
  }

  if (user === undefined) {
    console.log();
  } else if (filtered === true && query.length > 0) {
    let filteredUser = '';
    if (type === 'cliente') {
      filteredUser = Object.values(users).filter((customer) =>
        customer.razao.toLowerCase().includes(query.toLowerCase())
      );
    } else if (type === 'plano') {
      filteredUser = Object.values(users).filter((customer) =>
        customer.tb_account[0]?.tariff_plan?.name.toLowerCase().includes(query.toLowerCase())
      );
    } else if (type === 'resale') {
      filteredUser = Object.values(users).filter((customer) =>
        customer.resale.name.toLowerCase().includes(query.toLowerCase())
      );
    } else if (type === 'cidade') {
      filteredUser = Object.values(users).filter((customer) =>
        customer.city.name.toLowerCase().includes(query.toLowerCase())
      );
    } else if (type === 'contrato') {
      filteredUser = Object.values(users).filter((customer) =>
        customer.status_internet_ixc.toLowerCase().includes(query.toLowerCase())
      );
    } else if (type === 'acesso') {
      filteredUser = Object.values(users).filter((customer) =>
        customer.status_ixc.toLowerCase().includes(query.toLowerCase())
      );
    }
    filteredUser.forEach((item) => {
      handleMyRows(item);
    });
  } else if (inactive === false) {
    const filteredUser = Object.values(users).filter((customer) => customer.ativo === 'S');
    filteredUser.forEach((item) => {
      handleMyRows(item);
    });
  } else {
    users?.forEach((item) => {
      handleMyRows(item);
    });
  }

  // || user.role === 'admin'
  function myTableData() {
    if (user.role === 'operatorTubaron') {
      return {
        columns: [
          { Header: 'cliente', accessor: 'name' },
          { Header: 'city', accessor: 'city' },
          { Header: 'Revenda', accessor: 'revenda' },
          { Header: 'document', accessor: 'document' },
          { Header: 'ações', accessor: 'action', width: '50%', align: 'left' },
        ],
        rows: myRows,
      };
    }
    if (user.role === 'admin') {
      return {
        columns: [
          { Header: 'cliente', accessor: 'name', width: '40%', align: 'left' },
          { Header: 'Revenda', accessor: 'revenda', width: '50%', align: 'left' },
          { Header: 'ações', accessor: 'action', width: '50%', align: 'left' },
        ],
        rows: myRows,
      };
    }
    return {
      columns: [
        { Header: 'cliente', accessor: 'name', width: '70%', align: 'left' },
        { Header: 'ações', accessor: 'action', width: '30%', align: 'right' },
      ],
      rows: myRows,
    };
  }

  return (
    <>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      {loading ? (
        <div className="loading">
          <CircularProgress color="warning" size={60} />
        </div>
      ) : (
        <DataTable
          table={myTableData()}
          isSorted={false}
          entriesPerPage={false}
          noEndBorder
          pagination={{ variant: 'gradient', color: 'warning' }}
        />
      )}
    </>
  );
}
