/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */

import { Modal, Tooltip } from '@mui/material';
import MDButton from 'components/MDButton';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import {
  ArrowsClockwise,
  CheckCircle,
  Coins,
  File,
  Pencil,
  Question,
  UploadSimple,
  XCircle,
} from 'phosphor-react';
import React, { useEffect, useState, useReducer } from 'react';
import './style.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { baseUrl } from 'utils/base_url';
import * as Switch from '@radix-ui/react-switch';
import FileUpload from 'react-material-file-upload';
import { useNavigate } from 'react-router-dom';

export default function PaymentContest({ payment, value, resaleId }) {
  const [open, setOpen] = useState(false);
  const role = useSelector((state) => state.auth.user.role);
  const [valor, setValor] = useState(
    Number(value?.toFixed(2))?.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    })
  );
  const [resaleEmail, setResaleEmail] = useState('');
  const [file, setFile] = useState();
  const [notes, setNotes] = useState('');
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [update, setupdate] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();

  useEffect(() => {
    if (role === 'admin') {
      axios.get(`${baseUrl}/resale`).then((res) => {
        const resale = res.data.filter((item) => {
          if (item.id === resaleId) {
            return item;
          }
          return null;
        });
        setResaleEmail(resale[0].email);
      });
    }
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  function handlePaymentEventChange(data) {
    if (data.success === true) {
      successNotify(data.message);
      setTimeout(() => {
        setupdate();
      }, 1500);
    } else {
      errorNotify(data.message);
    }
  }

  function handleContest() {
    const formData = new FormData();
    formData.append('file', file ? file[0] : undefined);

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        console.log(`Current progress:`, Math.round((event.loaded * 100) / event.total));
      },
    };
    axios
      .post(`${baseUrl}/google/upload`, formData, config)
      .then((response) => {
        axios
          .patch(`${baseUrl}/resale-payment/set-pending/${payment.resalePaymentId}`, {
            url_fl: response.data.fileId.id,
            note: notes,
          })
          .then((res) => {
            if (res.status === 200) {
              handlePaymentEventChange({ success: true, message: res.statusText });
              setTimeout(() => {
                handleClose();
                navigate('/resale/pagamentos');
              }, 1000);
            } else {
              handlePaymentEventChange({ success: false, message: res.statusText });
            }
          })
          .catch((err) => {
            handlePaymentEventChange({ success: false, message: err.response?.data.message });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div style={{ position: 'relative' }}>
      <span>
        <MDButton
          sx={{ ml: 0.5 }}
          size="small"
          variant="gradient"
          color="warning"
          onClick={handleOpen}
          iconOnly
        >
          <Tooltip title="Ajustar Nota Contestada">
            <ArrowsClockwise weight="fill" size={16} />
          </Tooltip>
        </MDButton>
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form action="" className="operatorForm">
          <SnackbarProvider
            iconVariant={{
              success: (
                <CheckCircle
                  size={16}
                  color="#ffffff"
                  weight="fill"
                  style={{ marginRight: '10px' }}
                />
              ),
              error: (
                <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
              ),
            }}
            dense
            autoHideDuration={2500}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
          <div className="formHeader">
            <div className="formLogo">
              <File size={28} color="#ffffff" weight="fill" />
            </div>
          </div>
          <h2 className="modalTitle">Ajuste pagamento {payment.conclusao}</h2>
          <div className="tariffFormGrid">
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label>Valor:</label>
              <input
                value={valor}
                className="inputField"
                onChange={(event) => setValor(event.target.value)}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label
                htmlFor="img"
                style={{
                  marginBottom: 3,
                }}
                className="toolTip"
              >
                Relatório
                <Tooltip title="Anexar relatório demonstrativo de comissões">
                  <Question size={20} color="#ffffff" weight="fill" />
                </Tooltip>
              </label>
              <label style={{ padding: '0.5rem' }}>
                Arquivo: {file ? file[0].path : ' sem anexo'}
              </label>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {openUploadFile ? (
                  <MDButton
                    onClick={() => setOpenUploadFile(false)}
                    className="formBtn"
                    color="error"
                    variant="contained"
                  >
                    Fechar
                  </MDButton>
                ) : (
                  <MDButton
                    onClick={() => setOpenUploadFile(true)}
                    className="formBtn"
                    color="info"
                    variant="contained"
                  >
                    Upload
                  </MDButton>
                )}
              </div>
              {openUploadFile ? (
                <FileUpload
                  value={file}
                  id="nfUrl"
                  title="Arraste e solte seu comprovante, ou clique no botão para selecionar o arquivo."
                  sx={{ borderColor: '#fff', color: '#fff', marginTop: '0.5rem' }}
                  typographyProps={{ color: '#fff' }}
                  onChange={setFile}
                  style={{}}
                />
              ) : null}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label>Observações:</label>
              <textarea
                defaultValue={notes}
                className="paymentTextArea inputField"
                onChange={(event) => setNotes(event.target.value)}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields" />
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields" />

            <div className="buttonsCreateTariff">
              <MDButton
                onClick={() => handleContest()}
                className="formBtn"
                color="success"
                variant="contained"
              >
                Enviar
              </MDButton>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
}
