/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
// import {  } from '@headlessui/react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Modal } from '@mui/material';
import axios from 'axios';
import { Buildings, CheckCircle, CircleNotch, XCircle } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { changeUpdate } from '../../redux/resaleSlice';

import MDButton from '../MDButton';
import './styles.css';
import { baseUrl } from '../../utils/base_url';

const schema = yup.object({
  email: yup.string().required('Campo obrigatório!'),
  password: yup
    .string()
    .required('Campo obrigatório!')
    .min(6, 'senha deve ter no minimo 6 caracteres'),
  name: yup.string().required('Campo obrigatório!'),
  permission: yup
    .string()
    .required('Campo obrigatório!')
    .oneOf(['admin', 'operator', 'resaleAdmin', 'operatorTubaron'], 'Permissão inválida'),
});

export default function OperatorModal() {
  const storeUpdate = useSelector((state) => state.resale.updateResale);
  const thisResaleId = useSelector((state) => state.auth.user.resaleId);
  const contractId = useSelector((state) => state.auth.user.contract_id_ixc);
  const dispatch = useDispatch();

  const {
    resetField,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [resales, setResales] = useState([]);
  const [open, setOpen] = useState(false);
  const [resale, setResale] = useState(thisResaleId);

  const [isLoading, setIsLoading] = useState(false);

  function clear() {
    resetField('email');
    resetField('password');
    resetField('name');
    resetField('permission');
    resetField('resaleId');
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    clear();
  };

  function handleResaleChange(value) {
    const enconteredResale = resales.find((_resale) => _resale.id === value);
    setResale(enconteredResale);
  }

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };
  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const user = useSelector((state) => state.auth.user);

  const onSubmit = async (data) => {
    if (data) {
      setIsLoading(true);
      await axios
        .post(`${baseUrl}/operators`, {
          type: data.permission,
          email: data.email,
          password: data.password,
          full_name: data.name,
          resaleId: resale.id,
          contractIdIxc: contractId,
        })
        .then((thisData) => {
          if (thisData.status !== 201) {
            errorNotify(thisData.response.data.message);
          } else {
            successNotify(thisData.data.message);
            setIsLoading(false);
            dispatch(changeUpdate(!storeUpdate));
            handleClose();
          }
        })
        .catch((error) => {
          setIsLoading(false);
          // if (
          //   error.response.data.message[0] !== undefined &&
          //   error.response.data.message[0] !== '0'
          // ) {
          //   // errorNotify(error.response.data.message[0]);
          //   setIsLoading(false);
          // } else {
          errorNotify(error.response.data.message);
          // }
        });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user.role === 'admin') {
      axios.get(`${baseUrl}/resale`).then((res) => {
        setResales(res.data);
      });
    }
  }, []);

  return (
    <>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      {user.role !== 'operatorTubaron' && user.role !== 'operator' && (
        <MDButton
          onClick={() => {
            handleOpen();
          }}
          // className="openModalBtn"
          variant="gradient"
          color="success"
        >
          Criar Operador
        </MDButton>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      >
        <form action="" className="operatorForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="formHeader">
            <div className="formLogo">
              <Buildings size={28} color="#ffffff" />
            </div>
          </div>
          <h2 className="operatorModalTitle">Criar Operador</h2>
          <div action="test" className="operatorFormGrid">
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginLeft: '8px',
                  marginRight: '8px',
                  marginBottom: '5px',
                }}
              >
                <label htmlFor="nome">Nome:</label>
                <span className="span">{errors.name !== undefined ? errors.name.message : ''}</span>
              </div>
              <input
                className="inputField"
                type="text"
                id="nome"
                // onChange={(e) => setName(e.target.value)}
                {...register('name')}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginLeft: '8px',
                  marginRight: '8px',
                  marginBottom: '5px',
                }}
              >
                <label htmlFor="email">Email:</label>
                <span className="span">
                  {errors.email !== undefined ? errors.email.message : ''}
                </span>
              </div>
              <input
                className="inputField"
                type="text"
                id="email"
                // onChange={(e) => setEmail(e.target.value)}
                {...register('email')}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginLeft: '8px',
                  marginRight: '8px',
                  marginBottom: '5px',
                }}
              >
                <label htmlFor="password">Senha: </label>
                <span className="span">
                  {errors.password !== undefined ? errors.password.message : ''}
                </span>
              </div>
              <input
                className="inputField"
                type="password"
                id="password"
                // onChange={(e) => setPassword(e.target.value)}
                {...register('password')}
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <label htmlFor="nome">Permissão:</label>
                <span className="span">
                  {errors.permission !== undefined ? errors.permission.message : ''}
                </span>
              </div>
              <div className="select">
                <select id="select-permission" {...register('permission')}>
                  <option disabled>Selecione uma opção</option>
                  {user.role === 'admin' ? (
                    <>
                      <option value="admin">Admin</option>
                      <option value="operatorTubaron">Tubaron Operador</option>
                    </>
                  ) : (
                    <>
                      <option value="resaleAdmin">Admin</option>
                      <option value="operator">Operador</option>
                    </>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="buttonsCreateResale">
            <MDButton color="error" variant="contained" onClick={() => handleClose()}>
              Cancelar
            </MDButton>
            <MDButton
              type="submit"
              color="success"
              className="customerCreateBtn"
              onClick={() => onSubmit()}
              style={{ paddingRight: '26px', paddingLeft: '26px' }}
            >
              {isLoading === true ? (
                <CircleNotch size={18} weight="bold" className="isLoading" />
              ) : (
                'Criar'
              )}
            </MDButton>
          </div>
        </form>
      </Modal>
    </>
  );
}
