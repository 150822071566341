/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { useSelector } from 'react-redux';

// import { useDispatch } from 'react-redux';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { CheckCircle, XCircle } from 'phosphor-react';
import PlanDetails from '../PlanDetails/index.jsx';
import DataTable from '../../examples/Tables/DataTable';
import EditTariffModal from '../EditTariffModal/index.jsx';
// import { setModaState } from '../../features/common/modalSlice';
// import { setIsUpdateTariff, setTariffFormState } from '../../features/tariff/tariffFormSlice';
import {
  useDeleteTariffMutation,
  useFindAllTariffsQuery,
} from '../../features/tariff/tariffService';
import MDBox from '../MDBox';
import MDButton from '../MDButton';
import MDTypography from '../MDTypography';
import DeleteModal from '../../layouts/tariff/components/DeleteModal.jsx';

export default function TariffTable({ tariffs }) {
  const myRows = [];
  // const dispatch = useDispatch();

  const [deleteTariff] = useDeleteTariffMutation();
  const { refetch } = useFindAllTariffsQuery();
  const role = useSelector((state) => state.auth.user.role);

  // function handleUpdateTariff(payload) {
  //   dispatch(setTariffFormState(payload));
  //   dispatch(setIsUpdateTariff(true));
  //   dispatch(setModaState(true));
  // }

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const updateNotify = (message) => {
    successNotify(message);
    setTimeout(() => {
      refetch();
    }, 500);
  };

  function handleDeleteTariff(id) {
    deleteTariff(id)
      .unwrap()
      .then(() => {
        successNotify('Plano deletado com sucesso!');
        setTimeout(() => {
          refetch();
        }, 500);
      });
  }

  if (tariffs === undefined) {
    myRows.push([]);
  } else {
    Object.values(tariffs).forEach((tariff) => {
      myRows.push({
        name: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium" lineHeight={1}>
              {tariff.name}
            </MDTypography>
          </MDBox>
        ),
        plan_id_ixc: (
          <MDTypography variant="button" color="text" fontWeight="medium">
            {tariff.plan_id_ixc}
          </MDTypography>
        ),
        price: (
          <MDTypography variant="button" color="text" fontWeight="medium">
            {tariff.sale.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </MDTypography>
        ),
        price_sale: (
          <MDTypography variant="button" color="text" fontWeight="medium">
            {tariff.cost.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </MDTypography>
        ),
        category: (
          <MDTypography variant="button" color="text" fontWeight="medium">
            {tariff.category}
          </MDTypography>
        ),
        channels_group: (
          <MDButton
            onClick={() => handleDeleteTariff(tariff.id)}
            sx={{ ml: 1 }}
            size="medium"
            variant="contained"
            color="dark"
            disabled
            title="Grupos de Canais"
          >
            Grupos
          </MDButton>
        ),
        action:
          role === 'resaleAdmin' || role === 'operator' ? (
            <MDBox style={{ display: 'flex', gap: '0.5rem' }}>
              {/* eslint-disable */}
              <PlanDetails
                size="small"
                data={tariff}
                iconVariant="see"
                title={`Detalhes do plano ${tariff.name}`}
              />
            </MDBox>
          ) : (
            <MDBox style={{ display: 'flex', gap: '0.5rem' }}>
              {/* eslint-disable */}
              <EditTariffModal data={tariff} updateNotify={updateNotify} />
              <DeleteModal data={tariff} handleDeletePlan={handleDeleteTariff} />
              <PlanDetails
                size="small"
                data={tariff}
                iconVariant="see"
                title={`Detalhes do plano ${tariff.name}`}
              />
            </MDBox>
          ),
      });
    });
  }

  function myTableData() {
    if (role === 'operatorTubaron') {
      return {
        columns: [
          { Header: 'Nome', accessor: 'name', width: '25%', align: 'left' },
          { Header: 'Plano id ixc', accessor: 'plan_id_ixc', align: 'left' },
          { Header: 'Preço de custo', accessor: 'price_sale', align: 'center', isSorted: 'false' },
          { Header: 'Venda', accessor: 'price', align: 'center', isSorted: 'false' },
        ],
        rows: myRows,
      };
    }
    if (role === 'resaleAdmin' || role === 'operator') {
      return {
        columns: [
          { Header: 'Nome', accessor: 'name', width: '25%', align: 'left' },
          // { Header: 'Plano id ixc', accessor: 'plan_id_ixc', align: 'left' },
          // { Header: 'Preço de custo', accessor: 'price_sale', align: 'center', isSorted: 'false' },
          { Header: 'Venda', accessor: 'price', align: 'center', isSorted: 'false' },
          // { Header: 'Canais', accessor: 'canais', align: 'center', isSorted: 'false' },
          { accessor: 'action', align: 'center' },
        ],
        rows: myRows,
      };
    }
    return {
      columns: [
        { Header: 'Nome', accessor: 'name', width: '25%', align: 'left' },
        { Header: 'Plano id ixc', accessor: 'plan_id_ixc', align: 'left' },
        { Header: 'Preço de custo', accessor: 'price_sale', align: 'center', isSorted: 'false' },
        { Header: 'Venda', accessor: 'price', align: 'center', isSorted: 'false' },
        { accessor: 'action', align: 'center' },
      ],
      rows: myRows,
    };
  }

  return (
    <>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <DataTable
        table={myTableData()}
        isSorted={false}
        entriesPerPage={false}
        noEndBorder
        pagination={{ variant: 'gradient', color: 'warning' }}
      />
    </>
  );
}
