import { Card, Grid } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setIsSearch } from '../../features/tariff/tariffFormSlice';

import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import TariffModal from '../../components/TariffModal/index.jsx';
import TariffTable from '../../components/TariffTable/index.jsx';
// import Footer from '../../examples/Footer';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

import {
  useFindAllTariffsQuery,
  useFindAllTariffsSearchQuery,
} from '../../features/tariff/tariffService';
import './style.css';

function TariffPage() {
  const user = useSelector((state) => state.auth.user);
  const [query, setQuery] = useState('');

  const { data: tariffs } = useFindAllTariffsQuery();
  const { data: tariff } = useFindAllTariffsSearchQuery(query);

  const isSearch = useSelector((state) => state.tariffForm.isSearch);
  const dispatch = useDispatch();

  function handleSearchEvent(e) {
    dispatch(setIsSearch(false));
    if (e === 'Enter') {
      dispatch(setIsSearch(true));
    }
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {user.role === 'admin' ? <TariffModal /> : ''}
      <MDBox mt={6} mb={3}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="info"
              display={{ sm: 'flex' }}
              alignItems={{ sm: 'center' }}
              justifyContent={{ sm: 'space-between' }}
            >
              <MDTypography variant="h6" color="white">
                Planos
              </MDTypography>
              <input
                id="myInput"
                className="myInput"
                placeholder="Plano..."
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                onKeyDown={(e) => handleSearchEvent(e.key)}
              />
            </MDBox>
            <MDBox pt={3}>
              {isSearch ? <TariffTable tariffs={tariff} /> : <TariffTable tariffs={tariffs} />}
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default TariffPage;
