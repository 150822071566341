/* eslint-disable */
import { FilePdf } from 'phosphor-react';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import './stylesInvoicePrint.css';
import axios from 'axios';
import { baseUrl } from 'utils/base_url';
import MDButton from 'components/MDButton';
import Barcode from 'react-barcode';

import logo from './assets/img_01.png';

export function InvoiceToPrint({ invoice, customer }) {
  const componentRef = useRef();
  const [invoiceData, setInvoiceData] = useState('');
  const [open, setOpen] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Doc',
    pageStyle: `
`,
  });

  useEffect(() => {
    axios.get(`${baseUrl}/customer/get-boleto/${invoice.id}`, {}).then((response) => {
      setInvoiceData(response.data[0]);
    });
  }, [invoice]);

  function handlePDF() {
    handlePrint();
  }
  return (
    <div>
      <FilePdf
        size={30}
        onClick={() => handlePDF()}
        color="#ffffff"
        weight="fill"
        className="PdfIcon"
      />
      <div ref={componentRef} className="hideDocument" style={{ width: '100%' }}>
        <div className="boletoHeaderContainer">
          <img src={logo} className="boletoLogo" />
          <div className="BoletoTubaronData">
            <p>Fatura de Serviçõs de Telecomunicação</p>
            <p>TUBARON TECNOLOGIAS LTDA</p>
            <p>R WALDO MACHADO DE OLIVEIRA, 67 - Centro</p>
            <p>CEP: 96690-000 - Pantano Grande - RS</p>
            <p>CNPJ: 07.775.840/001-75 IE: 296/0017557</p>
          </div>
          <div className="BoletoTubaronDataSuporte">
            <p>Central de Suporte ao Cliente</p>
            <p style={{ fontWeight: 'bold' }}>Ligue 106-32 ou 2324-2324</p>
            <p>www.tubaron.com.br</p>
            <p>sac@tubaron.com.br</p>
            <p>WhatsApp: (51) 9959-10632</p>
          </div>
        </div>
        <div className="BoletoSpacer" />
        <div className="BoletoClienteData">
          <div className="BoletoClienteDataContainer">
            <p style={{ fontWeight: 'bold' }}>{invoiceData.sacado}</p>
            <p>{invoiceData.Endereco?.split('-')[0]}</p>
            <p>{invoiceData.Endereco?.split('-')[1]}</p>
            <p>
              {invoiceData.CEP} {invoiceData.Cidade} {invoiceData.Estado_sigla}
            </p>
          </div>
          <div className="BoletoNoticiaContainer">
            <span>
              A Tubaron tem uma ótima notícia para você! Faça um Upgrade no seu plano. Ficou
              interessado? Ligue 10632 e solicite!
            </span>
          </div>
        </div>
        <div className="BoletoSpacer" />
        <div className="BoletoDataFaturaContainer">
          <div className="BoletoDataFatura">
            <p>CPF/CNPJ</p>
            <p>{invoiceData.CPF}</p>
          </div>
          <div className="BoletoDataFatura">
            <p>Referência</p>
            <p style={{ letterSpacing: '-0.03rem' }}>{invoiceData.obs}</p>
          </div>
          <div className="BoletoDataFatura">
            <p>Data de Vencimento</p>
            <p>{invoiceData.data_vencimento}</p>
          </div>
          <div className="BoletoDataFatura">
            <p>Valor</p>
            <p>R$ {invoiceData.valor_boleto}</p>
          </div>
        </div>
        <div className="BoletoFaturaDetalhes">
          <p style={{ fontWeight: 'bold' }}>Veja o que está sendo cobrado</p>
          <div className="BoletoFaturaDetalhesContainer">
            <div className="BoletoFaturaDetalhesContainerFlex">
              <div className="BoletoDetalhado">
                <span>{customer.tb_account[0].tariff_plan.name}</span>
                <span>{customer.tb_account[0].tariff_plan.sale.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
              </div>
            </div>
            <div className="BoletoDetalhadoTotal">
              <span>Total</span>
              <span>R$ {invoiceData.valor_boleto}</span>
            </div>
          </div>
        </div>
        <p className="BoletoFiscal">NOTA FISCAL DE SERVIÇO DE COMUNICAÇÂO MOD.</p>
        <p className="BoletoNatureza">Natureza da operação 0 -</p>
        <div className="BoletoImposto">
          <div className="BoletoImpostoItem">
            <span>Base de cálculo do ISS: R$ 0,00</span>
          </div>
          <div className="BoletoImpostoItem">
            <span>Alíquota: R$ 0,00</span>
          </div>
          <div className="BoletoImpostoItem">
            <span>Valor do ISS: R$ 0,00</span>
          </div>
        </div>
        <div className="BoletoImposto">
          <div className="BoletoImpostoItem">
            <span>Número da nota fiscal:</span>
          </div>
          <div className="BoletoImpostoItem">
            <span>Data de emissão: {invoiceData.data_documento} </span>
          </div>
          <div className="BoletoImpostoItem">
            <span>Valor: R$ 0,00</span>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="BoletoReservadoFisco">
            <p>RESERVADO AO FISCO</p>
            <p style={{ fontWeight: 'bold' }}>15F8.73BD.4186.7436.A024.D3A8.7CBD.CDF0</p>
          </div>
        </div>
        <div className="BoletoObservacoes">
          <p>{invoiceData.linha1}</p>
          <p>{invoiceData.linha2}</p>
          <p>{invoiceData.linha3}</p>
          <p>{invoiceData.Instrucao1}</p>
          <p>{invoiceData.Instrucao2}</p>
          <p>
            O pagamento desta fatura não exime débitos anteriores. O não pagamento das faturas não
            caracteriza cancelamento dos serviços.
          </p>
        </div>
        <div className="BoletoSpacerDashed">
          <span style={{ marginLeft: '0.5rem' }}>Via do cliente</span>
          <span>Autenticação mecânica:</span>
        </div>
        <span style={{ marginLeft: '0.5rem', marginTop: '1rem', fontSize: '0.4rem' }}>
          Via do banco
        </span>
        <div className="BoletoViaBancoContainer">
          <img src={logo} className="boletoLogo" />
          <div className="BoletoViaBancoData">
            <p style={{ fontWeight: 'bold' }}>Nome do Cliente</p>
            <p style={{ position: 'absolute', bottom: '0' }}>{invoiceData.sacado}</p>
          </div>
          <span style={{ fontWeight: 'bold' }}>
            Sr. Caixa, favor não receber pagamento parcial.
          </span>
        </div>
        <div className="BoletoViaBancoContainer2">
          <div className="BoletoViaBancoData2">
            <p style={{ fontWeight: 'bold' }}>Código do cliente</p>
            <p>{invoiceData.id_cliente}</p>
          </div>
          <div className="BoletoViaBancoData2">
            <p style={{ fontWeight: 'bold' }}>Código do débito em conta</p>
            <p>
              <br />
            </p>
          </div>
          <div className="BoletoViaBancoData2">
            <p style={{ fontWeight: 'bold' }}>Numero da fatura</p>
            <p>{invoiceData.id_receber}</p>
          </div>
          <div className="BoletoViaBancoData2">
            <p style={{ fontWeight: 'bold' }}>Data de vencimento</p>
            <p>{invoiceData.data_vencimento}</p>
          </div>
          <div className="BoletoViaBancoData2">
            <p style={{ fontWeight: 'bold' }}>Valor</p>
            <p>R$ {invoiceData.valor_boleto}</p>
          </div>
        </div>
        <div className="BoletoBarras">
          <Barcode
            value={invoiceData.linha_digitavel}
            textPosition="top"
            width="1"
            height="60"
            textMargin={8}
            font='Arial'
            fontSize={12}
            fontOptions='bold'
          />
        </div>
      </div>
    </div>
  );
}
