/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components

// Material Dashboard 2 React examples
import { Card } from '@mui/material';
// import { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircle, Circle, CircleNotch, XCircle } from 'phosphor-react';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { changeUser } from 'redux/userSlice';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { changeUpdate } from '../../redux/resaleSlice';
import MDTypography from '../../components/MDTypography';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import Footer from '../../examples/Footer';
import MDBox from '../../components/MDBox';
import MDButton from '../../components/MDButton';

import './style.css';
import { baseUrl } from '../../utils/base_url';

const schema = yup.object({
  nome: yup.string().required('Campo obrigatório!').min(5, 'Nome deve ter no minimo 5 caracteres'),
  endereco: yup.string().required('Campo obrigatório!'),
  bairro: yup.string().required('Campo obrigatório!'),
  numero: yup.string().required('Campo obrigatório!'),
  complemento: yup.string(),
  email: yup.string().required('Campo obrigatório!').email('Necessario inserir um E-mail válido'),
  telefone: yup.string().required('Campo obrigatório!'),
  cep: yup.string().required('Campo obrigatório!'),
  cnpj_cpf: yup.string().required('Campo obrigatório!'),
  tipo_pessoa: yup.string().required('Campo obrigatório!'),
  localidade: yup.string().required('Campo obrigatório!'),
});

export default function CreateCustomer() {
  // state variables
  const [cidade, setCidade] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [adress, setAdress] = useState('');
  const [adressNumber, setAdressNumber] = useState();
  const [neighborhood, setNeighborhood] = useState('');
  const [cnpjCpf, setCnpjCpf] = useState('');
  const [telefoneCelular, setTelefoneCelular] = useState('');
  const [tipoPessoa, setTipoPessoa] = useState('');
  const [tipoLocalidade, setTipoLocalidade] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [customerCep, setCustomerCep] = useState('');

  const formatCpfCnpj = (inputValue) => {
    let formattedValue = inputValue.replace(/\D/g, ''); // remove todos os caracteres que não são números

    if (formattedValue.length <= 11) {
      // CPF
      formattedValue = formattedValue.replace(/(\d{3})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/(\d{3})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      // CNPJ
      formattedValue = formattedValue.replace(/^(\d{2})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
      formattedValue = formattedValue.replace(/\.(\d{3})(\d)/, '.$1/$2');
      formattedValue = formattedValue.replace(/(\d{4})(\d)/, '$1-$2');
    }

    return formattedValue;
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatCpfCnpj(inputValue);
    setCnpjCpf(formattedValue);
  };

  const handlePhoneChange = (event) => {
    let formattedPhone = event.target.value.replace(/\D/g, '');
    formattedPhone = formattedPhone.replace(/^(\d{2})(\d)/g, '($1) $2');
    formattedPhone = formattedPhone.replace(/(\d)(\d{4})$/, '$1-$2');
    setPhone(formattedPhone);
  };

  const handleCepChange = (event) => {
    let formattedCep = event.target.value.replace(/\D/g, '');
    formattedCep = formattedCep.replace(/^(\d{5})(\d)/g, '$1-$2');
    setCustomerCep(formattedCep);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const navigate = useNavigate();
  const storeUpdate = useSelector((state) => state.resale.updateResale);
  const dispatch = useDispatch();

  useEffect(async () => {
    await axios.get(`${baseUrl}/location/countries`).then((data) => setCountries(data.data));
  }, []);

  const findState = async (id) => {
    await axios.get(`${baseUrl}/location/states/${id}`).then((data) => setStates(data.data));
  };

  const findCities = async (id) => {
    await axios.get(`${baseUrl}/location/cities/${id}`).then((data) => setCities(data.data));
  };

  function handleNavigationBack() {
    navigate('/clientes');
  }

  function handleCityChange(value) {
    const enconteredCity = cities.find((_plan) => _plan.id === value);
    setCidade(enconteredCity);
  }
  const errorNotify = (message) => {
    if (message[0]) {
      enqueueSnackbar(message[0], {
        style: {
          backgroundColor: '#bd2727',
        },
        variant: 'error',
      });
    }
    
    if (message !== undefined) {
      enqueueSnackbar(message, {
        style: {
          backgroundColor: '#bd2727',
        },
        variant: 'error',
      });
    }
  };
  const successNotify = () => {
    enqueueSnackbar(`Cliente Cadastrado com sucesso!`, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const onSubmit = async (data) => {
    console.log(cidade.id)
    setIsLoading(true);
    await axios
      .post(`${baseUrl}/customer`, {
        cidade: cidade.id,
        razao: data.nome,
        endereco: data.endereco,
        numero: Number(data.numero),
        complemento: data.complemento,
        email: data.email,
        bairro: data.bairro,
        telefone_celular: data.telefone,
        cnpj_cpf: data.cnpj_cpf,
        tipo_pessoa: data.tipo_pessoa,
        tipo_localidade: data.localidade,
        cep: data.cep,
        cidade_ixc: cidade.id_ixc,
      })
      .then((thisData) => {
        successNotify();
        dispatch(changeUser(thisData.data.customer));
        setIsLoading(false);
        setTimeout(() => {
          navigate('/clientes');
        }, [1600]);
      })
      .catch((error) => {
        if (error.response.data.message !== undefined) {
          errorNotify(error.response.data.message);
        }
        if (error.response.data.status === 409) {
          errorNotify(error.response.data.error);
        }
        setIsLoading(false);
      });
    setIsLoading(false);
    dispatch(changeUpdate(!storeUpdate));
  };

  return (
    <>
      <div>
        <SnackbarProvider
          iconVariant={{
            success: (
              <CheckCircle
                size={16}
                color="#ffffff"
                weight="fill"
                style={{ marginRight: '10px' }}
              />
            ),
            error: (
              <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
            ),
          }}
          dense
          autoHideDuration={1500}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
      </div>
      <DashboardLayout>
        <MDBox mt={6} mb={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                mb={4}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
                display={{ sm: 'flex' }}
                alignItems={{ sm: 'center' }}
                justifyContent={{ sm: 'space-between' }}
              >
                <MDTypography variant="h6" color="white">
                  Cliente
                </MDTypography>
              </MDBox>
              <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <form className="" onSubmit={handleSubmit(onSubmit)}>
                    <div action="test" className="resaleFormGrid">
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="nome">Nome:</label>
                          <span className="span">
                            {errors.nome !== undefined ? errors.nome.message : ''}
                          </span>
                        </div>
                        <input
                          className="inputField"
                          type="text"
                          id="nome"
                          onChange={(e) => setName(e.target.value)}
                          {...register('nome')}
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="endereco">Endereço: </label>
                          <span className="span">
                            {errors.endereco !== undefined ? errors.endereco.message : ''}
                          </span>
                        </div>
                        <input
                          className="inputField"
                          type="text"
                          id="endereco"
                          onChange={(e) => setAdress(e.target.value)}
                          {...register('endereco')}
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="bairro">Bairro:</label>
                          <span className="span">
                            {errors.bairro !== undefined ? errors.bairro.message : ''}
                          </span>
                        </div>
                        <input
                          className="inputField"
                          type="text"
                          id="bairro"
                          onChange={(e) => setNeighborhood(e.target.value)}
                          {...register('bairro')}
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="numero">Numero: </label>
                          <span className="span">
                            {errors.numero !== undefined ? errors.numero.message : ''}
                          </span>
                        </div>
                        <input
                          className="inputField"
                          type="text"
                          id="numero"
                          onChange={(e) => setAdressNumber(e.target.value)}
                          {...register('numero')}
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="complemento">Complemento: </label>
                          <span className="span">
                            {errors.complemento !== undefined ? errors.complemento.message : ''}
                          </span>
                        </div>
                        <input
                          className="inputField"
                          type="text"
                          id="complemento"
                          onChange={(e) => setAdressNumber(e.target.value)}
                          {...register('complemento')}
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="email">Email:</label>

                          <span className="span">
                            {errors.email !== undefined ? errors.email.message : ''}
                          </span>
                        </div>
                        <input
                          className="inputField"
                          type="text"
                          id="email"
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          onClick={() => setValidEmail(true)}
                          {...register('email')}
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="telefone">Telefone:</label>
                          <span className="span">
                            {errors.telefone !== undefined ? errors.telefone.message : ''}
                          </span>
                        </div>
                        <input
                          className="inputField"
                          id="telefone"
                          {...register('telefone')}
                          type="text"
                          placeholder="(00) 0000-0000"
                          value={phone}
                          onChange={handlePhoneChange}
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="cep">CEP:</label>
                          <span className="span">
                            {errors.cep !== undefined ? errors.cep.message : ''}
                          </span>
                        </div>
                        <input
                          className="inputField"
                          type="text"
                          placeholder="00000-000"
                          id="cep"
                          value={customerCep}
                          {...register('cep')}
                          onChange={handleCepChange}
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="cpfcnpj">Cpf/cnpj: </label>
                          <span className="span">
                            {errors.cnpj_cpf !== undefined ? errors.cnpj_cpf.message : ''}
                          </span>
                        </div>

                        <input
                          className="inputField"
                          {...register('cnpj_cpf')}
                          type="text"
                          value={cnpjCpf}
                          onChange={handleInputChange}
                          placeholder="Digite seu CPF ou CNPJ"
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="nome">País:</label>
                          <span className="span">
                            {errors.pais !== undefined ? errors.pais.message : ''}
                          </span>
                        </div>
                        <div className="select">
                          <select
                            id="standard-select"
                            placeholder="seleciona uma opção"
                            onChange={(e) => findState(e.target.value)}
                            style={{ cursor: 'pointer' }}
                          >
                            <option>Selecione uma opção</option>
                            {countries.map((countri) => (
                              <option key={countri.id} value={countri.id}>
                                {countri.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="nome">Estado:</label>
                          <span className="span">
                            {errors.estado !== undefined ? errors.estado.message : ''}
                          </span>
                        </div>
                        <div className="select">
                          <select
                            id="standard-select"
                            placeholder="seleciona uma opção"
                            onChange={(e) => findCities(e.target.value)}
                            style={{ cursor: 'pointer' }}
                          >
                            <option disabled value="">
                              Selecione uma opção
                            </option>
                            {states.map((state) => (
                              <option key={state.id} value={state.id}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="nome">Cidade:</label>
                          <span className="span">
                            {errors.cidade !== undefined ? errors.cidade.message : ''}
                          </span>
                        </div>
                        <div className="select">
                          <select
                            id="standard-select"
                            placeholder="seleciona uma opção"
                            onChange={(e) => handleCityChange(e.target.value)}
                          >
                            <option disabled>Selecione um país primeiro</option>
                            {cities.map((city) => (
                              <option key={city.id} value={city.id}>
                                {city.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="nome">Tipo de pessoa: </label>
                        </div>
                        <div className="select">
                          <select
                            id="standard-select"
                            onChange={(e) => setTipoPessoa(e.target.value)}
                            {...register('tipo_pessoa')}
                          >
                            <option disabled value="">
                              Selecione uma opção
                            </option>
                            <option value="F">Fisica</option>
                            <option value="J">Jurídica</option>
                          </select>
                        </div>
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="nome">Tipo de Localidade: </label>
                        </div>
                        <div className="select">
                          <select
                            id="standard-select"
                            onChange={(e) => setTipoLocalidade(e.target.value)}
                            {...register('localidade')}
                          >
                            <option disabled value="">
                              Selecione uma opção
                            </option>
                            <option value="U">Urbana</option>
                            <option value="R">Rural</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="resaleButtonsCreateResale">
                      <MDButton
                        className="formBtn"
                        color="error"
                        variant="contained"
                        onClick={() => handleNavigationBack()}
                      >
                        Cancelar
                      </MDButton>
                      <button type="submit" className="customerCreateBtn">
                        {isLoading === true ? (
                          <CircleNotch size={18} weight="bold" className="isLoading" />
                        ) : (
                          'Criar'
                        )}
                      </button>
                    </div>
                  </form>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}
