import { useState } from 'react';
import useDebounce from '../../../debounce';

/* eslint-disable */
function SearchInput({ value, onChange, placeholder, label, color }) {
  const [displayValue, setDisplayValue] = useState(value);
  const debouncedChange = useDebounce(onChange, 500);

  function handleChange(event) {
    setDisplayValue(event.target.value);
    debouncedChange(event.target.value);
  }
  return (
    <div
      style={{
        display: 'flex',
        gap: '0.5rem',
        alignItems: 'center',
        background: '#181B26',
        borderRadius: '8px',
      }}
    >
      <label style={{color: 'white', padding: '0.2rem' }}>{label}</label>
      <input
        placeholder={placeholder}
        className="inputFilter"
        type="search"
        value={displayValue}
        onChange={handleChange}
      />
    </div>
  );
}

export default SearchInput;
