/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable*/
import { Modal, Select } from '@mui/material';

import axios from 'axios';
import { CheckCircle, Handbag, Pencil, XCircle } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { baseUrl } from '../../utils/base_url';

import MDButton from '../MDButton';

import './channelStyle.css';

export default function ProductModal({
  data,
  title,
  variant,
  color,
  iconVariant,
  className,
  btnText,
  handleProductAdd,
  canAddProduct,
}) {
  const role = useSelector((state) => state.auth.user.role);
  const [plan, setPlan] = useState('');
  const [listPlans, setListPlans] = useState([]);
  const [update, setUpdate] = useState(false);
  const [open, setOpen] = useState(false);


  const handleOpen = () => {
    if (canAddProduct === true) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setPlan('');
    setOpen(false);
    setUpdate(false);
  };

  const handlePlan = (value) => {
    const plano = listPlans.find((item) => item.id === value);
    setPlan(plano);
  };

  useEffect(() => {
    axios.get(`${baseUrl}/plan`).then((data) => {
      setListPlans(data.data);
    });
  }, []);

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };
  function handleProduct() {
    axios
      .post(`${baseUrl}/tb-account/add-contract/${data.id}`, {
        tariffPlan: {
          id: plan.id,
          name: plan.name,
          plan_id_ixc: plan.plan_id_ixc,
          sale: plan.sale,
          cost: plan.cost,
        },
      })
      .then((res) => {
        if (res.data.status === 400) {
          handleProductAdd({
            message: res.data,
            success: false,
          });
        } else {
          handleProductAdd({
            message: res.data,
            success: true,
            data: res.data,
          });
        }
        handleClose();
      })
      .catch((error) => console.log(error));
  }
  return (
    <div style={{ position: 'relative' }}>
      <span>
        {role !== 'operatorTubaron' && (
          <MDButton
            sx={{ ml: 0.5 }}
            ize="medium"
            variant="gradient"
            color="warning"
            onClick={handleOpen}
            iconOnly={iconVariant === 'edit'}
            disabled={!canAddProduct}
          >
            {iconVariant === 'edit' ? <Pencil weight="fill" size={16} /> : null}
            {btnText}
          </MDButton>
        )}
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ position: 'absolute', top: '0' }}
      >
        <form action="" className="operatorForm">
          <SnackbarProvider
            iconVariant={{
              success: (
                <CheckCircle
                  size={16}
                  color="#ffffff"
                  weight="fill"
                  style={{ marginRight: '10px' }}
                />
              ),
              error: (
                <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
              ),
            }}
            dense
            autoHideDuration={2500}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
          <div className="formHeader">
            <div className="formLogo">
              <Handbag size={28} color="#ffffff" weight="fill" />
            </div>
          </div>
          <h2 className="modalTitle">{title}</h2>
          <div className="formProduto">
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
                className="formFields"
              >
                <label htmlFor="groupSelect">Produto:</label>
                <select
                  id="groupSelect"
                  value={plan.id}
                  className="inputField"
                  placeholder="seleciona uma opção"
                  defaultChecked
                  onChange={(e) => {
                    handlePlan(e.target.value);
                  }}
                >
                  <option disabled value="">
                    Escolha um Plano
                  </option>
                  {listPlans?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="buttonsCreateTariff">
            <MDButton
              onClick={() => handleClose()}
              className="formBtn"
              color="error"
              variant="contained"
            >
              Cancelar
            </MDButton>

            <MDButton
              onClick={() => handleProduct()}
              className="formBtn"
              color="success"
              variant="contained"
            >
              Criar
            </MDButton>
          </div>
        </form>
      </Modal>
    </div>
  );
}
