/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components

// Material Dashboard 2 React examples
import { Card } from '@mui/material';
import { useState } from 'react';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MDBox from '../../components/MDBox';
import MDButton from '../../components/MDButton';
import MDTypography from '../../components/MDTypography';
// import Footer from '../../examples/Footer';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import CustomerTable from '../logins/customerTable/index.jsx';

// Billing page components

// import BillingInformation from './components/BillingInformation/index.jsx';
// import Transactions from "layouts/billing/components/Transactions";

function CustomersPage() {
  const role = useSelector((state) => state.auth.user.role);
  const [isFiltered, setIsFiltered] = useState(false);
  const [search, setSearch] = useState('');
  const [query, setQuery] = useState('');
  const [typeSearch, setTypeSearch] = useState('Cliente');

  function handleSearchEvent(e) {
    if (e === 'Enter') {
      setQuery(search);
      setIsFiltered(true);
    }
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {role !== 'operatorTubaron' && role !== 'admin' ? (
        <Link to="/createCustomer" style={{ color: '#fff' }}>
          <MDButton color="success">Novo Cliente</MDButton>
        </Link>
      ) : null}
      <MDBox mt={6} mb={3}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="info"
              display={{ sm: 'flex' }}
              alignItems={{ sm: 'center' }}
              justifyContent={{ sm: 'space-between' }}
            >
              <MDTypography variant="h6" color="white">
                Clientes
              </MDTypography>
              <div className="select" style={{ width: 'auto', marginRight: '30px', gap: '0.5rem' }}>
                <select
                  name="tipo"
                  id="tipo"
                  style={{
                    border: '1px solid #fff',
                    color: '#fff',
                    marginLeft: '5px',
                  }}
                  value={typeSearch}
                  onChange={(e) => {
                    setTypeSearch(e.target.value);
                  }}
                >
                  <option value="">Selecione uma opção</option>
                  <option value="plano">Plano</option>
                  <option value="cidade">Cidade</option>
                  <option value="resale">Revenda</option>
                  <option value="cliente">Cliente</option>
                  <option value="contrato">Status Contrato</option>
                  <option value="acesso">Status Acesso</option>
                </select>
                <input
                  id="myInput"
                  className="myInput"
                  placeholder={`${typeSearch}...`}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={(e) => handleSearchEvent(e.key)}
                />
              </div>
            </MDBox>
            <MDBox pt={1} pb={2} px={2}>
              <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                <CustomerTable
                  filtered={isFiltered}
                  query={query}
                  type={typeSearch}
                />
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default CustomersPage;
