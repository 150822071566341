/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import { Modal, Tooltip } from '@mui/material';

import axios from 'axios';
import { Eye, FileSearch } from 'phosphor-react';
import { useState, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import ChannelGroupModal from 'components/ChannelGroupModal';
import { baseUrl } from '../../utils/base_url';

import MDButton from '../MDButton';

import './styles.css';
import SearchInput from '../ChannelGroupModal/components/SearchInput.jsx';

export default function PlanDetails({
  data,
  title,
  variant,
  color,
  iconVariant,
  className,
  btnText,
  handleListGroupsChange,
  handleListProgrammsChange,
  showListGroups = true,
}) {
  const role = useSelector((state) => state.auth.user.role);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [filtredChannelsList, setFiltredChannelsList] = useState([]);
  const [search, setSearch] = useState('');
  const [channelGroup, setChannelGroup] = useState([]);
  const isFiltred = search.length > 0;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEditGroup = (group) => {
    if (group) {
      setOpenModal(true);
    } else {
      setOpenModal(!openModal);
    }
    setChannelGroup(group);
  };

  useEffect(() => {
    const channels = data.channels?.filter((item) =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );
    setFiltredChannelsList(channels);
  }, [search]);

  return (
    <div style={{ position: 'relative' }}>
      <span>
        {role !== 'operatorTubaron' && (
          <MDButton
            sx={{ ml: 0.5 }}
            ize="medium"
            variant="gradient"
            color="secondary"
            onClick={handleOpen}
            iconOnly={iconVariant === 'see'}
          >
            {iconVariant === 'see' ? <Eye size={18} /> : null}
            {btnText}
          </MDButton>
        )}
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ position: 'absolute', top: '0' }}
      >
        <form action="" className="operatorForm">
          <div className="formHeader">
            <div className="formLogo">
              <FileSearch size={36} color="#ffffff" weight="fill" />
            </div>
          </div>
          <h2 className="modalTitle">{title}</h2>
          <SearchInput
            label="Filtro de Canais"
            placeholder="canais..."
            value={search}
            onChange={(event) => setSearch(event)}
            color='white'
            
          />
          <div className="planChannels">
            {isFiltred
              ? filtredChannelsList?.map((channel) => (
                  <div key={channel.id}>
                    <img src={channel.url} alt="" className="channelLogo" />
                  </div>
                ))
              : data.channels?.map((channel) => (
                  <div key={channel.id}>
                    <img src={channel.url} alt="" className="channelLogo" />
                  </div>
                ))}
          </div>
          <div className="buttonsCreateTariff">
            <MDButton
              onClick={() => handleClose()}
              className="formBtn"
              color="error"
              variant="contained"
            >
              Fechar
            </MDButton>
          </div>
        </form>
      </Modal>
    </div>
  );
}
