import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../utils/base_url';

export const tariffService = createApi({
  reducerPath: 'tariffService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    findAllTariffs: builder.query({
      query: () => '/plan',
    }),
    findAllTariffsSearch: builder.query({
      query: (query) => ({
        url: '/plan/search/',
        params: { query },
      }),
    }),
    createTariff: builder.mutation({
      query: (payload) => ({
        url: '/plan',
        method: 'POST',
        body: payload,
      }),
    }),
    updateTariff: builder.mutation({
      query: (payload) => {
        const { id } = payload;
        return {
          url: `/plan/${id}`,
          method: 'PATCH',
          body: payload,
        };
      },
    }),
    deleteTariff: builder.mutation({
      query: (id) => ({
        url: `/plan/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFindAllTariffsQuery,
  useFindAllTariffsSearchQuery,
  useCreateTariffMutation,
  useUpdateTariffMutation,
  useDeleteTariffMutation,
} = tariffService;
