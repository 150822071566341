/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
// import Footer from 'examples/Footer';
import ReportsBarChart from 'examples/Charts/BarCharts/ReportsBarChart';
import ReportsLineChart from 'examples/Charts/LineCharts/ReportsLineChart';
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard';

// Data
import reportsBarChartData from 'layouts/dashboard/data/reportsBarChartData';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';

// Dashboard components
import Projects from 'layouts/dashboard/components/Projects';
import OrdersOverview from 'layouts/dashboard/components/OrdersOverview';
// eslint-disable-next-line import/no-unresolved
import MDBox from 'components/MDBox';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl } from 'utils/base_url';
import { useDispatch, useSelector } from 'react-redux';
import { Article, UserCirclePlus, UserList, Wallet } from 'phosphor-react';
import { changeDashboard } from 'redux/dashboardSlice';
import { Link } from 'react-router-dom';
import { changeOrders } from 'redux/ordersSlice';

function Dashboard() {
  const dashboard = useSelector((state) => state.dashboard);
  const orders = useSelector((state) => state.orders.orders);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const { sales, tasks } = reportsLineChartData;
  const [infos, setInfos] = useState();
  useEffect(() => {
    axios
      .get(`${baseUrl}/dashboard`)
      .then((data) => {
        dispatch(changeDashboard(data.data));
      })
      .catch((error) => {
        console.log(error);
      });

    axios.get(`${baseUrl}/customer`).then((data) => {
      dispatch(changeOrders(data.data));
    });
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          {user.role === 'admin' ? (
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="person_add"
                  title="Logins"
                  count={
                    dashboard?.dashboard === undefined ? '0' : dashboard?.dashboard.totalTbAccounts
                  }
                  percentage={{
                    color: 'success',
                    amount: '',
                    label: 'Just updated',
                  }}
                />
              </MDBox>
            </Grid>
          ) : null}
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              {user.role === 'admin' ? (
                <Link to="/planos">
                  <ComplexStatisticsCard
                    color="dark"
                    icon={<Article />}
                    title="Mais vendido"
                    count={
                      dashboard.dashboard === undefined ||
                      dashboard.dashboard.mostSeled === undefined
                        ? 'Nenhum plano vendido'
                        : dashboard.dashboard.mostSeled.name
                    }
                  />
                </Link>
              ) : (
                <ComplexStatisticsCard
                  color="dark"
                  icon={<UserCirclePlus color="#ffffff" weight="fill" />}
                  title="Logins Criados"
                  count={
                    dashboard.dashboard === undefined
                      ? 'Nenhum plano vendido'
                      : dashboard.dashboard.totalTbAccounts
                  }
                />
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <Link to="/clientes">
                <ComplexStatisticsCard
                  color="secondary"
                  icon={<UserList color="#fff" weight="fill" />}
                  title="Clientes"
                  count={
                    dashboard.dashboard === undefined ? '0' : dashboard.dashboard.totalCustomers
                  }
                />
              </Link>
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            {user.role === 'admin' ? (
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon={<Wallet color="#fff" weight="fill" />}
                  title="Faturamento"
                  count={
                    dashboard.dashboard === undefined ? 'R$0' : dashboard.dashboard.totalRevenue
                  }
                  percentage={{
                    color: 'success',
                    amount: '+1%',
                    label: 'than yesterday',
                  }}
                />
              </MDBox>
            ) : (
              null
             /*  <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="info"
                  icon={<Wallet color="#fff" weight="fill" />}
                  title="Comissão"
                  count={
                    dashboard.dashboard === undefined ? 'R$0' : dashboard.dashboard.totalRevenue
                  }
                  percentage={{
                    color: 'success',
                    amount: '+1%',
                    label: 'than yesterday',
                  }}
                />
              </MDBox> */
            )}
          </Grid>
        </Grid>
        {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview
                customers={
                  dashboard.dashboard === undefined
                    ? orders
                    : dashboard.dashboard.lastFiveTbAccounts
                }
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
