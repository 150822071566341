/* eslint-disable no-unused-vars */
import { Grid } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { baseUrl } from '../../utils/base_url';
import CustomerTableByResale from '../../components/CustomerTableByResale/index.jsx';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import Footer from '../../examples/Footer';
import MDTypography from '../../components/MDTypography';
import MDBox from '../../components/MDBox';

export default function CustomersPageByResale() {
  const locationState = useLocation();

  const storeUpdate = useSelector((state) => state.resale.updateResale);
  const [isFiltered, setIsFiltered] = useState(false);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState();
  const [query, setQuery] = useState();

  function handleSearchEvent(e) {
    if (e === 'Enter') {
      setQuery(search);
      setIsFiltered(true);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid item xs={12}>
          <div>
            <MDBox
              sx={{ mx: 3, mb: -1, position: 'relative' }}
              mx={2}
              mt={-2}
              py={3}
              px={2}
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="info"
              display={{ sm: 'flex' }}
              alignItems={{ sm: 'center' }}
              justifyContent={{ sm: 'space-between' }}
            >
              <MDTypography variant="h6" color="white">
                Clientes {locationState.state.name}
              </MDTypography>
              <input
                id="myInput"
                className="myInput"
                placeholder="Cliente..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyDown={(e) => handleSearchEvent(e.key)}
              />
            </MDBox>
            <MDBox pt={-3} pb={2} px={2}>
              <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                <CustomerTableByResale
                  data={locationState.state.id_ixc}
                  filtered={isFiltered}
                  query={query}
                />
              </MDBox>
            </MDBox>
          </div>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
