import { createSlice } from '@reduxjs/toolkit';

export const resaleslice = createSlice({
  name: 'resale',
  initialState: {
    resale: {},
    updateResale: false,
  },
  reducers: {
    selectResale: (state, action) => {
      state.resale = action.payload;
    },
    cleanResale: (state, action) => {
      state.resale = action.payload;
    },
    changeUpdate: (state, action) => {
      state.updateResale = action.payload;
    },
  },
});

export const { selectResale, cleanResale, changeUpdate } = resaleslice.actions;

export const selectUser = (state) => state.user;

export default resaleslice.reducer;
