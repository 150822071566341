/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import { CheckCircle, CircleNotch, MonitorPlay, UserList, XCircle } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import './styles.css';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { changeUpdate } from 'redux/resaleSlice';
import { changeUser } from 'redux/userSlice';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../../utils/base_url';
import MDButton from '../MDButton';

// const schema = yup.object().shape({
//   login: yup.string().required('Campo obrigatório'),
//   password: yup
//     .string()
//     .required('Campo obrigatório')
//     .min(6, 'Senha deve ter no mínimo 6 caracteres'),
//   controleDosPais: yup.string().required('Campo obrigatório'),
//   plano: yup.string().required('Campo obrigatório'),
// });

export default function TbAccountModal({ customerId, contractValidation }) {
  const storeUpdate = useSelector((state) => state.resale.updateResale);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  // const {
  //   register,
  //   handleSubmit,
  //   watch,
  //   formState: { errors },
  // } = useForm({ resolver: yupResolver(schema) });

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  // const [product, setProduct] = useState();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setLogin('');
    setPassword('');
    setOpen(false);
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };
  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };
  const onSubmit = async (data) => {
    if (login.length === 0) {
      errorNotify('Login não pode estar vazio!');
      return;
    }
    if (password.length === 0) {
      errorNotify('Senha não pode estar vazio!');
      return;
    }
    setIsLoading(true);
    await axios
      .patch(`${baseUrl}/tb-account/add-login/${customerId}`, {
        login,
        password,
      })
      .then((thisData) => {
        successNotify('Produto adicionado com sucesso!');
        dispatch(changeUser(thisData.data.tb_account.customer));
        setIsLoading(false);
        handleClose();
      })
      .catch((error) => {
        console.log(error);
        // errorNotify('Login ja esta sendo utilizado!');
        setIsLoading(false);
      });
    setIsLoading(false);
    dispatch(changeUpdate(!storeUpdate));
  };

  return (
    <>
      <MDButton
        sx={{ ml: 0.5 }}
        ize="medium"
        variant="gradient"
        color="warning"
        onClick={handleOpen}
        // disabled={contractValidation}
      >
        Adicionar Login
      </MDButton>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      >
        <form className="customerForm">
          <div className="formHeader">
            <div className="formLogo">
              <MonitorPlay size={32} color="#ffffff" weight="bold" />
            </div>
          </div>
          <h2 className="tbAccountModalTitle">Login</h2>
          <div className="customerFormFields">
            <div
              style={{ display: 'flex', flexDirection: 'column', margin: '30px 0' }}
              className="formFields"
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginLeft: '5px',
                  marginRight: '5px',
                  marginBottom: '3px',
                }}
              >
                <label htmlFor="login">Login / Email: </label>
              </div>
              <input
                className="inputField"
                type="text"
                id="login"
                onChange={(e) => setLogin(e.target.value)}
              />
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', margin: '30px 0' }}
              className="formFields"
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginLeft: '5px',
                  marginRight: '5px',
                  marginBottom: '3px',
                }}
              >
                <label htmlFor="password">Senha:</label>
              </div>
              <input
                className="inputField"
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="customerButtonsCreateResale">
            <button
              type="button"
              onClick={() => {
                handleClose();
              }}
              className="customerCancelBtn"
              variant="contained"
            >
              Cancelar
            </button>
            <button type="button" className="customerCreateBtn" onClick={onSubmit}>
              {isLoading === true ? (
                <CircleNotch size={16} weight="bold" className="isLoading" />
              ) : (
                'Criar'
              )}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}
