/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from 'react';

// react-router components
import { useLocation, Link } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @material-ui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components

import { useDispatch, useSelector } from 'react-redux';

import { ResaleInfos } from '../../../components/ResaleInfos/index.jsx';
import { cleanDashboard } from '../../../redux/dashboardSlice';
import { EditOperatorModal } from '../../../components/EditOperatorModal/index.jsx';
import MDBox from '../../../components/MDBox';

// Material Dashboard 2 React example components
import Breadcrumbs from '../../Breadcrumbs';

// Custom styles for DashboardNavbar
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav } from '../../../context';
import { navbar, navbarContainer, navbarRow, navbarIconButton, navbarMobileMenu } from './styles';
import { AuthContext } from '../../../context/auth';
import SpecModal from '../../../components/SpecModal/index.jsx';

// Material Dashboard 2 React context

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split('/').slice(1);
  // const [openModal, setOpenModal] = useState(false);
  const thisDispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
         horizontal: 'left',
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2, mr: 25, display: 'flex', flexDirection: 'column' }}
    >
      <EditOperatorModal
        variant="text"
        iconVariant="user"
        title="Perfil"
        data={user}
        onClose={handleCloseMenu}
      />
      <ResaleInfos
        variant="text"
        iconVariant="resale"
        title="Revenda"
        data={user}
        onClose={handleCloseMenu}
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const { clearToken } = useContext(AuthContext);

  function handleLogout() {
    clearToken();
  }

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      {user.spec ? (
        <div>
          <SpecModal modalOpen iconsStyle={iconsStyle} miniSidenav={miniSidenav} />
        </div>
      ) : null}
      <Toolbar sx={(theme) => navbarContainer(theme)}>
          <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
            <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          </MDBox>
          {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })} mr={3}>
              <MDBox color={light ? 'white' : 'inherit'}>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <Icon sx={iconsStyle}>settings</Icon>
                </IconButton>
                <Link to="/sign-in/">
                  <IconButton
                    sx={navbarIconButton}
                    size="small"
                    disableRipple
                    onClick={() => {
                      handleLogout();
                      thisDispatch(cleanDashboard());
                    }}
                  >
                    <Icon sx={iconsStyle}>logout</Icon>
                  </IconButton>
                </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>

                {renderMenu()}
              </MDBox>
            </MDBox>
          )}
        </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
