import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLogged: false,
  user: {},
  token: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoggedState: (state, action) => {
      state.isLogged = action.payload;
    },
    setUserState: (state, action) => {
      state.user = action.payload;
    },
    setTokenState: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { setIsLoggedState, setUserState, setTokenState } = authSlice.actions;

export default authSlice.reducer;
