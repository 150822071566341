/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
// import axios from 'axios';
// import DataTable from 'examples/Tables/DataTable';
// import Bill from 'layouts/customers/components/Bill';

import axios from 'axios';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { CheckCircle, Pencil, XCircle } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MDBox from '../../../../components/MDBox';

import MDTypography from '../../../../components/MDTypography';
import { useMaterialUIController } from '../../../../context';
import DataTable from '../../../../examples/Tables/DataTable';

import { changeUser } from '../../../../redux/userSlice';
import { baseUrl } from '../../../../utils/base_url';

export default function DashCustomers() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const myRows = [];
  const [users, setUsers] = useState([]);
  const [update, setUpdate] = useState(false);

  const storeUpdate = useSelector((state) => state.resale.updateResale);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    axios.get(`${baseUrl}/customer/lastFive`).then((data) => {
      setUsers(data.data);
    });
  }, [update]);

  function handleCustomer(value) {
    dispatch(changeUser(value));
  }

  function myRowOperatorTubaron(item) {
    return myRows.push({
      name: (
        // removido a ação de clicar no nome do cliente na dashboard
        // <Link to="/cliente" onClick={() => handleCustomer(item)} className="profileInfos">
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            flexDirection={{ xs: 'row', sm: 'row' }}
            mb={2}
          >
            <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
              {item.razao}
            </MDTypography>
          </MDBox>
        // </Link>
      ),
      revenda: (
        <MDBox mb={1} lineHeight={1}>
          <MDTypography variant="caption" fontWeight="medium">
            {item.resale.name}
          </MDTypography>
        </MDBox>
      ),
      city: (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'row', sm: 'row' }}
          mb={2}
        >
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {item.city.name}
          </MDTypography>
        </MDBox>
      ),
      document: (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'row', sm: 'row' }}
          mb={2}
        >
          <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
            {item.cnpj_cpf}
          </MDTypography>
        </MDBox>
      ),
    });
  }

  function handleMyRows(item) {
    if (user.role === 'operatorTubaron' || user.role === 'admin') {
      return myRowOperatorTubaron(item);
    }
    return myRows.push({
      name: (
        // <Link
        //   to="/cliente"
        //   onClick={() => {
        //     handleCustomer(item);
        //   }}
        //   className="profileInfos"
        // >
          <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              flexDirection={{ xs: 'row', sm: 'row' }}
              mb={0}
            >
              <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                {item.razao}
              </MDTypography>
            </MDBox>
          </MDBox>
        // </Link>
      ),
    });
  }

  if (users.length > 0) {
    users.forEach((item) => {
      handleMyRows(item);
    });
  }

  // || user.role === 'admin'
  function myTableData() {
    if (user.role === 'operatorTubaron' || user.role === 'admin') {
      return {
        columns: [
          { Header: 'Cliente', accessor: 'name' },
          { Header: 'Cidade', accessor: 'city' },
          { Header: 'Revenda', accessor: 'revenda' },
          { Header: 'Documento', accessor: 'document' },
        ],
        rows: myRows,
      };
    }
    return {
      columns: [
        { Header: 'cliente', accessor: 'name', width: '70%', align: 'left' },
        // { Header: 'Faturamento', accessor: 'revenue', width: '30%', align: 'center' },
      ],
      rows: myRows,
    };
  }

  return (
    <>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <DataTable
        entriesPerPage={false}
        canSearch={false}
        table={myTableData()}
        pagination={{ variant: 'gradient', color: 'warning' }}
        isSorted={false}
        noEndBorder
      />
    </>
  );
}
