/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable */
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { CheckCircle, FolderNotch, XCircle } from 'phosphor-react';
import DataTable from 'examples/Tables/DataTable';
import TableWithPaymentList from '../TableWithPaymentList';
import MDButton from 'components/MDButton';
import { Link, useNavigate } from 'react-router-dom';

export default function PaymentTable({ resale, setupdate, filtredByStatus, search, handlePaymentEventChange }) {
  const myRows = [];
  const navigate = useNavigate();

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  function myTableData() {
    return {
      columns: [
        { Header: 'Revenda', accessor: 'name', width: '25%', align: 'left' },
        { Header: '', accessor: 'action', width: '10%', align: 'center' },
      ],
      rows: myRows,
    };
  }

  function renderMyRows(item) {
    return myRows.push({
      name: (
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
            {item.name}
          </MDTypography>
        </MDBox>
      ),
      action: (
        <MDBox width="100%" display="flex" alignItems="flex-end" flexDirection="column" border>
          <MDBox display="flex" alignItems="right" gap={1}>
            <Link to="/resale/pagamentos" state={item}>
              <MDButton sx={{ ml: 0.5 }} ize="medium" variant="gradient" color="info">
                <FolderNotch weight="fill" size={16} style={{ marginRight: '8px' }} /> Pagamentos
              </MDButton>
            </Link>
          </MDBox>
        </MDBox>
      ),
    });
  }

  if (resale.length > 0) {
    resale.forEach((item) => {
      renderMyRows(item);
    });
  }

  return (
    <>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <DataTable
        table={myTableData()}
        isSorted={true}
        entriesPerPage={false}
        noEndBorder
        pagination={{ variant: 'gradient', color: 'warning' }}
      />
    </>
  );
}
