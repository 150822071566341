/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import { Modal, Tooltip } from '@mui/material';

import axios from 'axios';
import { Eye, FileSearch } from 'phosphor-react';
import { useState, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import ChannelGroupModal from 'components/ChannelGroupModal';
import MDButton from 'components/MDButton';
import ChannelsTable from '../../Channels/ChannelTable/index.jsx';

export default function DetailsChannelGroup({
  data,
  title,
  variant,
  color,
  iconVariant,
  className,
  btnText,
  handleListProgrammsChange,
  showListGroups = true,
  channelGroupChannels,
}) {
  const role = useSelector((state) => state.auth.user.role);
  const [programmerGroups, setProgrammerGroups] = useState([]);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [channelGroup, setChannelGroup] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEditGroup = (group) => {
    if (group) {
      setOpenModal(true);
    } else {
      setOpenModal(!openModal);
    }
    setChannelGroup(group);
  };

  return (
    <div  style={{ position: 'relative', }}>
      <span>
        {role !== 'operatorTubaron' && (
          <MDButton
            sx={{ ml: 0.5 }}
            ize="medium"
            variant="gradient"
            color="secondary"
            onClick={handleOpen}
            iconOnly={iconVariant === 'see'}
          >
            {iconVariant === 'see' ? <Eye size={18} /> : null}
            {btnText}
          </MDButton>
        )}
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form action="" className="operatorForm">
          <div className="formHeader">
            <div className="formLogo">
              <FileSearch size={36} color="#ffffff" weight="fill" />
            </div>
          </div>
          <div style={{ width: '100%' }}>
            <h2 style={{textAlign: 'center'}}>{title}</h2>
            <ChannelsTable isDetails channels={channelGroupChannels} />
          </div>
        </form>
      </Modal>
    </div>
  );
}
