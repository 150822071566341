/* eslint-disable consistent-return */
export default function percentFormater(loginLimit, loginCounter) {
  const result = ((loginCounter * 100) / loginLimit).toString();

  if (result.length >= 5) {
    return result.substring(0, 2);
  }
  if (result.length === 5 || result.length === 4) {
    return result.substring(0, 2);
  }

  return result;
}
