/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable */
// Images
import DataTable from 'examples/Tables/DataTable/index.js';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { CheckCircle, FileArrowDown, FileCsv, WarningOctagon, XCircle } from 'phosphor-react';
import { useRef } from 'react';
import MDButton from 'components/MDButton/index.js';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import generatePDF from '../../../utils/GeneratePDF';

export default function SignatureTable({ list }) {
  const role = useSelector((state) => state.auth.user.role);
  const rows = [];
  const myRows = [];
  const componentRef = useRef();

  const columnsPDF =
    role === 'admin'
      ? [
          {
            id: 'ID',
            client: 'Cliente',
            city: 'Cidade',
            resale: 'Revenda',
            contract: 'Data contrato',
            phone: 'Telefone',
          },
        ]
      : [
          {
            id: 'ID',
            client: 'Cliente',
            city: 'Cidade',
            resale: 'Revenda',
            contract: 'Data contrato',
            phone: 'Telefone',
          },
        ];

  function createData(id, client, city, resale, contract, phone) {
    return {
      id,
      client,
      city,
      resale,
      contract,
      phone,
    };
  }

  if (role === 'admin') {
    list?.map((item) => {
      const temp = createData(
        item.id,
        item.nome,
        item.cidade,
        item.resale,
        item.data_contrato,
        item.telefone
      );
      rows.push(temp);
    });
  } else {
    list?.map((item) => {
      const temp = createData(
        item.id,
        item.nome,
        item.cidade,
        item.resale,
        item.data_contrato,
        item.telefone
      );
      rows.push(temp);
    });
  }

  function renderMyRows(item) {
    if (role === 'admin') {
      return myRows.push({
        id: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.id}
            </MDTypography>
          </MDBox>
        ),
        client: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.client}
            </MDTypography>
          </MDBox>
        ),
        resale: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.resale}
            </MDTypography>
          </MDBox>
        ),
        contract: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.contract}
            </MDTypography>
          </MDBox>
        ),
        cidade: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.city}
            </MDTypography>
          </MDBox>
        ),
        phone: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.phone}
            </MDTypography>
          </MDBox>
        ),
      });
    } else {
      return myRows.push({
        id: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.id}
            </MDTypography>
          </MDBox>
        ),
        client: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.client}
            </MDTypography>
          </MDBox>
        ),
        resale: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.resale}
            </MDTypography>
          </MDBox>
        ),
        contract: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.contract}
            </MDTypography>
          </MDBox>
        ),
        cidade: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.city}
            </MDTypography>
          </MDBox>
        ),
        phone: (
          <MDBox>
            <MDTypography variant="caption" fontWeight="medium" fontSize={12}>
              {item.phone}
            </MDTypography>
          </MDBox>
        ),
      });
    }
  }

  if (rows.length > 0) {
    rows.forEach((item) => {
      renderMyRows(item);
    });
  }

  function myTableData() {
    if (role === 'admin') {
      return {
        columns: [
          { Header: 'ID', accessor: 'id', width: '5%', align: 'center' },
          { Header: 'Cliente', accessor: 'client', width: '10%', align: 'center' },
          { Header: 'Revenda', accessor: 'resale', width: '5%', align: 'left' },
          { Header: 'Cidade', accessor: 'cidade', width: '10%', align: 'left' },
          { Header: 'Data Contrato', accessor: 'contract', width: '10%', align: 'left' },
          { Header: 'Telefone', accessor: 'phone', width: '10%', align: 'left' },
        ],
        rows: myRows,
      };
    }
    return {
      columns: [
        { Header: 'ID', accessor: 'id', width: '5%', align: 'center' },
        { Header: 'Cliente', accessor: 'client', width: '10%', align: 'center' },
        { Header: 'Revenda', accessor: 'resale', width: '5%', align: 'left' },
        { Header: 'Cidade', accessor: 'cidade', width: '10%', align: 'left' },
        { Header: 'Data Contrato', accessor: 'contract', width: '10%', align: 'left' },
        { Header: 'Telefone', accessor: 'phone', width: '10%', align: 'left' },
      ],
      rows: myRows,
    };
  }

  return (
    <div ref={componentRef} style={{ display: 'flex', flexDirection: 'column' }}>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <div>
        <div
          style={{
            display: 'flex',
            gap: '2rem',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '-0.8rem',
          }}
        >
          {list.length === 0 ? null : (
            <div
              style={{ display: 'flex', gap: '0.5rem', width: '100%', justifyContent: 'flex-end' }}
            >
              <MDButton
                color="success"
                iconOnly
                style={{ alignSelf: 'flex-end', marginTop: '0.5rem' }}
              >
                <CSVLink
                  filename="my-file.csv"
                  style={{ display: 'flex', alignItems: 'center' }}
                  data={rows}
                >
                  <FileCsv size={16} color="white" fill="#fff" />
                </CSVLink>
              </MDButton>

              <MDButton
                color="info"
                iconOnly
                className="downloadButton"
                style={{ alignSelf: 'flex-end', marginTop: '0.5rem' }}
                onClick={() => generatePDF(rows, columnsPDF, true, role, 'AguardandoAssinatura')}
              >
                <FileArrowDown size={24} color="white" fill="#fff" />
              </MDButton>
            </div>
          )}
        </div>
      </div>
      {list.length === 0 ? (
        <div className="emptyIcon">
          <WarningOctagon size={200} weight="bold" />
          <span>Não Retornou Dados</span>
        </div>
      ) : (
        <DataTable
          table={myTableData()}
          isSorted={true}
          entriesPerPage
          noEndBorder
          pagination={{ variant: 'gradient', color: 'warning' }}
          showTotalEntries
        />
      )}
    </div>
  );
}
