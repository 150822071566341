/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react';

// @mui material components
import Card from '@mui/material/Card';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// Material Dashboard 2 React components
import axios from 'axios';
import { baseUrl } from '../../../../utils/base_url';
import data from './data';
import DataTable from '../../../../examples/Tables/DataTable';
import MDBox from '../../../../components/MDBox';
import MDTypography from '../../../../components/MDTypography';
import DashCustomers from '../DashCustomers/index.jsx';

// Material Dashboard 2 React examples

// Data

function Projects() {
  const [totalCustomers, setTotalCustomers] = useState();
  const { columns, rows } = data();
  const [menu, setMenu] = useState(null);

  useEffect(() => {
    axios.get(`${baseUrl}/customer`).then((thisData) => setTotalCustomers(thisData.data));
  }, []);

  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card sx={{ height: '100%' }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Ultimos Clientes
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox>
        <DashCustomers />
        {/* <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        /> */}
      </MDBox>
    </Card>
  );
}

export default Projects;
