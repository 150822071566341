/* eslint-disable no-unused-vars */
/* eslint-disable */
import { Card, CircularProgress, Grid, Tooltip } from '@mui/material';
// import CustomerTableByResale from 'components/CustomerTableByResale';
import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { CheckCircle, XCircle } from 'phosphor-react';
import ProgrammerModal from '../../components/ProgrammerModal/index.jsx';
import MDButton from '../../components/MDButton';
import DeleteProgrammerGroupChannelModal from '../../components/DeleteProgrammerGroupChannelModal/index.jsx';
import { baseUrl } from '../../utils/base_url';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';

import './styles.css';
import ProgrammerDetailsModal from 'components/ProgrammerDetailsModal/index.jsx';
import { ProgrammerTable } from './ProgrammerTable/index.jsx';

export default function Programmer() {
  const role = useSelector((state) => state.auth.user.role);
  const [programmers, setProgrammers] = useState([]);
  const [update, setupdate] = useReducer((x) => x + 1, 0);
  const [query, setQuery] = useState('');
  const [search, setSearch] = useState('');
  const [programmerData, setProgrammerData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl}/tv-programmer`).then((data) => {
      setProgrammers(data.data.payload);
      setLoading(false);
    });
  }, [update]);

  function handleListProgrammsChange(data) {
    if (data.success === true) {
      successNotify(data.message);
      setupdate();
    } else {
      errorNotify(data.message);
    }
  }

  function handleSearchEvent(e) {
    if (e === 'Enter') {
      axios.get(`${baseUrl}/tv-programmer`).then((data) => {
        const filteredProgrammers = data.data.payload.filter((programmer) => {
          return programmer.name.toLowerCase().includes(search.toLowerCase());
        });
        setProgrammers(filteredProgrammers);
      });
      setQuery(search);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {role !== 'operatorTubaron' && (
        <div className="btnContainer">
          <SnackbarProvider
            iconVariant={{
              success: (
                <CheckCircle
                  size={16}
                  color="#ffffff"
                  weight="fill"
                  style={{ marginRight: '10px' }}
                />
              ),
              error: (
                <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
              ),
            }}
            dense
            autoHideDuration={2500}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
          <ProgrammerModal
            size="small"
            iconVariant="add"
            btnText="Criar Programadora"
            title="Criar Programadora"
            handleListProgrammsChange={handleListProgrammsChange}
            color="success"
          />
        </div>
      )}

      <MDBox mt={6} mb={3}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={1}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="info"
              display={{ sm: 'flex' }}
              alignItems={{ sm: 'center' }}
              justifyContent={{ sm: 'space-between' }}
            >
              <MDTypography variant="h6" color="white">
                Programadoras
              </MDTypography>
              <div className="menuOptions">
                <input
                  id="myInput"
                  className="myInput"
                  placeholder={`Programadoras...`}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={(e) => handleSearchEvent(e.key)}
                />
              </div>
            </MDBox>
            <MDBox pt={-3} pb={2} px={2}>
              <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                {loading ? (
                  <div className="loading">
                    <CircularProgress color="warning" size={60} />
                  </div>
                ) : (
                 <ProgrammerTable programmer={programmers} setupdate={setupdate} />
                )}
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
