/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */

import axios from 'axios';

import { CheckCircle, Eye, Pencil, PencilSimple, Plus, XCircle } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { useContext, useState } from 'react';
import * as Switch from '@radix-ui/react-switch';
import ConfirmModal from '../SpecModal/components/ConfirmModal/index.jsx';
import { AuthContext } from '../../context/auth';
import MDButton from '../MDButton/index';
import ConfirmIncrement from '../ConfirmIncrement/index.jsx';
import ConfirmDecrement from '../ConfirmDecrement/index.jsx';
import { changeUpdate, selectResale } from '../../redux/resaleSlice';
import { EditResaleModal } from '../EditResaleModal/index.jsx';

import percentFormater from '../../utils/percentFormatter';
import MySwitch from '../MySwitch/MySwitch.jsx';

import DataTable from '../../examples/Tables/DataTable';
import MDBox from '../MDBox';
import MDTypography from '../MDTypography';
import MDProgress from '../MDProgress';

import './styles.css';
import { baseUrl } from '../../utils/base_url';

export default function MyDataTable({ resales, filtered, query, type }) {
  const storeUpdate = useSelector((state) => state.resale.updateResale);
  const { setToken } = useContext(AuthContext);
  const role = useSelector((state) => state.auth.user.role);
  const dispatch = useDispatch();

  const successNotify = (status) => {
    const thisMessage =
      status === true ? `Revenda ativada com sucesso` : `Revenda desativada com sucesso`;
    enqueueSnackbar(thisMessage, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  async function handleUpdateResalesStatus(
    id,
    name,
    city,
    status,
    address,
    address_number,
    neighborhood,
    cnpj_cpf,
    email,
    telefone_celular,
    cep,
    tipo_assinante,
    contribuinte_icms,
    tipo_localidade,
    ie_rg
  ) {
    await axios
      .patch(`${baseUrl}/resale/status`, {
        id,
        status,
      })
      .then((data) => {
        if (data.data.status === 400) {
          errorNotify(data.data.message);
          dispatch(changeUpdate(status));
        } else {
          /*eslint-disable */
          status === true ? successNotify(true) : successNotify(false);
          dispatch(changeUpdate(!storeUpdate));
        }
      })
      .catch((error) => {
        dispatch(changeUpdate(!storeUpdate));
      });
  }

  function updateLoginLimit(action, id) {
    axios.patch(`${baseUrl}/resale/loginLimit/${id}`, {
      action,
    });
    dispatch(changeUpdate(!storeUpdate));
  }

  async function handleSpecMode(id) {
    await axios.post(`${baseUrl}/login/resale/${id}`).then((data) => {
      setToken(data.data.access_token);
    });
  }
  const myRows = [];

  if (resales === undefined) {
    console.log();
  } else if (filtered === true && query.length > 0) {
    if (type === 'nome') {
      const filteredResale = Object.values(resales).filter((resale) =>
        resale.name.toLowerCase().includes(query.toLowerCase())
      );
      filteredResale.forEach((resale) => {
        myRows.push({
          name: (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              <Link to="/clientes/resale" state={resale}>
                <MDTypography display="block" variant="button" fontWeight="medium" lineHeight={1}>
                  {resale.name}
                </MDTypography>
              </Link>
            </MDBox>
          ),
          id_ixc: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {resale.id_ixc}
            </MDTypography>
          ),
          city: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {resale.city.name}
            </MDTypography>
          ),
          login_limit: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div className="loginLimitBtns">
                {role !== 'operatorTubaron' && (
                  <ConfirmDecrement onUpdate={() => updateLoginLimit('dec', resale.id)} />
                )}

                {resale.login_limit}
                {role !== 'operatorTubaron' && (
                  <ConfirmIncrement onUpdate={() => updateLoginLimit('add', resale.id)} />
                )}
              </div>
            </MDTypography>
          ),
          login_counter: (
            <MDBox display="flex" alignItems="center">
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {percentFormater(resale.login_limit, resale.login_counter)}%
              </MDTypography>
              <MDBox ml={0.5} width="9rem">
                <MDProgress
                  variant="gradient"
                  color="warning"
                  value={(resale.login_counter * 100) / resale.login_limit}
                />
              </MDBox>
            </MDBox>
          ),
          status: (
            <Switch.Root
              checked={resale.status}
              onClick={() => {
                handleUpdateResalesStatus(
                  resale.id,
                  resale.name,
                  resale.cityId,
                  !resale.status,
                  resale.address,
                  resale.address_number,
                  resale.neighborhood,
                  resale.cnpj_cpf,
                  resale.email,
                  resale.telefone_celular,
                  resale.cep,
                  resale.tipo_assinante,
                  resale.contribuinte_icms,
                  resale.tipo_localidade,
                  resale.ie_rg
                );
              }}
              className="SwitchRoot"
              id="airplane-mode"
            >
              <Switch.Thumb className="SwitchThumb" />
            </Switch.Root>
          ),
          editar: (
            <div className="buttonsContainer">
              <Link to="/resale/editar" state={{ resale }}>
                <MDButton color="info" size="small" style={{ display: 'flex', gap: '0.5em' }}>
                  <Pencil weight="fill" size={14} />
                  Editar
                </MDButton>
              </Link>
              {role === 'admin' ? (
                <ConfirmModal handleEnterModSpec={() => handleSpecMode(resale.id)} />
              ) : null}
            </div>
          ),
          // <EditResaleModal title="editar" data={resale} />,
        });
      });
    } else if (type === 'cidade') {
      const filteredResale = Object.values(resales).filter((resale) =>
        resale.city.name.toLowerCase().includes(query.toLowerCase())
      );
      filteredResale.forEach((resale) => {
        myRows.push({
          name: (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              <Link to="/clientes/resale" state={resale}>
                <MDTypography display="block" variant="button" fontWeight="medium" lineHeight={1}>
                  {resale.name}
                </MDTypography>
              </Link>
            </MDBox>
          ),
          id_ixc: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {resale.id_ixc}
            </MDTypography>
          ),
          city: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {resale.city.name}
            </MDTypography>
          ),
          login_limit: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div className="loginLimitBtns">
                {role !== 'operatorTubaron' && (
                  <ConfirmDecrement onUpdate={() => updateLoginLimit('dec', resale.id)} />
                )}

                {resale.login_limit}
                {role !== 'operatorTubaron' && (
                  <ConfirmIncrement onUpdate={() => updateLoginLimit('add', resale.id)} />
                )}
              </div>
            </MDTypography>
          ),
          login_counter: (
            <MDBox display="flex" alignItems="center">
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {percentFormater(resale.login_limit, resale.login_counter)}%
              </MDTypography>
              <MDBox ml={0.5} width="9rem">
                <MDProgress
                  variant="gradient"
                  color="warning"
                  value={(resale.login_counter * 100) / resale.login_limit}
                />
              </MDBox>
            </MDBox>
          ),
          status: (
            <Switch.Root
              checked={resale.status}
              onClick={() => {
                handleUpdateResalesStatus(
                  resale.id,
                  resale.name,
                  resale.cityId,
                  !resale.status,
                  resale.address,
                  resale.address_number,
                  resale.neighborhood,
                  resale.cnpj_cpf,
                  resale.email,
                  resale.telefone_celular,
                  resale.cep,
                  resale.tipo_assinante,
                  resale.contribuinte_icms,
                  resale.tipo_localidade,
                  resale.ie_rg
                );
              }}
              className="SwitchRoot"
              id="airplane-mode"
            >
              <Switch.Thumb className="SwitchThumb" />
            </Switch.Root>
          ),
          editar: (
            <div className="buttonsContainer">
              <Link to="/resale/editar" state={{ resale }}>
                <MDButton color="info" size="small" style={{ display: 'flex', gap: '0.5em' }}>
                  <Pencil weight="fill" size={14} />
                  Editar
                </MDButton>
              </Link>
              {role === 'admin' ? (
                <ConfirmModal handleEnterModSpec={() => handleSpecMode(resale.id)} />
              ) : null}
            </div>
          ),
          // <EditResaleModal title="editar" data={resale} />,
        });
      });
    }
  } else {
    Object.values(resales).forEach((resale) => {
      myRows.push({
        name: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <Link to="/clientes/resale" state={resale}>
              <MDTypography display="block" variant="button" fontWeight="medium" lineHeight={1}>
                {resale.name}
              </MDTypography>
            </Link>
          </MDBox>
        ),
        id_ixc: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {resale.id_ixc}
          </MDTypography>
        ),
        city: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {resale.city.name}
          </MDTypography>
        ),
        login_limit: (
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <div className="loginLimitBtns">
              {role !== 'operatorTubaron' && (
                <ConfirmDecrement onUpdate={() => updateLoginLimit('dec', resale.id)} />
              )}

              {resale.login_limit}
              {role !== 'operatorTubaron' && (
                <ConfirmIncrement onUpdate={() => updateLoginLimit('add', resale.id)} />
              )}
            </div>
          </MDTypography>
        ),
        login_counter: (
          <MDBox display="flex" alignItems="center">
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {percentFormater(resale.login_limit, resale.login_counter)}%
            </MDTypography>
            <MDBox ml={0.5} width="9rem">
              <MDProgress
                variant="gradient"
                color="warning"
                value={(resale.login_counter * 100) / resale.login_limit}
              />
            </MDBox>
          </MDBox>
        ),
        status: (
          <Switch.Root
            checked={resale.status}
            onClick={() => {
              handleUpdateResalesStatus(
                resale.id,
                resale.name,
                resale.cityId,
                !resale.status,
                resale.address,
                resale.address_number,
                resale.neighborhood,
                resale.cnpj_cpf,
                resale.email,
                resale.telefone_celular,
                resale.cep,
                resale.tipo_assinante,
                resale.contribuinte_icms,
                resale.tipo_localidade,
                resale.ie_rg
              );
            }}
            className="SwitchRoot"
            id="airplane-mode"
          >
            <Switch.Thumb className="SwitchThumb" />
          </Switch.Root>
        ),
        editar: (
          <div className="buttonsContainer">
            <Link to="/resale/editar" state={{ resale }}>
              <MDButton color="info" size="small" style={{ display: 'flex', gap: '0.5em' }}>
                <Pencil weight="fill" size={14} />
                Editar
              </MDButton>
            </Link>
            {role === 'admin' ? (
              <ConfirmModal handleEnterModSpec={() => handleSpecMode(resale.id)} />
            ) : null}
          </div>
        ),
        // <EditResaleModal title="editar" data={resale} />,
      });
    });
  }

  function myTableData() {
    if (role === 'operatorTubaron') {
      return {
        columns: [
          { Header: 'nome', accessor: 'name', align: 'left' },
          { Header: 'id ixc', accessor: 'id_ixc', align: 'left' },
          { Header: 'cidade', accessor: 'city', align: 'left' },
        ],
        rows: myRows,
      };
    }
    return {
      columns: [
        { Header: 'nome', accessor: 'name', align: 'left' },
        { Header: 'id ixc', accessor: 'id_ixc', align: 'left' },
        { Header: 'cidade', accessor: 'city', align: 'left' },
        { Header: 'status', accessor: 'status', align: 'left' },
        { accessor: 'editar', align: 'left' },
      ],
      rows: myRows,
    };
  }

  return (
    <>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <DataTable
        table={myTableData()}
        isSorted={false}
        entriesPerPage={false}
        noEndBorder
        pagination={{ variant: 'gradient', color: 'warning' }}
      />
    </>
  );
}
