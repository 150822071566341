/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
// Material Dashboard 2 React example components
import { Card, CircularProgress, Grid, Tooltip } from '@mui/material';
// import ResaleModal from 'components/ResaleModal';
import { useSelector } from 'react-redux';

// import Footer from '../../examples/Footer';
import { Link } from 'react-router-dom';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { CheckCircle, XCircle } from 'phosphor-react';
import MDButton from '../../components/MDButton';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DeleteProgrammerGroupChannelModal from '../../components/DeleteProgrammerGroupChannelModal';
import MDTypography from '../../components/MDTypography';
import ChannelGroupModal from '../../components/ChannelGroupModal/index.jsx';
import MDBox from '../../components/MDBox';

import './style.css';
import { baseUrl } from '../../utils/base_url';
import { ChannelGroupTable } from './ChannelGroupTable';

function ChannelGroup() {
  const role = useSelector((state) => state.auth.user.role);
  const [channels, setChannels] = useState([]);
  const [search, setSearch] = useState('');
  const [channelGroup, setChannelGroup] = useState([]);
  const [update, setupdate] = useReducer((x) => x + 1, 0);
  const [openModal, setOpenModal] = useState(false);
  const [group, setGroup] = useState([]);
  const [loading, setLoading] = useState(false);

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  async function handleListGroupsChange(data) {
    successNotify(data.message);
    setTimeout(() => {
      setupdate();
    }, 1000);
  }

  function handleSearchEvent(e) {
    if (e === 'Enter') {
      axios.get(`${baseUrl}/channel-groups`).then((data) => {
        const result = data.data.payload.filter((item) => {
          return item.name.toLowerCase().includes(search.toLowerCase());
        });
        setChannelGroup(result);
      });
    }
  }

  useEffect(() => {
    setLoading(true);
    axios.get(`${baseUrl}/channel-groups`).then((data) => {
      setChannelGroup(data.data.payload);
      setLoading(false);
    });
  }, [update]);

  useEffect(() => {
    axios.get(`${baseUrl}/channels`).then((data) => setChannels(data.data));
  }, []);

  const handleEditGroup = (item) => {
    if (item) {
      setOpenModal(true);
    } else {
      setOpenModal(!openModal);
    }
    setGroup(item);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      {role !== 'operatorTubaron' && (
        <div className="btnContainer">
          <ChannelGroupModal
            size="small"
            iconVariant="add"
            btnText="Criar Grupo de Canais"
            title="Criar Grupo de Canais"
            handleListGroupsChange={handleListGroupsChange}
            color="success"
          />
        </div>
      )}
      <MDBox mt={6} mb={3}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={1}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="info"
              display={{ sm: 'flex' }}
              alignItems={{ sm: 'center' }}
              justifyContent={{ sm: 'space-between' }}
            >
              <MDTypography variant="h6" color="white">
                Grupo de Canais
              </MDTypography>
              <div className="select" style={{ width: 'auto', marginRight: '30px' }}>
                <input
                  id="myInput"
                  className="myInput"
                  placeholder="Grupos de Canais..."
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={(e) => handleSearchEvent(e.key)}
                />
              </div>
            </MDBox>
            <MDBox pt={3}>
              {loading ? (
                <div className="loading">
                  <CircularProgress color="warning" size={60} />
                </div>
              ) : (
                <ChannelGroupTable
                  ChannelGroup={channelGroup}
                  setupdate={setupdate}
                  handleListGroupsChange={handleListGroupsChange}
                />
              )}
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ChannelGroup;
