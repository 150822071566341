import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'orders',
  initialState: {},
  reducers: {
    changeOrders(state, { payload }) {
      return { ...state, orders: payload };
    },
    cleanOrders(state) {
      return { ...state, orders: {} };
    },
  },
});

export const { changeOrders, cleanOrders } = slice.actions;

export const selectDashboard = (state) => state.orders;

export default slice.reducer;
//
