/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable */

import { Modal } from '@mui/material';
import axios from 'axios';
import { Buildings, CheckCircle, CircleNotch, Pencil, XCircle } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { baseUrl } from '../../utils/base_url';
import { changeUpdate } from '../../redux/resaleSlice';
import MDButton from '../MDButton';
import './editResale.css';

export function EditResaleModal({ data }) {
  const testUpdate = useSelector((state) => state.resale.updateResale);
  const dispatch = useDispatch();

  const [changed, setChanged] = useState(false);
  const [name, setName] = useState(data.name);
  const [cidade, setCidade] = useState(data.cityId);
  const [email, setEmail] = useState(data.email);
  const [address, setAdress] = useState(data.address);
  const [addressNumber, setAdressNumber] = useState(data.address_number);
  const [neighborhood, setNeighborhood] = useState(data.neighborhood);
  const [cnpjCpf, setCnpjCpf] = useState(data.cnpj_cpf);
  const [telefoneCelular, setTelefoneCelular] = useState(data.telefone_celular);
  const [cep, setCep] = useState('');

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setChanged(false);
  };

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedCountrie, setSelectedCountrie] = useState(data.city.State.country.id);
  const [selectedState, setSelectedState] = useState(data.city.stateId);
  const [selectedCity, setSelectedCity] = useState(data.cityId);

  const [isLoading, setIsLoading] = useState(false);

  const formatCpfCnpj = (inputValue) => {
    let formattedValue = inputValue.replace(/\D/g, ''); // remove todos os caracteres que não são números

    if (formattedValue.length <= 11) {
      // CPF
      formattedValue = formattedValue.replace(/(\d{3})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/(\d{3})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      // CNPJ
      formattedValue = formattedValue.replace(/^(\d{2})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
      formattedValue = formattedValue.replace(/\.(\d{3})(\d)/, '.$1/$2');
      formattedValue = formattedValue.replace(/(\d{4})(\d)/, '$1-$2');
    }

    return formattedValue;
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatCpfCnpj(inputValue);
    setCnpjCpf(formattedValue);
  };

  const handlePhoneChange = (event) => {
    let formattedPhone = event.target.value.replace(/\D/g, '');
    formattedPhone = formattedPhone.replace(/^(\d{2})(\d)/g, '($1) $2');
    formattedPhone = formattedPhone.replace(/(\d)(\d{4})$/, '$1-$2');
    setTelefoneCelular(formattedPhone);
  };

  const handleCepChange = (event) => {
    let formattedCep = event.target.value.replace(/\D/g, '');
    formattedCep = formattedCep.replace(/^(\d{5})(\d)/g, '$1-$2');
    setCep(formattedCep);
  };
  const findState = async (id) => {
    setSelectedCountrie(id);
    await axios.get(`${baseUrl}/location/states/${id}`).then((thisData) => {
      setStates(thisData.data);
    });
  };

  const findCities = async (id) => {
    setSelectedState(id);
    await axios
      .get(`${baseUrl}/location/cities/${id}`)
      .then((thisData) => setCities(thisData.data));
  };

  useEffect(async () => {
    await axios
      .get(`${baseUrl}/location/countries`)
      .then((thisData) => setCountries(thisData.data));

    await axios
      .get(`${baseUrl}/location/states/${selectedCountrie}`)
      .then((thisData) => setStates(thisData.data));

    await axios
      .get(`${baseUrl}/location/cities/${selectedState}`)
      .then((thisData) => setCities(thisData.data));
  }, []);

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };
  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };
  const handleUpdateResale = async (
    idIxc,
    resaleName,
    resaleCidade,
    resaleStatus,
    resaleAdress,
    resaleAdressNumber,
    resaleEmail,
    resaleNeighborhood,
    resaleCnpjCpf,
    resaleTelefone
  ) => {
    setIsLoading(true);
    await axios
      .patch(`${baseUrl}/resale/${idIxc}`, {
        name: resaleName,
        cityId: resaleCidade,
        status: resaleStatus,
        address: resaleAdress,
        address_number: Number(resaleAdressNumber),
        cnpj_cpf: resaleCnpjCpf,
        email: resaleEmail,
        neighborhood: resaleNeighborhood,
        telefone_celular: resaleTelefone,
      })
      .then((thisData) => {
        successNotify(thisData.data.message);
        setTimeout(() => handleClose(), 100);
      })
      .catch((error) => {
        if(error.status === 401) {
          errorNotify(error.response.data.message[0]);
        }
      });
    setIsLoading(false);
    dispatch(changeUpdate(!testUpdate));
  };
  return (
    <>
      <MDButton
        onClick={() => {
          handleOpen();
        }}
        // variant="text"
        className="openModalBtn"
        color="info"
        size="small"
        sx={{ gap: '16px' }}
      >
        <Pencil weight="fill" size={18} />
        Editar
      </MDButton>
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      >
        <form action="" className="form">
          <SnackbarProvider
            iconVariant={{
              success: (
                <CheckCircle
                  size={16}
                  color="#ffffff"
                  weight="fill"
                  style={{ marginRight: '10px' }}
                />
              ),
              error: (
                <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
              ),
            }}
            dense
            autoHideDuration={1500}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
          <div className="formHeader">
            <div className="formLogo">
              <Buildings size={28} color="#ffffff" />
            </div>
          </div>
          <h2 className="modalTitle">Revenda</h2>
          <div action="test" className="resaleFormGrid">
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}
              className="formFields"
            >
              <label htmlFor="nome">Nome:</label>
              <input
                type="text"
                id="nome"
                className="inputField"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setChanged(true);
                }}
              />
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}
              className="formFields"
            >
              <label htmlFor="endereco">Endereço: </label>
              <input
                type="text"
                value={address}
                className="inputField"
                id="endereco"
                onChange={(e) => {
                  setChanged(true);
                  setAdress(e.target.value);
                }}
              />
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}
              className="formFields"
            >
              <label htmlFor="bairro">Bairro:</label>
              <input
                type="text"
                id="bairro"
                value={neighborhood}
                className="inputField"
                onChange={(e) => {
                  setNeighborhood(e.target.value);
                  setChanged(true);
                }}
              />
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}
              className="formFields"
            >
              <label htmlFor="numero">Numero:</label>
              <input
                type="text"
                value={addressNumber}
                className="inputField"
                id="numero"
                onChange={(e) => {
                  setAdressNumber(e.target.value);
                  setChanged(true);
                }}
              />
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}
              className="formFields"
            >
              <label htmlFor="email">Email: </label>
              <input
                type="text"
                value={email}
                className="inputField"
                id="email"
                onChange={(e) => {
                  setChanged(true);
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}
              className="formFields"
            >
              <label htmlFor="telefone">Telefone:</label>
              <input
                type="text"
                value={telefoneCelular}
                className="inputField"
                id="telefone"
                onChange={(e) => {
                  handlePhoneChange(e);
                  setChanged(true);
                }}
              />
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}
              className="formFields"
            >
              <label htmlFor="cpfcnpj">Cpf/cnpj:</label>
              <input
                type="text"
                value={cnpjCpf}
                className="inputField"
                id="cpfcnpj"
                onChange={(e) => {
                  handleInputChange(e);
                  setChanged(true);
                }}
              />
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}
              className="formFields"
            >
              <label>País:</label>
              <div className="select">
                <select
                  id="standard-select"
                  value={selectedCountrie}
                  className="inputField"
                  placeholder="seleciona uma opção"
                  onChange={(e) => {
                    findState(e.target.value);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <option disabled selected>
                    Selecione uma opção
                  </option>
                  {countries.map((countri) => (
                    <option key={countri.id} value={countri.id}>
                      {countri.name}
                    </option>
                  ))}
                </select>
                <span style={{ fontSize: '12px', color: '#f01202' }} />
              </div>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}
              className="formFields"
            >
              <label htmlFor="nome">Estado:</label>
              <div className="select">
                <select
                  id="standard-select"
                  value={selectedState}
                  className="inputField"
                  placeholder="seleciona uma opção"
                  onChange={(e) => findCities(e.target.value)}
                >
                  <option disabled selected>
                    Selecione um país primeiro
                  </option>
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
                <span style={{ fontSize: '12px', color: '#f01202' }} />
              </div>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}
              className="formFields"
            >
              <label htmlFor="nome">Cidade:</label>
              <div className="select">
                <select
                  id="standard-select"
                  value={selectedCity}
                  className="inputField"
                  placeholder="seleciona uma opção"
                  onChange={(e) => setSelectedCity(e.target.value)}
                >
                  <option disabled selected>
                    Selecione um país primeiro
                  </option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div />
          </div>
          <div className="resaleButtonsCreateResale">
            <MDButton color="error" variant="contained" onClick={() => handleClose()}>
              Cancelar
            </MDButton>
            <MDButton
              type="button"
              className="customerCreateBtn"
              style={{ paddingRight: '26px', paddingLeft: '26px' }}
              color="success"
              onClick={() => {
                handleUpdateResale(
                  data.id_ixc,
                  name,
                  cidade,
                  data.status,
                  address,
                  addressNumber,
                  email,
                  neighborhood,
                  cnpjCpf,
                  telefoneCelular
                );
              }}
            >
              {isLoading === true ? (
                <CircleNotch size={18} weight="bold" className="isLoading" />
              ) : (
                'salvar'
              )}
            </MDButton>
          </div>
        </form>
      </Modal>
    </>
  );
}

// onClick={() => handleClose()}
