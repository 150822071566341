/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components

// Material Dashboard 2 React base styles

// Images
import { X } from 'phosphor-react';
import { Link } from 'react-router-dom';
import backgroundImage from '../../../../assets/images/bg-profile.jpeg';
import backImg from '../../../../assets/images/BackTubaPlay.png';
import breakpoints from '../../../../assets/theme-dark/base/breakpoints';
import MDAvatar from '../../../../components/MDAvatar';
import MDTypography from '../../../../components/MDTypography';
import MDBox from '../../../../components/MDBox';

function Header({ children, name }) {
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="8.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient } }) =>
            `${linearGradient(rgba('#FA7209', 1), rgba('#FA8C01', 1))}, url()`,
          backgroundSize: 'cover',
          backgroundPosition: '50%',
          overflow: 'hidden',
        }}
      />
      <Card
        sx={{
          position: 'relative',
          mt: -8,
          mx: 3,
          py: 5,
          px: 2,
        }}
      >
        <Grid
          container
          spacing={3}
          alignItems="center"
          display="flex"
          justifyContent="space-between"
        >
          <Grid item display="flex" alignItems="center">
            <MDAvatar alt="profile-image" size="xl" shadow="sm" />
            <MDBox height="100%" mt={0.5} lineHeight={1} ml={4}>
              <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                {name.toUpperCase()}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item>
            <Link to="/clientes" style={{ color: '#fff' }}>
              <X size={28} color="#FFF" />
            </Link>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: '',
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
