/* eslint-disable no-unused-vars */
/* eslint-disable */
import { Card, CircularProgress, Grid } from '@mui/material';
// import CustomerTableByResale from 'components/CustomerTableByResale';
import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { CheckCircle, XCircle } from 'phosphor-react';
import MDButton from '../../components/MDButton';
import PaymentEvent from './components/PaymentEvent';
import { baseUrl } from '../../utils/base_url';
import DeleteProgrammerGroupChannelModal from '../../components/DeleteProgrammerGroupChannelModal';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';

import './styles.css';
import PaymentTable from './components/PaymentTable';

export default function Payment() {
  const role = useSelector((state) => state.auth.user.role);
  const [update, setupdate] = useReducer((x) => x + 1, 0);
  const [loading, setloading] = useState(false);
  const [search, setSearch] = useState('');
  const [resalePaymentList, setResalePaymentList] = useState([]);
  const [resalePaymentListFiltred, setResalePaymentListFiltred] = useState([]);
  const [isFiltred, setIsFiltred] = useState(false);
  const [filtredByStatus, setFiltredByStatus] = useState(false);

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  function handlePaymentEventChange(data) {
    if (data.success === true) {
      successNotify(data.message);
      setTimeout(() => {
        setupdate();
      }, 1500);
    } else {
      errorNotify(data.message);
    }
  }

  function handleSearchEvent(e) {
    let filter = [];
    if (e === 'Enter') {
      filter = resalePaymentList?.filter((item) => {
        return item.name.toLowerCase().trim().includes(search.toLowerCase().trim());
      });
      setFiltredByStatus(false);
      setIsFiltred(true);
    }

    const uniqueArray = filter?.filter(function (elem, pos, self) {
      return self.indexOf(elem) == pos;
    });

    if (uniqueArray.length > 0) {
      setResalePaymentListFiltred(uniqueArray);
    } else {
      setIsFiltred(false);
      setFiltredByStatus(false);
    }
  }

  useEffect(() => {
    setloading(true);
    axios.get(`${baseUrl}/resale-payment`).then((res) => {
      setResalePaymentList(res.data.payload);
      setloading(false);
    });
  }, [update]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <MDBox mt={6} mb={3}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={1}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="info"
              display={{ sm: 'flex' }}
              alignItems={{ sm: 'center' }}
              justifyContent={{ sm: 'space-between' }}
            >
              <MDTypography variant="h6" color="white">
                {role === 'admin' ? 'Pagamentos' : 'Recebimentos'}
              </MDTypography>
              <div className="select" style={{ width: 'auto', marginRight: '30px' }}>
                <input
                  id="myInput"
                  className="myInput"
                  placeholder={role === 'admin' ? 'Pagamentos...' : 'Recebimentos...'}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={(e) => handleSearchEvent(e.key)}
                />
              </div>
            </MDBox>
            <MDBox pt={3}>
              {loading ? (
                <div className="loading">
                  <CircularProgress color="warning" size={60} />
                </div>
              ) : isFiltred ? (
                <PaymentTable
                  resale={resalePaymentListFiltred}
                  setupdate={setupdate}
                  handlePaymentEventChange={() => handlePaymentEventChange()}
                  filtredByStatus={filtredByStatus}
                  search={search}
                />
              ) : (
                <PaymentTable
                  resale={resalePaymentList}
                  setupdate={setupdate}
                  handlePaymentEventChange={() => handlePaymentEventChange()}
                  filtredByStatus={filtredByStatus}
                />
              )}
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
