/* eslint-disable no-unused-vars */

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './context/auth';
import App from './App';

import { MaterialUIControllerProvider } from './context';
import { store } from './store';
// Material Dashboard 2 React Context Provider

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Provider store={store}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
