import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import ordersSlice from './redux/ordersSlice';
import dashboardSlice from './redux/dashboardSlice';
import resaleSlice from './redux/resaleSlice';
import { tariffService } from './features/tariff/tariffService';

import tariffFormReducer from './features/tariff/tariffFormSlice';
import modalReducer from './features/common/modalSlice';
import userReducer from './redux/userSlice';
import authReducer from './features/authentication/authSlice';

export const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    auth: authReducer,
    user: userReducer,
    modal: modalReducer,
    tariffForm: tariffFormReducer,
    [tariffService.reducerPath]: tariffService.reducer,
    resale: resaleSlice,
    orders: ordersSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(tariffService.middleware),
});

setupListeners(store.dispatch);
