/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Icon from '@mui/material/Icon';
// eslint-disable-next-line import/no-unresolved
import Overview from 'layouts/profile';
import {
  Analytics,
  ContactPage,
  NoteAdd,
  NoteAddOutlined,
  RequestQuote,
  Shop,
  Shop2,
  Tv,
} from '@mui/icons-material';

import {
  Television,
  GearSix,
  Monitor,
  MonitorPlay,
  Coins,
  UserList,
  Archive,
} from 'phosphor-react';
import VideoSettingsSharpIcon from '@mui/icons-material/VideoSettingsSharp';
import ForgotPassword from './layouts/ForgotPassword/index.jsx';
import RelatoriesClients from './layouts/RelatoriesClients/index.jsx';
import RelatoriesFinancial from './layouts/RelatoriesFinancial/index.jsx';
import Payment from './layouts/Payment/index.jsx';
import ChannelGroup from './layouts/ChannelGroup/index.jsx';
import EditResale from './layouts/EditResale/index.jsx';
import Channels from './layouts/Channels/index.jsx';
import CreateResaleDocs from './layouts/CreateResaleDocs/index.jsx';
import CreateResale from './layouts/CreateResale/index.jsx';
import CustomersPageByResale from './layouts/CustomersPageByResale/index.jsx';
import CreateCustomer from './layouts/CreateCustomer/index.jsx';
import Logins from './layouts/logins/index.jsx';
import ResalePage from './layouts/resale/index.jsx';
import CustomersPage from './layouts/customers';
import UsersPage from './layouts/users/index.jsx';
import SignIn from './layouts/authentication/sign-in';
import Parameters from './layouts/Parameters/index.jsx';

// @mui icons
import Dashboard from './layouts/dashboard/index.jsx';
import TariffPage from './layouts/tariff/index.jsx';
import Programmer from './layouts/Programmer/index.jsx';
import TableHistoricPayment from './layouts/Payment/components/TableHistoricPayment/index.jsx';
import TableWithPaymentList from './layouts/Payment/components/TableWithPaymentList/index.jsx';
// import Channels from './layouts/Channels/index.jsx';

export const commonUserRoutes = [
  {
    type: 'title',
    title: 'Menu',
    key: 'titleMenu',
  },
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/dashboard',
    component: <Dashboard />,
  },
  {
    type: 'collapse',
    name: 'Clientes',
    key: 'clientes',
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: '/clientes',
    component: <CustomersPage />,
  },
  {
    type: 'collapse',
    name: 'Usuarios',
    key: 'usuario',
    icon: <Icon fontSize="small">person</Icon>,
    route: '/usuarios',
    component: <UsersPage />,
  },
  {
    key: 'cliente',
    route: '/cliente',
    component: <Overview />,
  },
  {
    key: 'logins',
    route: '/logins',
    component: <Logins />,
  },
  {
    key: 'createCustomer',
    route: '/createCustomer',
    component: <CreateCustomer />,
  },
  {
    type: 'collapse',
    name: 'Planos',
    key: 'planos',
    icon: <Icon fontSize="small">calculate</Icon>,
    route: '/planos',
    component: <TariffPage />,
  },
];

export const adminRoutes = [
  {
    type: 'divider',
    title: 'Menu',
    name: 'user',
    key: 'divider',
  },
  {
    type: 'title',
    title: 'Menu',
    name: 'user',
    key: 'titleMenu',
  },
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/dashboard',
    component: <Dashboard />,
  },

  {
    type: 'collapse',
    name: 'Clientes',
    key: 'clientes',
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: '/clientes',
    component: <CustomersPage />,
  },
  {
    type: 'collapse',
    name: 'Usuarios',
    key: 'usuarios',
    icon: <Icon fontSize="small">person</Icon>,
    route: '/usuarios',
    component: <UsersPage />,
  },
  {
    type: 'divider',
    name: 'Usuarios',
    key: 'dividerUsuario',
    icon: <Icon fontSize="small">person</Icon>,
    route: '/usuarios',
    component: <UsersPage />,
  },
  {
    type: 'title',
    title: 'Administração',
    name: 'title',
    key: 'admininstracao',
  },
  {
    type: 'collapse',
    name: 'Revendas',
    key: 'revendas',
    icon: <Icon fontSize="small">business</Icon>,
    route: `/revendas`,
    component: <ResalePage />,
  },
  {
    type: 'collapse',
    name: 'Planos',
    key: 'planos',
    icon: <Icon fontSize="small">calculate</Icon>,
    route: '/planos',
    component: <TariffPage />,
  },
  {
    type: 'collapse',
    name: 'Pagamentos',
    key: 'pagamentos',
    icon: <Coins color="#fff" />,
    route: '/pagamentos',
    component: <Payment />,
  },
  {
    type: 'expansive',
    name: 'Config programadoras',
    key: 'programadorasExpansive',
    icon: <VideoSettingsSharpIcon color="#ffffff" />,
    expansive: [
      {
        name: 'Programadoras',
        key: 'programadoras',
        icon: <Television color="#ffffff" />,
        route: '/programadoras',
        component: <Programmer />,
      },
      {
        name: 'Grupos de canais',
        key: 'programadora-grupo',
        icon: <Monitor color="#fff" />,
        route: '/programadora-grupo',
        component: <ChannelGroup />,
      },
      {
        name: 'Canais',
        key: 'programadora-canais',
        icon: <MonitorPlay color="#fff" />,
        route: '/programadora-canais',
        component: <Channels />,
      },
    ],
  },

  {
    type: 'divider',
    title: 'Relatórios',
    name: 'title',
    key: 'dividerRelatorios',
    component: <h1>teste</h1>,
  },
  {
    type: 'title',
    title: 'Relatórios',
    name: 'title',
    key: 'RelatoriosTitle',
  },
  /*  {
    type: 'collapse',
    name: 'Comissão revendas',
    key: 'dividerComissao',
    icon: <NoteAdd />,
    route: '/relatorios',
    component: <Relatories />,
  }, */
  /*  {
    type: 'collapse',
    name: 'Relatório de clientes',
    key: 'relatoriosClientes',
    icon: <ContactPage />,
    route: '/relatorios',
    component: <Relatories />,
  }, */
  {
    type: 'expansive',
    name: 'Relatórios',
    key: 'relatoriosAcord',
    icon: <Archive color="#fff" />,
    expansive: [
      {
        type: 'collapse',
        name: 'Relatório financeiro',
        key: 'relatorio-financeiro',
        icon: <RequestQuote color="#fff" />,
        route: '/relatorios-financeiro',
        component: <RelatoriesFinancial />,
      },
      {
        type: 'collapse',
        name: 'Relatório cliente',
        key: 'relatorio-cliente',
        icon: <UserList color="#fff" />,
        route: '/relatorios-cliente',
        component: <RelatoriesClients />,
      },
      {
        type: 'collapse',
        name: 'Parâmetros',
        key: 'parametros',
        icon: <GearSix size={20} color="#ffffff" />,
        route: '/parametros',
        component: <Parameters />,
      },
    ],
  },
  {
    key: 'clientes',
    route: '/cliente',
    component: <Overview />,
  },
  {
    key: 'logins',
    route: '/logins',
    component: <Logins />,
  },
  {
    key: 'createCustomer',
    route: '/createCustomer',
    component: <CreateCustomer />,
  },
  {
    key: 'clientesResale',
    route: '/clientes/resale',
    component: <CustomersPageByResale />,
  },
  {
    key: 'createResale',
    route: '/createResale',
    component: <CreateResale />,
  },
  {
    key: 'createResaleDocs',
    route: '/createResale/docs',
    component: <CreateResaleDocs />,
  },
  {
    key: 'editResale',
    route: '/resale/editar',
    component: <EditResale />,
  },
  {
    key: 'resalePayments',
    route: '/resale/pagamentos',
    component: <TableWithPaymentList />,
  },
  {
    key: 'resalePayments',
    route: '/resale/pagamentos/historico',
    component: <TableHistoricPayment />,
  },
];

export const operatorTubaronRoutes = [
  {
    type: 'divider',
    title: 'Menu',
    name: 'user',
    key: 'divider',
  },
  {
    type: 'title',
    title: 'Menu',
    name: 'user',
    key: 'titleMenu',
  },
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/dashboard',
    component: <Dashboard />,
  },

  {
    type: 'collapse',
    name: 'Clientes',
    key: 'clientes',
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: '/clientes',
    component: <CustomersPage />,
  },
  {
    type: 'collapse',
    name: 'Usuarios',
    key: 'usuarios',
    icon: <Icon fontSize="small">person</Icon>,
    route: '/usuarios',
    component: <UsersPage />,
  },
  {
    type: 'divider',
    name: 'Usuarios',
    key: 'dividerUsuario',
    icon: <Icon fontSize="small">person</Icon>,
    route: '/usuarios',
    component: <UsersPage />,
  },
  {
    type: 'title',
    title: 'Administração',
    name: 'title',
    key: 'admininstracao',
  },
  {
    type: 'collapse',
    name: 'Revendas',
    key: 'revendas',
    icon: <Icon fontSize="small">business</Icon>,
    route: `/revendas`,
    component: <ResalePage />,
  },
  {
    type: 'collapse',
    name: 'Planos',
    key: 'planos',
    icon: <Icon fontSize="small">calculate</Icon>,
    route: '/planos',
    component: <TariffPage />,
  },
  /*  {
    type: 'collapse',
    name: 'Comissão revendas',
    key: 'dividerComissao',
    icon: <NoteAdd />,
    route: '/relatorios',
    component: <Relatories />,
  }, */
  /*  {
    type: 'collapse',
    name: 'Relatório de clientes',
    key: 'relatoriosClientes',
    icon: <ContactPage />,
    route: '/relatorios',
    component: <Relatories />,
  }, */
  {
    key: 'clientes',
    route: '/cliente',
    component: <Overview />,
  },
  {
    key: 'logins',
    route: '/logins',
    component: <Logins />,
  },
  {
    key: 'createCustomer',
    route: '/createCustomer',
    component: <CreateCustomer />,
  },
  {
    key: 'clientesResale',
    route: '/clientes/resale',
    component: <CustomersPageByResale />,
  },
  {
    key: 'createResale',
    route: '/createResale',
    component: <CreateResale />,
  },
  {
    key: 'createResaleDocs',
    route: '/createResale/docs',
    component: <CreateResaleDocs />,
  },
  {
    key: 'editResale',
    route: '/resale/editar',
    component: <EditResale />,
  },
];

export const signRoutes = [
  {
    key: 'sign-in',
    route: '/sign-in',
    component: <SignIn />,
  },
  {
    key: 'forgotPassword',
    route: '/forgot-password/:id',
    component: <ForgotPassword />,
  },
];

export const adminResaleRoutes = [
  {
    type: 'divider',
    title: 'Menu',
    name: 'user',
    key: 'divider',
  },
  {
    type: 'title',
    title: 'Menu',
    name: 'user',
    key: 'titleMenu',
  },
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/dashboard',
    component: <Dashboard />,
  },
  {
    type: 'collapse',
    name: 'Clientes',
    key: 'clientes',
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: '/clientes',
    component: <CustomersPage />,
  },
  {
    type: 'collapse',
    name: 'Usuarios',
    key: 'usuarios',
    icon: <Icon fontSize="small">person</Icon>,
    route: '/usuarios',
    component: <UsersPage />,
  },

  {
    key: 'clientes',
    route: '/cliente',
    component: <Overview />,
  },
  {
    key: 'logins',
    route: '/logins',
    component: <Logins />,
  },
  {
    key: 'createCustomer',
    route: '/createCustomer',
    component: <CreateCustomer />,
  },
  {
    name: 'Clientes',
    key: 'clientesResale',
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: '/clientes/resale',
    component: <CustomersPageByResale />,
  },
  {
    type: 'collapse',
    name: 'Planos',
    key: 'planos',
    icon: <Icon fontSize="small">calculate</Icon>,
    route: '/planos',
    component: <TariffPage />,
  },
  {
    type: 'collapse',
    name: 'Recebimentos',
    key: 'payment',
    icon: <Coins color="#fff" />,
    route: '/resale/pagamentos',
    component: <TableWithPaymentList />,
  },
  {
    key: 'resalePayments',
    route: '/resale/pagamentos/historico',
    component: <TableHistoricPayment />,
  },
  {
    type: 'divider',
    title: 'Menu',
    name: 'user',
    key: 'dividerRelatories',
  },
  {
    type: 'title',
    title: 'Relatórios',
    name: 'user',
    key: 'titleMenuRelatories',
  },
  {
    type: 'collapse',
    name: 'Relatório financeiro',
    key: 'relatorios',
    icon: <RequestQuote color="#fff" />,
    route: '/relatorios',
    component: <RelatoriesFinancial />,
  },
  {
    type: 'collapse',
    name: 'Relatório cliente',
    key: 'relatorio-cliente',
    icon: <UserList color="#fff" />,
    route: '/relatorios-cliente',
    component: <RelatoriesClients />,
  },
];

export const CHTECHROUTES = [
  {
    type: 'divider',
    title: 'Menu',
    name: 'user',
    key: 'divider',
  },
  {
    type: 'title',
    title: 'Menu',
    name: 'user',
    key: 'titleMenu',
  },
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/dashboard',
    component: <Dashboard />,
  },
  {
    type: 'collapse',
    name: 'Clientes',
    key: 'clientes',
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: '/clientes',
    component: <CustomersPage />,
  },
  {
    type: 'collapse',
    name: 'Usuarios',
    key: 'usuarios',
    icon: <Icon fontSize="small">person</Icon>,
    route: '/usuarios',
    component: <UsersPage />,
  },

  {
    key: 'clientes',
    route: '/cliente',
    component: <Overview />,
  },
  {
    key: 'logins',
    route: '/logins',
    component: <Logins />,
  },
  {
    key: 'createCustomer',
    route: '/createCustomer',
    component: <CreateCustomer />,
  },
  {
    name: 'Clientes',
    key: 'clientesResale',
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: '/clientes/resale',
    component: <CustomersPageByResale />,
  },
  {
    type: 'collapse',
    name: 'Planos',
    key: 'planos',
    icon: <Icon fontSize="small">calculate</Icon>,
    route: '/planos',
    component: <TariffPage />,
  },
];
