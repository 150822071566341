/* eslint-disable react/self-closing-comp */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import * as Tabs from '@radix-ui/react-tabs';

// Material Dashboard 2 React components

// Material Dashboard 2 React examples
import { Card, Tooltip } from '@mui/material';
// import { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Check, CheckCircle, Circle, CircleNotch, Question, XCircle } from 'phosphor-react';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { changeUser } from 'redux/userSlice';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { changeUpdate } from '../../redux/resaleSlice';
import MDTypography from '../../components/MDTypography';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import { NotificationResale } from './components/NotificationResale.jsx';
import Footer from '../../examples/Footer';
import MDBox from '../../components/MDBox';

import './style.css';
import { baseUrl } from '../../utils/base_url';
import MDButton from '../../components/MDButton';
import SearchInput from '../../components/ChannelGroupModal/components/SearchInput.jsx';

const schema = yup.object({
  nome: yup.string().required('Campo obrigatório!').min(5, 'Nome deve ter no minimo 5 caracteres'),
  endereco: yup.string().required('Campo obrigatório!'),
  bairro: yup.string().required('Campo obrigatório!'),
  numero: yup.string().required('Campo obrigatório!'),
  complemento: yup.string(),
  email: yup.string().required('Campo obrigatório!').email('Necessario inserir um E-mail válido'),
  telefone: yup.string().required('Campo obrigatório!'),
  whatsapp: yup.string().required('Campo obrigatório!'),
  cep: yup.string().required('Campo obrigatório!'),
  cnpj_cpf: yup.string().required('Campo obrigatório!'),
  pais: yup.string(),
  paisCobranca: yup.string(),
  ieRg: yup.string(),
  contaBancaria: yup.string(),
  agencia: yup.string(),
  banco: yup.string(),
  tipoConta: yup.string(),
  pix: yup.string(),
});

export default function CreateResale() {
  const testUpdate = useSelector((state) => state.resale.updateResale);
  const role = useSelector((state) => state.auth.user.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [pais, setPais] = useState('');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [phone, setPhone] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [resaleCep, setResaleCep] = useState('');
  const [tipoPessoa, setTipoPessoa] = useState('F');

  // documentos
  const [cnpjCpf, setCnpjCpf] = useState('');
  const [ieRg, setIeRg] = useState('');

  // Dados Bancarios
  const [contaBancaria, setContaBancaria] = useState('');
  const [agencia, setAgencia] = useState('');
  const [banco, setBanco] = useState({});
  const [contaDv, setContaDv] = useState('');
  const [agenciaDv, setAgenciaDv] = useState('');
  const [filterBank, setFilterBank] = useState([]);
  const [filterBankList, setFilterBankList] = useState([]);
  const [tipoConta, setTipoConta] = useState('');
  const [pix, setPix] = useState('');
  const [bankList, setBankList] = useState([]);

  const isFiltred = filterBankList.length > 0;

  // usuario

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const formatCpfCnpj = (inputValue) => {
    let formattedValue = inputValue.replace(/\D/g, ''); // remove todos os caracteres que não são números

    if (formattedValue.length <= 11) {
      // CPF
      formattedValue = formattedValue.replace(/(\d{3})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/(\d{3})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      // CNPJ
      formattedValue = formattedValue.replace(/^(\d{2})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
      formattedValue = formattedValue.replace(/\.(\d{3})(\d)/, '.$1/$2');
      formattedValue = formattedValue.replace(/(\d{4})(\d)/, '$1-$2');
    }

    return formattedValue;
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatCpfCnpj(inputValue);
    setCnpjCpf(formattedValue);
  };

  const handlePhoneChange = (event) => {
    let formattedPhone = event.target.value.replace(/\D/g, '');
    formattedPhone = formattedPhone.replace(/^(\d{2})(\d)/g, '($1) $2');
    formattedPhone = formattedPhone.replace(/(\d)(\d{4})$/, '$1-$2');
    setPhone(formattedPhone);
  };
  const handleCellPhoneChange = (event) => {
    let formattedPhone = event.target.value.replace(/\D/g, '');
    formattedPhone = formattedPhone.replace(/^(\d{2})(\d)/g, '($1) $2');
    formattedPhone = formattedPhone.replace(/(\d)(\d{4})$/, '$1-$2');
    setWhatsapp(formattedPhone);
  };

  const handleCepChange = (event) => {
    let formattedCep = event.target.value.replace(/\D/g, '');
    formattedCep = formattedCep.replace(/^(\d{5})(\d)/g, '$1-$2');
    setResaleCep(formattedCep);
  };

  const handleSelecionarTipoPessoa = (event) => {
    const valor = event.target.value; // Obtém o valor do checkbox selecionado
    setTipoPessoa(valor); // Atualiza o estado com o valor selecionado
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const resaleEmail = watch('emailnome');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    await axios.get(`${baseUrl}/location/countries`).then((data) => setCountries(data.data));
  }, []);

  const handleNavigationBack = () => {
    navigate('/revendas');
  };

  const findState = async (id) => {
    setPais(id);
    setStates([]);
    await axios.get(`${baseUrl}/location/states/${id}`).then((data) => {
      setStates(data.data);
    });
  };

  const findCities = async (id) => {
    await axios.get(`${baseUrl}/location/cities/${id}`).then((data) => {
      setCities(data.data);
    });
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };
  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  function handleChangeBank(value) {
    const bank = bankList.filter((bankk) => bankk.ispb === value);
    setBanco(bank[0]);
  }

  useEffect(async () => {
    await axios
      .get('https://brasilapi.com.br/api/banks/v1', {
        mode: 'no-cors',
        auth: '',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
      .then((res) => {
        setBankList(res.data);
        setBanco(res.data[0]);
      });
  }, []);

  useEffect(() => {
    if (filterBank.length > 0) {
      const fill = bankList.filter((item) =>
        item.fullName?.toLowerCase().includes(filterBank?.toLowerCase())
      );
      setFilterBankList(fill);
    }
  }, [filterBank]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    if (data) {
      await axios
        .post(`${baseUrl}/resale`, {
          name: data.nome,
          cityId: data.cidade,
          address: data.endereco,
          address_number: Number(data.numero),
          email: data.email,
          neighborhood: data.bairro,
          telefone_celular: data.telefone,
          cnpj_cpf: data.cnpj_cpf,
          tipo_pessoa: data.tipo_pessoa,
          cep: data.cep,
          cep_cob: data.cepCobranca,
          endereco_cob: data.enderecoCobranca,
          numero_cob: data.numeroCobranca,
          bairro_cob: data.bairroCobranca,
          cidade_cob: data.cidadeCobranca,
          complemento_cob: data.complementoCobranca,
          whatsapp: data.whatsapp,
          ie_rg: data.ieRg,
          dados_bancarios: {
            banco,
            tipo_conta: tipoConta,
            chave_pix: pix,
            agencia: {
              cod_conta: contaBancaria,
              agencia_dv: agenciaDv,
              cod_agencia: agencia,
              numero_conta_dv: contaDv,
            },
          },
          login: {
            full_name: data.nome,
            email: login,
            password,
          },
        })
        .then((thisData) => {
          if (thisData.data.status === 400) {
            errorNotify(thisData.data.message);
            setIsLoading(false);
          } else {
            successNotify(thisData.data.message);
            setIsLoading(false);
            setModalOpen(true);
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            error.response.data.message[0] ===
            'address_number must be a number conforming to the specified constraints'
          ) {
            errorNotify('Numero Invalido!');
          } else {
            errorNotify(error.response.data.message[0]);
          }
          setIsLoading(false);
        });
      dispatch(changeUpdate(!testUpdate));
    }
  };

  return (
    <>
      <div>
        <SnackbarProvider
          iconVariant={{
            success: (
              <CheckCircle
                size={16}
                color="#ffffff"
                weight="fill"
                style={{ marginRight: '10px' }}
              />
            ),
            error: (
              <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
            ),
          }}
          dense
          autoHideDuration={1500}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
      </div>
      <DashboardLayout>
        {modalOpen ? <NotificationResale resale={resaleEmail} modalOpen /> : null}
        <MDBox mt={6} mb={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                mb={4}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
                display={{ sm: 'flex' }}
                alignItems={{ sm: 'center' }}
                justifyContent={{ sm: 'space-between' }}
              >
                <MDTypography variant="h6" color="white">
                  Revenda
                </MDTypography>
              </MDBox>
              <MDBox pt={1} pb={2} px={2}>
                <Tabs.Root className="TabsRoot" defaultValue="tab1">
                  <form onSubmit={handleSubmit(onSubmit)} className="">
                    <Tabs.List className="TabsList" aria-label="Manage your account">
                      <Tabs.Trigger className="TabsTrigger" value="tab1">
                        Dados
                      </Tabs.Trigger>
                      <Tabs.Trigger className="TabsTrigger" value="tab3">
                        Dados Bancarios
                      </Tabs.Trigger>
                      <Tabs.Trigger className="TabsTrigger" value="tab2">
                        Usuário
                      </Tabs.Trigger>
                    </Tabs.List>
                    <Tabs.Content className="TabsContent" value="tab1">
                      <div action="test" className="resaleFormGrid">
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="nome">Razão: </label>
                            <span className="span">
                              {errors.nome !== undefined ? errors.nome.message : ''}
                            </span>
                          </div>
                          <input
                            className={
                              errors.nome !== undefined ? 'inputField error' : 'inputField'
                            }
                            type="text"
                            id="nome"
                            defaultValue=""
                            {...register('nome')}
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="endereco">Endereço: </label>
                            <span className="span">
                              {errors.endereco !== undefined ? errors.endereco.message : ''}
                            </span>
                          </div>
                          <input
                            {...register('endereco')}
                            type="text"
                            defaultValue=""
                            id="endereco"
                            className="inputField"
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="bairro">Bairro:</label>
                            <span className="span">
                              {errors.bairro !== undefined ? errors.bairro.message : ''}
                            </span>
                          </div>
                          <input
                            {...register('bairro')}
                            type="text"
                            id="bairro"
                            defaultValue=""
                            className="inputField"
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="numero">Numero:</label>
                            <span className="span">
                              {errors.numero !== undefined ? errors.numero.message : ''}
                            </span>
                          </div>
                          <input
                            {...register('numero')}
                            type="tel"
                            defaultValue=""
                            id="numero"
                            className="inputField"
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="email">Email: </label>
                            <span className="span">
                              {errors.email !== undefined ? errors.email.message : ''}
                            </span>
                          </div>
                          <input
                            {...register('email')}
                            type="email"
                            defaultValue=""
                            id="email"
                            className="inputField"
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="telefone">Telefone:</label>
                            <span className="span">
                              {errors.telefone !== undefined ? errors.telefone.message : ''}
                            </span>
                          </div>
                          <input
                            className="inputField"
                            id="telefone"
                            {...register('telefone')}
                            type="text"
                            placeholder="(00) 0000-0000"
                            value={phone}
                            onChange={handlePhoneChange}
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="telefone">Whatsapp:</label>
                            <span className="span">
                              {errors.telefone !== undefined ? errors.telefone.message : ''}
                            </span>
                          </div>
                          <input
                            className="inputField"
                            id="telefone"
                            {...register('whatsapp')}
                            type="text"
                            placeholder="(00) 0000-0000"
                            value={whatsapp}
                            onChange={handleCellPhoneChange}
                          />
                        </div>

                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="cep">CEP:</label>
                            <span className="span">
                              {errors.cep !== undefined ? errors.cep.message : ''}
                            </span>
                          </div>
                          <input
                            className="inputField"
                            type="text"
                            placeholder="00000-000"
                            id="cep"
                            value={resaleCep}
                            {...register('cep')}
                            onChange={handleCepChange}
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="nome">País:</label>
                            <span className="span">
                              {errors.cidade !== undefined ? errors.cidade.message : ''}
                            </span>
                          </div>
                          <div className="select">
                            <select
                              id="standard-select"
                              placeholder="seleciona uma opção"
                              onChange={(e) => {
                                findState(e.target.value);
                              }}
                              value={pais}
                              style={{ cursor: 'pointer' }}
                              // {...register('país')}
                            >
                              <option disabled value="">
                                Selecione uma opção
                              </option>
                              {countries.map((countri) => (
                                <option key={countri.id} value={countri.id}>
                                  {countri.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="nome">Estado:</label>
                            <span className="span">
                              {errors.cidade !== undefined ? errors.cidade.message : ''}
                            </span>
                          </div>
                          <div className="select">
                            <select
                              id="standard-select"
                              defaultValue=""
                              placeholder="seleciona uma opção"
                              onChange={(e) => findCities(e.target.value)}
                            >
                              <option disabled>Selecione um país primeiro</option>
                              {states.map((state) => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="nome">Cidade:</label>
                            <span className="span">
                              {errors.cidade !== undefined ? errors.cidade.message : ''}
                            </span>
                          </div>
                          <div className="select">
                            <select
                              id="standard-select"
                              defaultValue=""
                              placeholder="seleciona uma opção"
                              {...register('cidade')}
                            >
                              <option disabled>Selecione um país primeiro</option>
                              {cities.map((city) => (
                                <option key={city.id} value={city.id}>
                                  {city.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="cpfcnpj">CPF/CNPJ</label>
                            <span className="span">
                              {errors.cnpj_cpf !== undefined ? errors.cnpj_cpf.message : ''}
                            </span>
                          </div>
                          <input
                            className="inputField"
                            {...register('cnpj_cpf')}
                            type="text"
                            value={cnpjCpf}
                            onChange={handleInputChange}
                            placeholder="Digite seu CPF/CNPJ"
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="cpfcnpj">IE/RG:</label>
                            <span className="span">
                              {errors.cnpj_cpf !== undefined ? errors.cnpj_cpf.message : ''}
                            </span>
                          </div>
                          <input
                            className="inputField"
                            {...register('ieRg')}
                            type="text"
                            value={ieRg}
                            onChange={(e) => setIeRg(e.target.value)}
                            placeholder="IE/RG"
                          />
                        </div>
                        <div />
                      </div>
                    </Tabs.Content>
                    <Tabs.Content className="TabsContent" value="tab3">
                      <div action="test" className="resaleFormGrid">
                        <div
                          style={{ display: 'flex', width: '100%', gap: '0.5rem' }}
                          className="formFields"
                        >
                          <div style={{ width: '60%' }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginLeft: '8px',
                                marginRight: '8px',
                                marginBottom: '5px',
                              }}
                            >
                              <label htmlFor="conta">Conta</label>
                              <span className="span">
                                {errors.contaBancaria !== undefined ? errors.contaBancaria : ''}
                              </span>
                            </div>
                            <input
                              className="inputField"
                              type="text"
                              value={contaBancaria}
                              onChange={(e) => setContaBancaria(e.target.value)}
                              placeholder="Digite sua conta"
                              style={{ width: '100%' }}
                            />
                          </div>
                          <div style={{ width: '40%' }}>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: '8px',
                                marginRight: '8px',
                                marginBottom: '5px',
                                gap: '0.5rem',
                              }}
                            >
                              <label htmlFor="conta">Verificador</label>
                              <Tooltip title="caso houver números após o hifen">
                                <Question size={20} color="#ffffff" weight="fill" />
                              </Tooltip>

                              <span className="span">
                                {errors.contaBancaria !== undefined ? errors.contaBancaria : ''}
                              </span>
                            </div>
                            <input
                              className="inputField"
                              type="text"
                              value={contaDv}
                              onChange={(e) => setContaDv(e.target.value)}
                              placeholder="Digito Verificador"
                              style={{ width: '100%' }}
                            />
                          </div>
                        </div>
                        <div
                          style={{ display: 'flex', width: '100%', gap: '0.5rem' }}
                          className="formFields"
                        >
                          <div style={{ width: '60%' }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginLeft: '8px',
                                marginRight: '8px',
                                marginBottom: '5px',
                              }}
                            >
                              <label htmlFor="conta">Agência</label>
                              <span className="span">
                                {errors.agencia !== undefined ? errors.agencia : ''}
                              </span>
                            </div>
                            <input
                              className="inputField"
                              type="text"
                              value={agencia}
                              onChange={(e) => setAgencia(e.target.value)}
                              placeholder="Digite sua Agência"
                              style={{ width: '100%' }}
                            />
                          </div>
                          <div style={{ width: '40%' }}>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: '8px',
                                marginRight: '8px',
                                marginBottom: '5px',
                                gap: '0.5rem',
                              }}
                            >
                              <label htmlFor="conta">Verificador</label>
                              <Tooltip title="caso houver números após o hifen">
                                <Question size={20} color="#ffffff" weight="fill" />
                              </Tooltip>
                            </div>
                            <input
                              className="inputField"
                              type="text"
                              value={agenciaDv}
                              onChange={(e) => setAgenciaDv(e.target.value)}
                              placeholder="Digito Verificador"
                              style={{ width: '100%' }}
                            />
                          </div>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <label htmlFor="conta">Banco</label>
                              <SearchInput
                                label="Filtro de Bancos"
                                placeholder="bancos..."
                                value={filterBank}
                                onChange={(search) => setFilterBank(search)}
                              />
                            </div>
                            <span className="span">
                              {errors.banco !== undefined ? errors.banco : ''}
                            </span>
                          </div>
                          <select
                            id="banco"
                            value={banco?.ispb}
                            className="inputField"
                            placeholder="seleciona uma opção"
                            onChange={(e) => {
                              handleChangeBank(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Escolha um banco
                            </option>
                            {isFiltred
                              ? filterBankList.map((item, index) => (
                                  <option
                                    key={item.ispb !== '' ? item.ispb : index}
                                    value={item.ispb}
                                  >
                                    {item.name}
                                  </option>
                                ))
                              : bankList.map((item, index) => (
                                  <option
                                    key={item.ispb !== '' ? item.ispb : index}
                                    value={item.ispb}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                          </select>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="conta">Tipo de Conta</label>
                            <span className="span">
                              {errors.tipoConta !== undefined ? errors.tipoConta : ''}
                            </span>
                          </div>
                          <select
                            className="inputField"
                            type="text"
                            value={tipoConta}
                            onChange={(e) => setTipoConta(e.target.value)}
                          >
                            <option>Selecione o tipo de Conta</option>
                            <option value="c">Conta Corrente</option>
                            <option value="p">Conta Poupança</option>
                          </select>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="conta">Chave Pix</label>
                            <span className="span">
                              {errors.pix !== undefined ? errors.pix : ''}
                            </span>
                          </div>
                          <input
                            className="inputField"
                            type="text"
                            value={pix}
                            onChange={(e) => setPix(e.target.value)}
                            placeholder="Digite sua chave pix"
                          />
                        </div>
                      </div>
                    </Tabs.Content>
                    <Tabs.Content className="TabsContent" value="tab2">
                      <div action="test" className="resaleFormGrid">
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="login">Login: </label>
                          </div>
                          <input
                            className={
                              errors.nome !== undefined ? 'inputField error' : 'inputField'
                            }
                            type="text"
                            id="nome"
                            onChange={(e) => setLogin(e.target.value)}
                            defaultValue={login}
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="endereco">Senha: </label>
                          </div>
                          <input
                            type="text"
                            defaultValue={password}
                            onChange={(e) => setPassword(e.target.value)}
                            id="endereco"
                            className="inputField"
                          />
                        </div>
                      </div>
                    </Tabs.Content>
                    <div className="resaleButtonsCreateResale">
                      <MDButton
                        className=""
                        color="error"
                        variant="contained"
                        onClick={() => handleNavigationBack()}
                      >
                        Cancelar
                      </MDButton>
                      <button
                        type="submit"
                        color="success"
                        className="customerCreateBtn"
                        onClick={() => {
                          onSubmit();
                        }}
                        style={{ paddingRight: '26px', paddingLeft: '26px' }}
                      >
                        {isLoading === true ? (
                          <CircleNotch size={18} weight="bold" className="isLoading" />
                        ) : (
                          'Criar'
                        )}
                      </button>
                    </div>
                  </form>
                </Tabs.Root>
              </MDBox>
            </Card>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}
