/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Modal } from '@mui/material';
import axios from 'axios';
import { Buildings, CheckCircle, Pencil, User, XCircle } from 'phosphor-react';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { AuthContext } from '../../context/auth';
import { baseUrl } from '../../utils/base_url';
import { changeUpdate } from '../../redux/resaleSlice';
import MDButton from '../MDButton';

export function EditOperatorModal({ data, title, color, iconVariant, className, onClose, variant }) {
  const storeUpdate = useSelector((state) => state.resale.updateResale);
  const dispatch = useDispatch();

  const [email, setEmail] = useState(data.email);
  const [password, setPassword] = useState('');
  const [name, setName] = useState(data.username === undefined ? data.full_name : data.username);
  const [permission, setPermission] = useState(data.type === undefined ? data.role : data.type);
  const role = useSelector((state) => state.auth.user.role);
  const disabled = role === 'operator' || role === 'operatorTubaron';
  const [open, setOpen] = useState(false);

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };
  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const needLogin = () => {
    enqueueSnackbar('Necessario logar novamente!', {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const user = useSelector((state) => state.auth.user);

  const { clearToken } = useContext(AuthContext);

  function handleLogout() {
    clearToken();
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePermission = (event) => {
    setPermission(event.target.value);
  };

  const handleUpdateOperator = async (
    id,
    operatorType,
    operatorEmail,
    operatorPassword,
    fullName
  ) => {
    await axios
      .put(`${baseUrl}/operators/${operatorEmail}`, {
        type: operatorType,
        email: operatorEmail,
        password: operatorPassword,
        full_name: fullName,
        status: true,
      })
      .then((thisData) => {
        if (operatorEmail !== data.email) {
          successNotify(`Atualizado com sucesso!`);
          setTimeout(() => {
            setOpen(false);
            handleClose();
          }, 1000);
        } else {
          successNotify(`Atualizado com sucesso!`);
          setTimeout(() => {
            setOpen(false);
            handleClose();
          }, 1000);
        }
        if (thisData.data.code === 'P2002') {
          errorNotify('Email ja esta sendo utilizado!');
          setOpen(false);
          handleClose();
        }
      })
      .catch((error) => {
        console.log(error);
        handleClose();
      });
    dispatch(changeUpdate(!storeUpdate));
  };

  return (
    <>
      <MDButton
        onClick={() => {
          handleOpen();
        }}
        variant={variant}
        color={color}
        size="small"
        style={{ display: 'flex', gap: '0.5em' }}
      >
        {iconVariant === 'user' ? <User size={14} /> : null}
        {iconVariant === 'resale' ? <Buildings size={14} /> : null}
        {iconVariant === 'edit' ? <Pencil weight="fill" size={14} /> : null}

        {title}
      </MDButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      >
        <form action="" className="operatorForm">
          <SnackbarProvider
            iconVariant={{
              success: (
                <CheckCircle
                  size={16}
                  color="#ffffff"
                  weight="fill"
                  style={{ marginRight: '10px' }}
                />
              ),
              error: (
                <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
              ),
            }}
            dense
            autoHideDuration={2500}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
          <div className="formHeader">
            <div className="formLogo">
              <Buildings size={28} color="#ffffff" />
            </div>
          </div>
          <h2 className="operatorModalTitle">
            {title} {data.full_name}{' '}
          </h2>
          <div action="test" className="operatorFormGrid">
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="nome">*Nome:</label>
              <input
                type="text"
                id="nome"
                className="inputField"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="email">*Email:</label>
              <input
                type="text"
                id="email"
                className="inputField"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="password">Senha: </label>
              <input
                type="password"
                id="password"
                className="inputField"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {disabled ? null : (
              <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
                <label htmlFor="nome">Permissão: </label>
                <div className="select">
                  <select
                    id="select-permission"
                    value={permission}
                    onChange={(e) => setPermission(e.target.value)}
                  >
                    <option disabled>Selecione uma opção</option>
                    {user.role === 'admin' ? (
                      <>
                        <option value="admin">Admin</option>
                        <option value="operatorTubaron">Tubaron Operador</option>
                      </>
                    ) : (
                      <>
                        <option value="resaleAdmin">
                          Admin
                        </option>
                        <option value="operator">Operador</option>
                      </>
                    )}
                  </select>
                </div>
              </div>
            )}
          </div>
          <div className="buttonsCreateResale">
            <MDButton
              onClick={() => {
                handleClose();
              }}
              className="formBtn"
              color="error"
              variant="contained"
            >
              Cancelar
            </MDButton>
            <MDButton
              onClick={() => {
                handleUpdateOperator(
                  data.email,
                  permission,
                  email,
                  password,
                  name,
                  data.status,
                  data.resaleId
                );
              }}
              className="formBtn"
              color="success"
              variant="contained"
              disabled={email === data.email && name === data.username && password.length === 0}
            >
              Salvar
            </MDButton>
          </div>
        </form>
      </Modal>
    </>
  );
}
