/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable */
// Images
import DataTable from 'examples/Tables/DataTable/index.js';
import DeleteProgrammerGroupChannelModal from '../../../components/DeleteProgrammerGroupChannelModal/index.jsx';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { CheckCircle, XCircle } from 'phosphor-react';
import { useState } from 'react';
import ProgrammerModal from 'components/ProgrammerModal/index.jsx';
import ProgrammerDetailsModal from 'components/ProgrammerDetailsModal/index.jsx';
// import MDBox from '../../../components/MDBox';

export function ProgrammerTable({ programmer, setupdate, handleListProgrammsChange }) {
  const myRows = [];
  const [openModal, setOpenModal] = useState(false);
  const [group, setGroup] = useState([]);
  const [programmerData, setProgrammerData] = useState([]);


  const handleEditProgrammer = (item) => {
    if (item) {
      setOpenModal(true);
    } else {
      setOpenModal(!openModal);
    }
    setProgrammerData(item);
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const successNotify = (message) => {
    console.log(message)
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };
  function handleListProgrammsChange(data) {
    if (data.success) {
      successNotify(data.message);
      setTimeout(() => {
        setupdate();
      }, 1000);
    } else {
      errorNotify(data.message);
    }
  }

  function renderMyRows(item) {
    return myRows.push({
      name: (
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
            {item.name}
          </MDTypography>
        </MDBox>
      ),
      logo: (
        <MDBox>
          <MDTypography>
            <img src={item.url} alt="" className="thumbChannel" />
          </MDTypography>
        </MDBox>
      ),
      action: (
        <MDBox width="100%" display="flex" alignItems="flex-end" flexDirection="column" border>
          <MDBox display="flex" alignItems="right" gap={1}>
            <ProgrammerModal
              data={item}
              title={`Editar ${item.name}`}
              modalOpen={openModal}
              handleListProgrammsChange={handleListProgrammsChange}
              handleEditProgrammer={handleEditProgrammer}
              iconVariant={'edit'}
            />
            <DeleteProgrammerGroupChannelModal
              data={item}
              programadora={true}
              handleListProgrammsChange={handleListProgrammsChange}
            />
            <ProgrammerDetailsModal
              key={item.id}
              size="small"
              data={item}
              iconVariant="see"
              title={`Detalhes Programadora ${item.name}`}
              handleListProgrammsChange={handleListProgrammsChange}
            />
          </MDBox>
        </MDBox>
      ),
    });
  }

  if (programmer.length > 0) {
    programmer.forEach((item) => {
      renderMyRows(item);
    });
  }

  function myTableData() {
    return {
      columns: [
        { Header: 'Logo', accessor: 'logo', width: '10%', align: 'left' },
        { Header: 'Nome', accessor: 'name', width: '80%', align: 'left' },
        { Header: '', accessor: 'action', width: '10%', align: 'center' },
      ],
      rows: myRows,
    };
  }

  return (
    <>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <DataTable
        table={myTableData()}
        isSorted={true}
        entriesPerPage={false}
        noEndBorder
        pagination={{ variant: 'gradient', color: 'warning' }}
      />
    </>
  );
}
