/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable */
import { Modal } from '@mui/material';

import axios from 'axios';
import { Buildings, CheckCircle, XCircle } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { useFindAllTariffsQuery } from '../../features/tariff/tariffService';
import { baseUrl } from '../../utils/base_url';

import MDButton from '../MDButton';

import './tariffStyle.css';

export default function TariffModal({ notify }) {
  const role = useSelector((state) => state.auth.user.role);
  const [planName, setPlanName] = useState('');
  const [planIdIxc, setPlanIdIxc] = useState('');
  const [salePrice, setSalePrice] = useState('');
  const [groups, setGroups] = useState([]);
  const [planGroups, setPlanGroups] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([
    { id: 'L', name: 'Lifeline' },
    { id: 'SB', name: 'Sub Básico' },
    { id: 'B', name: 'Básico' },
    { id: 'E', name: 'Estendido' },
  ]);
  const [planCategory, setPlanCategory] = useState('');
  const { refetch } = useFindAllTariffsQuery();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const validator = (state) => {
    errorNotify(state.message);
    document.getElementById(state.id).classList.replace('inputField', 'inputFieldError');
  };

  const handleSalePriceChange = (event) => {
    const rawValue = event.target.value;
    const onlyNumbers = rawValue.replace(/\D/g, ''); // remove tudo que não for número
    const formattedValue = Number(onlyNumbers) / 100; // divide por 100 para transformar em número decimal
    setSalePrice(formattedValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
  };

  async function handleCreateTariff(thisName, thisPlanIdIxc, thisSalePrice) {
    if (thisName?.length < 3) {
      validator({ message: 'Nome deve conter ao menos 3 caracteres', id: 'planName' });
    } else {
      document.getElementById('planName').classList.replace('inputFieldError', 'inputFieldSuccess');
    }

    if (!thisPlanIdIxc > 0) {
      validator({ message: 'id do IXC deve ser entre 1 e 9999', id: 'planIXCId' });
    } else {
      document
        .getElementById('planIXCId')
        .classList.replace('inputFieldError', 'inputFieldSuccess');
    }

    if (!Number(thisSalePrice.slice(2, -3)) > 0) {
      validator({ message: 'Valor deve ser maior que 0', id: 'planPrice' });
    } else {
      document
        .getElementById('planPrice')
        .classList.replace('inputFieldError', 'inputFieldSuccess');
    }

    if (thisName?.length < 3 || !thisPlanIdIxc > 0 || !thisSalePrice > 0) {
      return;
    }

    axios
      .post(`${baseUrl}/plan`, {
        name: thisName,
        plan_id_ixc: thisPlanIdIxc,
        sale: Number(thisSalePrice.slice(2, -3)),
        channels_group: planGroups,
        category: planCategory,
      })
      .then((res) => {
        successNotify(res.data.message);
        setTimeout(() => {
          setPlanCategory();
          setPlanGroups([]);
          setPlanIdIxc('');
          setSalePrice('');
          setPlanName('');
          handleClose();
          refetch();
        }, 500);
      })
      .catch((err) => {
        errorNotify(
          err.response.data.message.length > 1
            ? err.response.data.message[0]
            : err.response.data.message
        );
      });
  }

  function handleAddChannelToGroup(items) {
    const newList = groups.filter((item) => item.id !== items.id);
    setGroups(newList);
    setPlanGroups([...planGroups, items]);
  }

  function handleRemoveChannelToGroup(items) {
    const newList = planGroups.filter((item) => item.id !== items.id);
    setPlanGroups(newList);
    setGroups([...groups, items]);
  }

  useEffect(async () => {
    axios.get(`${baseUrl}/channel-groups`).then((res) => setGroups(res.data.payload));
  }, [open]);

  return (
    <>
      <span>
        {role !== 'operatorTubaron' && (
          <MDButton
            sx={{ ml: 1 }}
            ize="medium"
            variant="gradient"
            color="success"
            onClick={handleOpen}
          >
            Criar Plano
          </MDButton>
        )}
      </span>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form action="" className="operatorForm">
          <SnackbarProvider
            iconVariant={{
              success: (
                <CheckCircle
                  size={16}
                  color="#ffffff"
                  weight="fill"
                  style={{ marginRight: '10px' }}
                />
              ),
              error: (
                <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
              ),
            }}
            dense
            autoHideDuration={2500}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
          <div className="formHeader">
            <div className="formLogo">
              <Buildings size={28} color="#ffffff" />
            </div>
          </div>
          <h2 className="modalTitle">Criar Plano</h2>
          <div className="tariffFormGrid">
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="nome">Nome: </label>
              <input
                value={planName}
                type="text"
                id="planName"
                className="inputField"
                onChange={
                  (e) => setPlanName(e.target.value)
                  // dispatch(setTariffFormState({ ...tariffForm, name: e.target.value }))
                }
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="planIdIxc">Plano Id Ixc:</label>
              <input
                value={planIdIxc}
                id="planIXCId"
                className="inputField"
                onChange={(e) => setPlanIdIxc(e.target.value)}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="price">Preço: </label>
              <input
                value={salePrice}
                id="planPrice"
                className="inputField"
                placeholder="R$0,00"
                onChange={handleSalePriceChange}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="groupSelect">Categoria:</label>
              <select
                id="groupSelect"
                value={planCategory}
                className="inputField"
                placeholder="seleciona uma opção"
                defaultChecked
                onChange={(e) => {
                  setPlanCategory(e.target.value);
                }}
              >
                <option value="" disabled>
                  Escolha uma Categoria
                </option>
                {categoryOptions?.map((categ) => (
                  <option key={categ.id} value={categ.id}>
                    {categ.name}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{ display: 'flex', marginTop: '1rem', flexDirection: 'column' }}
              className="formFields"
            >
              <label>Grupos Disponiveis: {groups.length} </label>
              <div className="channels">
                {groups?.map((item) => (
                  <button
                    key={item.id}
                    type="button"
                    value={item.id}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      display: 'flex',
                      gap: '1rem',
                    }}
                    onClick={() => handleAddChannelToGroup(item)}
                  >
                    <span style={{ color: 'white' }}>{item.name}</span>
                  </button>
                ))}
              </div>
            </div>
            <div
              style={{ display: 'flex', marginTop: '1rem', flexDirection: 'column' }}
              className="formFields"
              id="groupChannels"
            >
              <label>Grupos que pertecem ao plano: {planGroups?.length} </label>
              <div className="channels">
                {planGroups?.map((item) => (
                  <button
                    key={item.id}
                    type="button"
                    value={item.id}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      display: 'flex',
                      gap: '1rem',
                    }}
                    onClick={() => handleRemoveChannelToGroup(item)}
                  >
                    <span style={{ color: 'white' }}>{item.name}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="buttonsCreateTariff">
            <MDButton
              onClick={() => handleClose()}
              className="formBtn"
              color="error"
              variant="contained"
            >
              Cancelar
            </MDButton>

            <MDButton
              onClick={() => {
                handleCreateTariff(planName, planIdIxc, salePrice);
              }}
              className="formBtn"
              color="success"
              variant="contained"
            >
              Criar
            </MDButton>
          </div>
        </form>
      </Modal>
    </>
  );
}
