import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'customer',
  initialState: {},
  reducers: {
    changeUser(state, { payload }) {
      return { ...state, user: payload };
    },
    cleanUser(state) {
      return { ...state, user: {} };
    },
  },
});

export const { changeUser, cleanUser } = slice.actions;

export const selectUser = (state) => state.user;

export default slice.reducer;
//
