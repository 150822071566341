/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from 'react';

// react-router components
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// // theme
// import 'primereact/resources/themes/lara-light-indigo/theme.css';

// // core
// import 'primereact/resources/primereact.min.css';

// // icons
// import 'primeicons/primeicons.css';

// @mui material components
import CssBaseline from '@mui/material/CssBaseline';
import Icon from '@mui/material/Icon';
import { ThemeProvider } from '@mui/material/styles';

// RTL plugins
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import rtlPlugin from 'stylis-plugin-rtl';

import { useSelector } from 'react-redux';
// Material Dashboard 2 React routes
import { SnackbarProvider } from 'notistack';
import { CheckCircle, XCircle } from 'phosphor-react';
import {
  adminResaleRoutes,
  adminRoutes,
  commonUserRoutes,
  signRoutes,
  operatorTubaronRoutes,
  CHTECHROUTES,
} from './routes';

// Material Dashboard 2 React contexts
import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from './context';

// Images
import brandDark from './assets/images/logo-ct-dark.png';
import brandWhite from './assets/images/logo-ct.png';
// Material Dashboard 2 React components
import MDBox from './components/MDBox';

// Material Dashboard 2 React Dark Mode themes
import themeDark from './assets/theme-dark';

// Material Dashboard 2 React example components
import Sidenav from './examples/Sidenav';

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.expansive) {
        return getRoutes(route.expansive);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: 'pointer' }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  const isLogged = useSelector((state) => state.auth.isLogged);
  const user = useSelector((state) => state.auth.user);

  let routesPermission = commonUserRoutes;

  const admin = true;

  if (user === null) {
    routesPermission = signRoutes;
    // TODO: CHUMBAR O ID DA RESALE CHTECH
  } else if (user.resaleId === 'd1aa388c-6ab0-4fad-843e-ce25b0e5cca6') {
    routesPermission = CHTECHROUTES;
  } else {
    switch (user.role) {
      case 'admin':
        routesPermission = adminRoutes;
        break;
      case 'resaleAdmin':
        routesPermission = adminResaleRoutes;
        break;
      case 'operatorTubaron':
        routesPermission = operatorTubaronRoutes;
        break;
      default:
        routesPermission = commonUserRoutes;
        break;
    }
  }

  return direction === 'rtl' ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={themeDark}>
        <CssBaseline />
        {layout === 'dashboard' && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Material Dashboard 2"
              routes={admin === true ? adminRoutes : commonUserRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />

            {configsButton}
          </>
        )}
        <Routes>
          {getRoutes(admin === true ? adminRoutes : commonUserRoutes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={themeDark}>
      <CssBaseline />
      {isLogged ? (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="TubaResale"
            routes={routesPermission}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Routes>
            {getRoutes(routesPermission)}
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </>
      ) : (
        <Routes>
          {getRoutes(signRoutes)}
          <Route path="*" element={<Navigate to="/sign-in" />} />
          <Route path="*" element={<Navigate to="/forgot-password/:id" />} />
        </Routes>
      )}
    </ThemeProvider>
  );
}
