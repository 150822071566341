/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import { CircularProgress, Modal, Select } from '@mui/material';

import axios from 'axios';
import { CheckCircle, Money, XCircle, Receipt, FilePdf, WarningOctagon } from 'phosphor-react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { baseUrl } from '../../utils/base_url';
import MDButton from '../MDButton';

import './styles.css';
import { InvoiceToPrint } from './components/InvoiceToPrint/index.jsx';

export default function CustomerBillModal({ customer, title, iconVariant, btnText }) {
  const role = useSelector((state) => state.auth.user.role);
  const [loading, setLoading] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [open, setOpen] = useState(false);
  const componentRef = useRef();

  const handleOpen = () => {
    setOpen(true);
    setLoading(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  function invoiceStatus(vencimento, status) {
    const now = new Date(new Date().toLocaleString('en-US').split(',')[0]);
    const venc = new Date(`${vencimento}T00:00:00`);

    const diffTimes = venc.getTime() - now.getTime();
    const diffDays = diffTimes / (1000 * 3600 * 24);

    if (status === 'C') {
      return { message: 'Fatura Cancelada', class: 'fatura FaturaCancelada' };
    }

    if (status === 'A' && diffDays < 0) {
      return { message: 'Fatura Vencida', class: 'fatura FaturaVencida' };
    }

    if (status === 'A' && diffDays > 0) {
      return { message: 'Fatura em Aberto', class: 'fatura FaturaAberta' };
    }

    return { message: 'Fatura Paga', class: 'fatura FaturaPaga' };
  }

  useEffect(() => {
    if (open) {
      setLoading(true);
      axios
        .post(`${baseUrl}/invoices`, {
          id: customer.id,
        })
        .then((response) => {
          const data = response.data?.payload;
          data?.sort((a, b) => {
            if (a.data_vencimento < b.data_vencimento) {
              return -1;
            }
            if (b.data_vencimento < a.data_vencimento) {
              return 1;
            }
            return 0;
          });
          setLoading(false);
          setInvoiceList(data);
        });
    }
  }, [open]);

  return (
    <div style={{ position: 'relative' }}>
      <span>
        <button type="button" onClick={handleOpen} className="buttonHeader">
          {iconVariant === 'bill' ? <Receipt weight="fill" size={18} color="white" /> : null}
          {btnText}
        </button>
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ position: 'absolute', top: '0' }}
      >
        <form action="" className="operatorForm">
          <SnackbarProvider
            iconVariant={{
              success: (
                <CheckCircle
                  size={16}
                  color="#ffffff"
                  weight="fill"
                  style={{ marginRight: '10px' }}
                />
              ),
              error: (
                <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
              ),
            }}
            dense
            autoHideDuration={2500}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
          <div className="formHeader">
            <div className="formLogo">
              <Receipt size={28} color="#ffffff" weight="fill" />
            </div>
          </div>
          <h2 className="modalTitle">Faturas de {customer.razao}</h2>
          {loading ? (
            <div className="loading">
              <CircularProgress color="warning" size={60} />
            </div>
          ) : (
            <div className="tableContainer">
              {invoiceList?.length > 0 ? (
                <table className="billTable">
                  <thead>
                    <tr>
                      <th>Vencimento</th>
                      <th>Valor</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceList?.map((invoice) => (
                      <tr key={invoice.id}>
                        <td>{invoice.data_vencimento.split('-').reverse().join('/')}</td>
                        <td>
                          {Number(invoice.valor)?.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 2,
                          })}
                        </td>
                        <td>
                          <span
                            className={invoiceStatus(invoice.data_vencimento, invoice.status).class}
                          >
                            {invoiceStatus(invoice.data_vencimento, invoice.status).message}
                          </span>
                        </td>
                        <td>
                          <InvoiceToPrint invoice={invoice} customer={customer} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="emptyIcon">
                  <WarningOctagon size={180} weight="bold" color="#344767" />
                  <span style={{ color: '#344767' }}>Este Cliente não possui boletos gerados</span>
                </div>
              )}
              <div className="actionButtons">
                <MDButton
                  onClick={() => handleClose()}
                  className="formBtn"
                  color="error"
                  variant="contained"
                >
                  fechar
                </MDButton>
              </div>
            </div>
          )}
        </form>
      </Modal>
    </div>
  );
}
