/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { WarningOctagon } from 'phosphor-react';
import { changeUser } from '../../../../redux/userSlice';
import { baseUrl } from '../../../../utils/base_url';
import TimelineItem from '../../../../examples/Timeline/TimelineItem';
import MDBox from '../../../../components/MDBox';
import MDTypography from '../../../../components/MDTypography';

// Material Dashboard 2 React example components

function OrdersOverview({ customers }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function handleCustomer(id) {
    await axios.get(`${baseUrl}/customer/${id}`).then((data) => dispatch(changeUser(data.data)));
    navigate('/cliente#title');
  }

  return (
    <Card sx={{ height: '100%' }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Ultimos logins
        </MDTypography>
        <MDBox mt={0} mb={-1.5} />
      </MDBox>
      <MDBox p={2} sx={{ display: 'flex', flexDirection: 'column' }}>
        {customers === undefined ? (
          <div className="emptyIcon">
            <WarningOctagon size={180} weight="bold" color="#344767" />
            <span style={{ color: '#344767' }}>Nenhum cliente </span>
          </div>
        ) : (
          customers.map((item) => {
            const data = new Date(item.createdAt);
            const dia = `0${data.getDate()}`.slice(-2);
            const mes = `0${data.getMonth() + 1}`.slice(-2); // Note que os meses são indexados de 0 a 11
            const ano = data.getFullYear();
            const horas = data.getHours();
            const minutos = data.getMinutes().toString().padStart(2, '0');
            if (item.login) {
              return (
                // <button
                //   style={{
                //     width: 'auto',
                //     backgroundColor: 'transparent',
                //     border: 0,
                //   }}
                //   onClick={() => handleCustomer(item.id)}
                // >
                <TimelineItem
                  color="success"
                  icon="notifications"
                  title={`#${item?.login}`}
                  dateTime={`${dia}/${mes}/${ano} - ${horas}:${minutos}`}
                  key={item.createdAt}
                />
                // </button>
              );
            }
            return null;
          })
        )}
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
