/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable*/

import { Card, Icon } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as Switch from '@radix-ui/react-switch';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { CheckCircle, Pencil, XCircle } from 'phosphor-react';
import { useEffect, useState } from 'react';
import ConfirmDeleteModal from '../ConfirmDeleteModal/index.jsx';
import { changeUpdate } from '../../redux/resaleSlice';
import { useMaterialUIController } from '../../context';
import DataTable from '../../examples/Tables/DataTable';
import { changeUser } from '../../redux/userSlice';
import MDTypography from '../MDTypography';
import { baseUrl } from '../../utils/base_url';
import MDButton from '../MDButton';
import MDBox from '../MDBox';

export default function CustomerTableByResale({ data, filtered, query }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const myRows = [];
  const [users, setUsers] = useState([]);
  const [update, setUpdate] = useState(false);

  const dispatch = useDispatch();

  useEffect(async () => {
    await axios
      .get(`${baseUrl}/customer/resale/${data}`)
      .then((thisData) => {
        setUsers(thisData.data);
      })
      .catch((err) => {
        errorNotify('Revenda sem clientes');
      });
  }, [update]);

  function handleCustomer(value) {
    dispatch(changeUser(value));
  }

  const successNotify = (status) => {
    const thisMessage =
      status === 'S' ? `Cliente ativado com sucesso` : `Cliente desativado com sucesso`;
    enqueueSnackbar(thisMessage, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const deleteNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const handleDeleteCustomer = async (id) => {
    await axios
      .delete(`${baseUrl}/customer/${id}`)
      .then(() => deleteNotify('Cliente deletado com sucesso'))
      .catch((error) => errorNotify(error.response.data.message));
    setUpdate(!update);
  };

  function statusColor(status) {
    switch (status) {
      case 'Aguardando Assinatura':
        return 'contratoAguardandoAssinatura';
      case 'Ativo':
        return 'contratoAtivo';
      case 'Pré-contrato':
        return 'contratoPreContrato';
      case 'Sem contrato':
        return 'contratoSemContrato';
      case 'Inativo':
        return 'contratoInativo';
      case 'Desativado':
        return 'contratoInativo';
      case 'Negativado':
        return 'contratoNegativado';
      default:
        return 'c';
        break;
    }
  }

  if (data === undefined) {
    console.log();
  } else if (filtered === true && query.length > 0) {
    const filteredUser = Object.values(users).filter((customer) =>
      customer.razao.toLowerCase().includes(query.toLowerCase())
    );
    filteredUser?.forEach((item) => {
      myRows.push({
        name: (
          <Link to="/cliente" onClick={() => handleCustomer(item)} className="profileInfos">
            <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                flexDirection={{ xs: 'row', sm: 'row' }}
                mb={2}
              >
                <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                  {item.razao}
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={1}>
                <MDTypography variant="caption" color="text">
                  Cidade:&nbsp;&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="medium">
                    {item.city.name}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
              <MDBox mb={1} lineHeight={1}>
                <MDTypography variant="caption" color="text">
                  CPF:&nbsp;&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                    {item.cnpj_cpf}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </Link>
        ),
        action: (
          <MDBox width="100%" display="flex" alignItems="flex-end" flexDirection="column" border>
            <MDBox mb={3} />
            <MDBox display="flex" alignItems="right" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
              <MDBox mr={1}>
                <ConfirmDeleteModal deleteCustomer={() => handleDeleteCustomer(item.id)} />
              </MDBox>
              <Link to="/profile" style={{ color: '#fff' }}>
                <MDButton variant="text" onClick={() => handleCustomer(item)} color="info">
                  <Icon>edit</Icon>&nbsp;Editar
                </MDButton>
              </Link>
            </MDBox>
            <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
              <MDBox mb={1} lineHeight={1}>
                <MDTypography variant="caption" color="text">
                  Plano:&nbsp;&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                    {item.tb_account[0]?.tariff_plan.name
                      ? item.tb_account[0]?.tariff_plan.name
                      : 'Sem plano'}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
              <MDBox mb={1} lineHeight={1}>
                <MDTypography variant="caption" color="text">
                  Contrato:&nbsp;&nbsp;&nbsp;
                  <span
                    variant="caption"
                    fontWeight="medium"
                    className={statusColor(item.status_internet_ixc)}
                  >
                    {item.status_internet_ixc}
                  </span>
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
              <MDBox mb={1} lineHeight={1}>
                <MDTypography variant="caption" color="text">
                  Status Acesso:&nbsp;&nbsp;&nbsp;
                  <span
                    variant="caption"
                    fontWeight="medium"
                    className={statusColor(item.status_internet_ixc)}
                  >
                    {item.status_ixc}
                  </span>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        ),
      });
    });
  } else {
    users?.forEach((item) => {
      myRows.push({
        name: (
          <Link to="/cliente" onClick={() => handleCustomer(item)} className="profileInfos">
            <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                flexDirection={{ xs: 'row', sm: 'row' }}
                mb={2}
              >
                <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                  {item.razao}
                </MDTypography>
              </MDBox>
              <MDBox mb={1} lineHeight={1}>
                <MDTypography variant="caption" color="text">
                  Cidade:&nbsp;&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="medium">
                    {item.city.name}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
              <MDBox mb={1} lineHeight={1}>
                <MDTypography variant="caption" color="text">
                  CPF:&nbsp;&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                    {item.cnpj_cpf}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
              <MDBox mb={1} lineHeight={1}>
                <MDTypography variant="caption" color="text">
                  Plano:&nbsp;&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                    {item.tb_account[0]?.tariff_plan.name
                      ? item.tb_account[0]?.tariff_plan.name
                      : 'Sem plano'}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
              <MDBox mb={1} lineHeight={1}>
                <MDTypography variant="caption" color="text">
                  Contrato:&nbsp;&nbsp;&nbsp;
                  <span
                    variant="caption"
                    fontWeight="medium"
                    className={statusColor(item.status_internet_ixc)}
                  >
                    {item.status_internet_ixc}
                  </span>
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
              <MDBox mb={1} lineHeight={1}>
                <MDTypography variant="caption" color="text">
                  Status Acesso:&nbsp;&nbsp;&nbsp;
                  <span
                    variant="caption"
                    fontWeight="medium"
                    className={statusColor(item.status_internet_ixc)}
                  >
                    {item.status_ixc}
                  </span>
                </MDTypography>
              </MDBox>
            </MDBox>
          </Link>
        ),
        action: (
          <MDBox width="100%" display="flex" alignItems="flex-end" flexDirection="column" border>
            <MDBox mb={3} />
            <MDBox display="flex" alignItems="right" mt={{ xs: 2, sm: 1 }} ml={{ xs: -1.5, sm: 0 }}>
              <MDBox mr={1}>
                <ConfirmDeleteModal deleteCustomer={() => handleDeleteCustomer(item.id)} />
              </MDBox>
              <Link to="/cliente" style={{ color: '#fff' }}>
                <MDButton size="small" onClick={() => handleCustomer(item)} color="info">
                  <Pencil weight="fill" />
                  Editar
                </MDButton>
              </Link>
            </MDBox>
          </MDBox>
        ),
      });
    });
  }

  const myTableData = {
    columns: [
      { Header: 'name', accessor: 'name', width: '70%' },
      { Header: 'action', accessor: 'action', width: '30%' },
    ],
    rows: myRows,
  };
  return (
    <>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />

      <DataTable
        table={myTableData}
        isSorted={false}
        entriesPerPage={false}
        noEndBorder
        pagination={{ variant: 'gradient', color: 'warning' }}
      />
    </>
  );
}
