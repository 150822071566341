/* eslint-disable no-unused-vars */
/* eslint-disable */
import { Card, CircularProgress, Grid } from '@mui/material';
// import CustomerTableByResale from 'components/CustomerTableByResale';
import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { CheckCircle, XCircle } from 'phosphor-react';
import MDButton from '../../components/MDButton';
import ChannelModal from '../../components/ChannelModal/index.jsx';
import { baseUrl } from '../../utils/base_url';
import DeleteProgrammerGroupChannelModal from '../../components/DeleteProgrammerGroupChannelModal';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';

import './styles.css';
import ChannelTable from './ChannelTable';

export default function Channels() {
  const role = useSelector((state) => state.auth.user.role);
  const [channels, setChannels] = useState([]);
  const [query, setQuery] = useState('');
  const [update, setupdate] = useReducer((x) => x + 1, 0);
  const [loading, setloading] = useState(false);

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  function handleChannelListChange(data) {
    if (data.success === true) {
      setupdate();
      successNotify(data.message);
    } else {
      errorNotify(data.message);
    }
  }

  function handleSearchEvent(e) {
    if (e === 'Enter') {
      axios.get(`${baseUrl}/channels`).then((channels) => {
        const filter = channels.data.filter(
          (item) =>
            item.name.toLowerCase().trim().includes(query.toLowerCase().trim()) ||
            item.number == query
        );
        setChannels(filter);
      });
    }
  }

  useEffect(() => {
    setloading(true);
    axios.get(`${baseUrl}/channels`).then((data) => {
      data?.data.sort((a, b) => {
        if (a.number < b.number) {
          return -1;
        }
        if (b.number < a.number) {
          return 1;
        }
        return 0;
      });
      setChannels(data.data);
      setloading(false);
    });
  }, [update]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      {role !== 'operatorTubaron' && (
        <div className="btnContainer">
          <ChannelModal
            size="small"
            iconVariant="add"
            btnText="Adicionar Canal"
            title="Adicionar Canal"
            handleChannelListChange={handleChannelListChange}
            color="success"
          />
        </div>
      )}
      <MDBox mt={6} mb={3}>
        <Grid item xs={12}>
          <div>
            <MDBox
              sx={{ mx: 3, mb: -1, position: 'relative' }}
              mx={2}
              mt={-2}
              py={3}
              px={2}
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="info"
              display={{ sm: 'flex' }}
              alignItems={{ sm: 'center' }}
              justifyContent={{ sm: 'space-between' }}
            >
              <MDTypography variant="h6" color="white">
                Canais de TV
              </MDTypography>
              <div className="menuOptions">
                <input
                  id="myInput"
                  className="myInput"
                  placeholder="Canais..."
                  value={query}
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                  onKeyDown={(e) => handleSearchEvent(e.key)}
                />
              </div>
            </MDBox>
            <MDBox pt={-3} pb={2} px={2}>
              <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                <Card>
                  {loading ? (
                    <div className="loading">
                      <CircularProgress color="warning" size={60} />
                    </div>
                  ) : (
                    <ChannelTable
                      channels={channels}
                      loading={loading}
                      handleChannelListChange={handleChannelListChange}
                      setupdate={setupdate}
                    />
                  )}
                </Card>
              </MDBox>
            </MDBox>
          </div>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
