/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable */
// Images
import DataTable from 'examples/Tables/DataTable/index.js';
import DeleteProgrammerGroupChannelModal from '../../../components/DeleteProgrammerGroupChannelModal/index.jsx';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { CheckCircle, XCircle } from 'phosphor-react';
import ChannelGroupModal from 'components/ChannelGroupModal/index.jsx';
import { useState } from 'react';
import DetailsChannelGroup from '../DetailsChannelGroup/index.jsx';
// import MDBox from '../../../components/MDBox';

export function ChannelGroupTable({ ChannelGroup, loading, setupdate, handleListGroupsChange }) {
  const myRows = [];
  const [openModal, setOpenModal] = useState(false);
  const [group, setGroup] = useState([]);

  const handleEditGroup = (item) => {
    if (item) {
      setOpenModal(true);
    } else {
      setOpenModal(!openModal);
    }
    setGroup(item);
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  function handleListGroupsChange(data) {
    if (data.success === true) {
      successNotify(data.message);
    }
    setTimeout(() => {
      setupdate();
    }, 1000);
  }

  function renderMyRows(item) {
    return myRows.push({
      name: (
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
            {item.name}
          </MDTypography>
        </MDBox>
      ),
      description: (
        <MDBox>
          <MDTypography>
            <span className="channelGroupDescription">{item.description}</span>
          </MDTypography>
        </MDBox>
      ),
      channelAmount: (
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
            <span>{item.Channel.length}</span>
          </MDTypography>
        </MDBox>
      ),
      price: (
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
            <span>
              {Number(item.value?.toFixed(2))?.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
              })}
            </span>
          </MDTypography>
        </MDBox>
      ),
      action: (
        <MDBox width="100%" display="flex" alignItems="flex-end" flexDirection="column" border>
          <MDBox display="flex" alignItems="right" gap={1}>
            <ChannelGroupModal
              data={item}
              title={`Editar Grupo ${item?.name}`}
              handleListGroupsChange={handleListGroupsChange}
              modalOpen={openModal}
              handleEditGroup={handleEditGroup}
              iconVariant={'edit'}
            />
            <DeleteProgrammerGroupChannelModal
              data={item}
              grupoDeCanais={true}
              handleListGroupsChange={handleListGroupsChange}
            />
            <DetailsChannelGroup
              channelGroupChannels={item.Channel}
              iconVariant="see"
              size="small"
              title={`Detalhes Programadora ${item.name}`}
              handleListGroupsChange={handleListGroupsChange}
            />
          </MDBox>
        </MDBox>
      ),
    });
  }

  if (ChannelGroup?.length > 0) {
    ChannelGroup.forEach((item) => {
      renderMyRows(item);
    });
  }

  function myTableData() {
    return {
      columns: [
        { Header: 'Nome', accessor: 'name', width: '25%', align: 'left' },
        { Header: 'Descrição', accessor: 'description', width: '25%', align: 'left' },
        {
          Header: 'Quantidade de Canais',
          accessor: 'channelAmount',
          width: '15%',
          align: 'center',
        },
        { Header: 'Custo', accessor: 'price', width: '20%', align: 'left' },
        { Header: '', accessor: 'action', width: '10%', align: 'center' },
      ],
      rows: myRows,
    };
  }

  return (
    <>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <DataTable
        table={myTableData()}
        isSorted={true}
        entriesPerPage={false}
        noEndBorder
        pagination={{ variant: 'gradient', color: 'warning' }}
      />
    </>
  );
}
