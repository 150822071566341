/* eslint-disable react/prop-types */
import { Modal } from '@mui/material';
import { CircleNotch, Trash } from 'phosphor-react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import MDButton from '../MDButton';

import './deleteForm.css';

/* eslint-disable jsx-a11y/label-has-associated-control */
export default function ConfirmDeleteModal({ deleteCustomer }) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const role = useSelector((state) => state.auth.user.role);
  const disabled = role === 'operator' || role === 'operatorTubaron';

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setIsLoading(true);
    setIsLoading(false);
    setOpen(false);
  };

  // const handleDeleteCustomer = async (id) => {
  //   console.log
  //   // await axios.delete(`http://localhost:3001/customer/${id}`);
  //   // dispatch(changeUpdate(!storeUpdate));
  // };
  return (
    <>
      <MDButton
        style={{ display: 'flex', gap: 5 }}
        size="small"
        variant="gradient"
        color="error"
        onClick={handleOpen}
        disabled={disabled}
      >
        <Trash weight="fill" size={14} />
        Deletar
      </MDButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form action="" className="deleteform" style={{ width: '300px' }}>
          <h2 className="modalTitle" style={{ marginTop: '20px', marginBottom: '-10px' }}>
            Atenção
          </h2>
          <div>
            <div
              style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}
              className="formFields"
            >
              <h1 style={{ color: '#ccc', fontSize: '18px' }}>Deseja deletar este cliente?</h1>
            </div>
          </div>
          <div className="confirmDeleteBtn">
            <MDButton className="formBtn" color="error" variant="contained" onClick={handleClose}>
              Cancelar
            </MDButton>

            <MDButton
              className="formBtn"
              color="success"
              variant="contained"
              onClick={() => {
                deleteCustomer();
                handleClose();
              }}
            >
              {isLoading === true ? (
                <CircleNotch size={18} weight="bold" className="isLoading" />
              ) : (
                'Confirmar'
              )}
            </MDButton>
          </div>
        </form>
      </Modal>
    </>
  );
}
