/* eslint-disable react/self-closing-comp */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import * as Tabs from '@radix-ui/react-tabs';

// Material Dashboard 2 React components

// Material Dashboard 2 React examples
import { Card, Tooltip } from '@mui/material';
// import { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Check, CheckCircle, Circle, CircleNotch, Question, X, XCircle } from 'phosphor-react';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { changeUser } from 'redux/userSlice';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { changeUpdate } from '../../redux/resaleSlice';
import MDTypography from '../../components/MDTypography';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import Footer from '../../examples/Footer';
import MDBox from '../../components/MDBox';
import SearchInput from '../../components/ChannelGroupModal/components/SearchInput.jsx';

import './style.css';
import { baseUrl } from '../../utils/base_url';
import MDButton from '../../components/MDButton';

const schema = yup.object({
  nome: yup.string().required('Campo obrigatório!').min(5, 'Nome deve ter no minimo 5 caracteres'),
  endereco: yup.string().required('Campo obrigatório!'),
  bairro: yup.string().required('Campo obrigatório!'),
  numero: yup.string().required('Campo obrigatório!'),
  complemento: yup.string(),
  email: yup.string().required('Campo obrigatório!').email('Necessario inserir um E-mail válido'),
  telefone: yup.string().required('Campo obrigatório!'),
  whatsapp: yup.string().required('Campo obrigatório!'),
  cep: yup.string().required('Campo obrigatório!'),
  cnpj_cpf: yup.string().required('Campo obrigatório!'),
  localidade: yup.string().required('Campo obrigatório!'),
  pais: yup.string(),
  ieRg: yup.string(),
  inscricao_municipal: yup.string(),
  resalecitie: yup.object(),
  contaBancaria: yup.string(),
  agencia: yup.string(),
  banco: yup.object(),
  tipoConta: yup.string(),
  pix: yup.string(),
});

export default function EditResale() {
  const testUpdate = useSelector((state) => state.resale.updateResale);
  const role = useSelector((state) => state.auth.user.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resaleData = useLocation();

  const [name, setName] = useState(resaleData.state.resale.name);
  const [address, setAddress] = useState(resaleData.state.resale.address);
  const [neighborhood, setNeighborhood] = useState(resaleData.state.resale.neighborhood);
  const [addressNumber, setAddressNumber] = useState(resaleData.state.resale.address_number);
  const [email, setEmail] = useState(resaleData.state.resale.email);
  const [selectedCountrie, setSelectedCountrie] = useState(
    resaleData.state.resale.city.State.countryId
  );
  const [selectedState, setSelectedState] = useState(resaleData.state.resale.city.stateId);
  const [cityId, setCityId] = useState(resaleData.state.resale.cityId);
  const [resaleCity, setResaleCity] = useState({});
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [resaleState, setResaleState] = useState(resaleData.state.resale.city.stateId);
  const [cities, setCities] = useState([]);
  const [phone, setPhone] = useState(resaleData.state.resale.telefone_celular);
  const [whatsapp, setWhatsapp] = useState(resaleData.state.resale.whatsapp);
  const [resaleCep, setResaleCep] = useState(resaleData.state.resale.cep);
  const [tipoPessoa, setTipoPessoa] = useState(resaleData.state.resale.tipo_pessoa);
  const [inscricaoMunicipal, setInscricaoMunicipal] = useState(
    resaleData.state.resale.inscricao_municipal
  );

  // documentos
  const [cnpjCpf, setCnpjCpf] = useState(resaleData.state.resale.cnpj_cpf);
  const [ieRg, setIeRg] = useState(resaleData.state.resale.ie_rg);
  // Dados Bancarios
  const [contaBancaria, setContaBancaria] = useState(
    resaleData.state.resale.BankData[0]?.cod_conta
      ? resaleData.state.resale.BankData[0]?.cod_conta
      : ''
  );
  const [agencia, setAgencia] = useState(
    resaleData.state.resale.BankData[0]?.cod_agencia
      ? resaleData.state.resale.BankData[0]?.cod_agencia
      : 0
  );
  const [contaDv, setContaDv] = useState(
    resaleData.state.resale.BankData[0]?.numero_conta_dv
      ? resaleData.state.resale.BankData[0]?.numero_conta_dv
      : ''
  );
  const [agenciaDv, setAgenciaDv] = useState(
    resaleData.state.resale.BankData[0]?.agencia_dv
      ? resaleData.state.resale.BankData[0]?.agencia_dv
      : ''
  );
  const [filterBank, setFilterBank] = useState([]);
  const [filterBankList, setFilterBankList] = useState([]);
  const [banco, setBanco] = useState(resaleData.state.resale.banco);
  const [tipoConta, setTipoConta] = useState(resaleData.state.resale.BankData[0]?.tipo_conta);
  const [pix, setPix] = useState(resaleData.state.resale.BankData[0]?.chave_pix);
  const [bankList, setBankList] = useState([]);
  const isFiltred = filterBankList.length > 0;

  // usuario
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  useEffect(async () => {
    await axios.get(`${baseUrl}/location/countries`).then((data) => setCountries(data.data));
    await axios
      .get(`${baseUrl}/location/states/${selectedCountrie}`)
      .then((data) => setStates(data.data));

    await axios.get(`${baseUrl}/location/cities/${selectedState}`).then((data) => {
      setCities(data.data);
    });
  }, []);
  const formatCpfCnpj = (inputValue) => {
    let formattedValue = inputValue.replace(/\D/g, ''); // remove todos os caracteres que não são números

    if (formattedValue.length <= 11) {
      // CPF
      formattedValue = formattedValue.replace(/(\d{3})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/(\d{3})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      // CNPJ
      formattedValue = formattedValue.replace(/^(\d{2})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
      formattedValue = formattedValue.replace(/\.(\d{3})(\d)/, '.$1/$2');
      formattedValue = formattedValue.replace(/(\d{4})(\d)/, '$1-$2');
    }

    return formattedValue;
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatCpfCnpj(inputValue);
    setCnpjCpf(formattedValue);
  };

  const handlePhoneChange = (event) => {
    let formattedPhone = event.target.value.replace(/\D/g, '');
    formattedPhone = formattedPhone.replace(/^(\d{2})(\d)/g, '($1) $2');
    formattedPhone = formattedPhone.replace(/(\d)(\d{4})$/, '$1-$2');
    setPhone(formattedPhone);
  };
  const handleWhatsappChange = (event) => {
    let formattedPhone = event.target.value.replace(/\D/g, '');
    formattedPhone = formattedPhone.replace(/^(\d{2})(\d)/g, '($1) $2');
    formattedPhone = formattedPhone.replace(/(\d)(\d{4})$/, '$1-$2');
    setWhatsapp(formattedPhone);
  };

  const handleCepChange = (event) => {
    let formattedCep = event.target.value.replace(/\D/g, '');
    formattedCep = formattedCep.replace(/^(\d{5})(\d)/g, '$1-$2');
    setResaleCep(formattedCep);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [isLoading, setIsLoading] = useState(false);

  const handleNavigationBack = () => {
    navigate('/revendas');
  };

  const findState = async (id) => {
    setSelectedCountrie(id);
    setStates([]);
    await axios.get(`${baseUrl}/location/states/${id}`).then((data) => {
      setStates(data.data);
    });
  };

  const findCities = async (id) => {
    await axios.get(`${baseUrl}/location/cities/${id}`).then((data) => {
      setCities(data.data);
    });
  };

  const citieFullObject = async (id) => {
    findCities(resaleState);
    setResaleCity(cities.find((item) => item.id === id));
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };
  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  useEffect(async () => {
    await axios
      .get('https://brasilapi.com.br/api/banks/v1', {
        mode: 'no-cors',
        auth: '',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
      .then((res) => {
        setBankList(res.data);
        setBanco(res.data[0]);
      });
  }, []);

  useEffect(() => {
    if (filterBank.length > 0) {
      const fill = bankList.filter((item) =>
        item.fullName?.toLowerCase().includes(filterBank?.toLowerCase())
      );
      setFilterBankList(fill);
    }
  }, [filterBank]);

  function handleChangeBank(value) {
    const bank = bankList.filter((bankk) => bankk.ispb === value);
    setBanco(bank[0]);
  }
  const onSubmit = async (
    data
    // idIxc,
    // resaleName,
    // resaleCidade,
    // resaleStatus,
    // resaleAdress,
    // resaleAdressNumber,
    // resaleEmail,
    // resaleNeighborhood,
    // resaleCnpjCpf,
    // resaleTelefone
  ) => {
    setIsLoading(true);
    await axios
      .patch(`${baseUrl}/resale/${resaleData.state.resale.id_ixc}`, {
        name,
        cityId,
        status: resaleData.state.resale.status,
        address,
        address_number: Number(addressNumber),
        cnpj_cpf: cnpjCpf,
        email,
        neighborhood,
        telefone_celular: phone,
        whatsapp,
        cep: resaleCep,
        ie_rg: ieRg,
        inscricao_municipal: inscricaoMunicipal,
        cityResale: resaleCity,
        dados_bancarios: {
          banco,
          tipo_conta: tipoConta,
          chave_pix: pix,
          agencia: {
            cod_conta: contaBancaria,
            agencia_dv: agenciaDv,
            cod_agencia: agencia,
            numero_conta_dv: contaDv,
          },
        },
        login: {
          full_name: name,
          email : login,
          password,
        },
      })
      .then((thisData) => {
        successNotify(thisData.data.message);
      })
      .catch((error) => {
        errorNotify(error.message);
      });
    // console.log(name);
    // console.log(address);
    // console.log(addressNumber);
    // console.log(neighborhood);
    // console.log(email);
    // console.log(selectedCountrie);
    // console.log(whatsapp);
    // console.log(phone);

    setIsLoading(false);
    // dispatch(changeUpdate(!testUpdate));
  };

  return (
    <>
      <div>
        <SnackbarProvider
          iconVariant={{
            success: (
              <CheckCircle
                size={16}
                color="#ffffff"
                weight="fill"
                style={{ marginRight: '10px' }}
              />
            ),
            error: (
              <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
            ),
          }}
          dense
          autoHideDuration={1500}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
      </div>
      <DashboardLayout>
        <MDBox mt={6} mb={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                mb={4}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
                display={{ sm: 'flex' }}
                alignItems={{ sm: 'center' }}
                justifyContent={{ sm: 'space-between' }}
              >
                <MDTypography variant="h6" color="white">
                  Revenda
                </MDTypography>

                <X
                  size={32}
                  color="#fff"
                  onClick={() => handleNavigationBack()}
                  className="closeEdit"
                />
              </MDBox>

              <MDBox pt={1} pb={2} px={2}>
                <Tabs.Root className="TabsRoot" defaultValue="tab1">
                  <form className="">
                    <Tabs.List className="TabsList" aria-label="Manage your account">
                      <Tabs.Trigger className="TabsTrigger" value="tab1">
                        Dados
                      </Tabs.Trigger>
                      <Tabs.Trigger className="TabsTrigger" value="tab3">
                        Dados Bancarios
                      </Tabs.Trigger>
                      <Tabs.Trigger className="TabsTrigger" value="tab2">
                        Usuário
                      </Tabs.Trigger>
                    </Tabs.List>
                    <Tabs.Content className="TabsContent" value="tab1">
                      <div action="test" className="resaleFormGrid">
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="nome">Razão:</label>
                            <span className="span">
                              {errors.nome !== undefined ? errors.nome.message : ''}
                            </span>
                          </div>
                          <input
                            className={
                              errors.nome !== undefined ? 'inputField error' : 'inputField'
                            }
                            type="text"
                            id="nome"
                            onChange={(e) => setName(e.target.value)}
                            defaultValue={name}
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="endereco">Endereço: </label>
                            <span className="span">
                              {errors.endereco !== undefined ? errors.endereco.message : ''}
                            </span>
                          </div>
                          <input
                            type="text"
                            defaultValue={address}
                            onChange={(e) => setAddress(e.target.value)}
                            id="endereco"
                            className="inputField"
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="bairro">Bairro:</label>
                            <span className="span">
                              {errors.bairro !== undefined ? errors.bairro.message : ''}
                            </span>
                          </div>
                          <input
                            type="text"
                            id="bairro"
                            defaultValue={neighborhood}
                            onChange={(e) => setNeighborhood(e.target.value)}
                            className="inputField"
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="numero">Numero:</label>
                            <span className="span">
                              {errors.numero !== undefined ? errors.numero.message : ''}
                            </span>
                          </div>
                          <input
                            type="tel"
                            defaultValue={addressNumber}
                            onChange={(e) => setAddressNumber(e.target.value)}
                            id="numero"
                            className="inputField"
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="email">Email: </label>
                            <span className="span">
                              {errors.email !== undefined ? errors.email.message : ''}
                            </span>
                          </div>
                          <input
                            type="email"
                            defaultValue={email}
                            onChange={(e) => setEmail(e.target.value)}
                            id="email"
                            className="inputField"
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="telefone">Telefone:</label>
                            <span className="span">
                              {errors.telefone !== undefined ? errors.telefone.message : ''}
                            </span>
                          </div>
                          <input
                            className="inputField"
                            id="telefone"
                            type="text"
                            placeholder="(00) 0000-0000"
                            value={phone}
                            onChange={handlePhoneChange}
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="telefone">Whatsapp:</label>
                            <span className="span">
                              {errors.telefone !== undefined ? errors.telefone.message : ''}
                            </span>
                          </div>
                          <input
                            className="inputField"
                            id="telefone"
                            type="text"
                            placeholder="(00) 0000-0000"
                            value={whatsapp}
                            onChange={handleWhatsappChange}
                          />
                        </div>

                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="cep">CEP:</label>
                            <span className="span">
                              {errors.cep !== undefined ? errors.cep.message : ''}
                            </span>
                          </div>
                          <input
                            className="inputField"
                            type="text"
                            placeholder="00000-000"
                            id="cep"
                            value={resaleCep}
                            onChange={handleCepChange}
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="nome">País:</label>
                            <span className="span">
                              {errors.cidade !== undefined ? errors.cidade.message : ''}
                            </span>
                          </div>
                          <div className="select">
                            <select
                              id="standard-select"
                              placeholder="seleciona uma opção"
                              onChange={(e) => {
                                findState(e.target.value);
                              }}
                              value={selectedCountrie}
                              style={{ cursor: 'pointer' }}
                              //
                            >
                              <option disabled value="">
                                Selecione uma opção
                              </option>
                              {countries.map((countri) => (
                                <option key={countri.id} value={countri.id}>
                                  {countri.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="nome">Estado:</label>
                            <span className="span">
                              {errors.cidade !== undefined ? errors.cidade.message : ''}
                            </span>
                          </div>
                          <div className="select">
                            <select
                              id="standard-select"
                              defaultValue=""
                              placeholder="seleciona uma opção"
                              onChange={(e) => {
                                findCities(e.target.value);
                                setResaleState(e.target.value);
                              }}
                            >
                              <option disabled defaultValue>
                                Selecione um país primeiro
                              </option>
                              {states.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="nome">Cidade:</label>
                            <span className="span">
                              {errors.cidade !== undefined ? errors.cidade.message : ''}
                            </span>
                          </div>
                          <div className="select">
                            <select
                              id="standard-select"
                              defaultValue={cityId}
                              placeholder="seleciona uma opção"
                              onChange={(e) => {
                                setCityId(e.target.value);
                                citieFullObject(e.target.value);
                              }}
                            >
                              <option disabled defaultValue>
                                Selecione um país primeiro
                              </option>
                              {cities.map((city) => (
                                <option key={city.id} value={city.id}>
                                  {city.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="cpfcnpj">CPF/CNPJ</label>
                            <span className="span">
                              {errors.cnpj_cpf !== undefined ? errors.cnpj_cpf.message : ''}
                            </span>
                          </div>
                          <input
                            className="inputField"
                            type="text"
                            value={cnpjCpf}
                            onChange={handleInputChange}
                            placeholder="Digite seu CPF/CNPJ"
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="cpfcnpj">IE/RG:</label>
                            <span className="span">
                              {errors.cnpj_cpf !== undefined ? errors.cnpj_cpf.message : ''}
                            </span>
                          </div>
                          <input
                            className="inputField"
                            type="text"
                            value={ieRg}
                            onChange={(e) => setIeRg(e.target.value)}
                            placeholder="IE/RG"
                          />
                        </div>
                        <div />
                      </div>
                    </Tabs.Content>

                    <Tabs.Content className="TabsContent" value="tab3">
                      <div action="test" className="resaleFormGrid">
                        <div
                          style={{ display: 'flex', width: '100%', gap: '0.5rem' }}
                          className="formFields"
                        >
                          <div style={{ width: '60%' }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginLeft: '8px',
                                marginRight: '8px',
                                marginBottom: '5px',
                              }}
                            >
                              <label htmlFor="conta">Conta</label>
                              <span className="span">
                                {errors.contaBancaria !== undefined ? errors.contaBancaria : ''}
                              </span>
                            </div>
                            <input
                              className="inputField"
                              type="text"
                              value={contaBancaria}
                              onChange={(e) => setContaBancaria(e.target.value)}
                              placeholder="Digite sua conta"
                              style={{ width: '100%' }}
                            />
                          </div>
                          <div style={{ width: '40%' }}>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: '8px',
                                marginRight: '8px',
                                marginBottom: '5px',
                                gap: '0.5rem',
                              }}
                            >
                              <label htmlFor="conta">Verificador</label>
                              <Tooltip title="caso houver números após o hifen">
                                <Question size={20} color="#ffffff" weight="fill" />
                              </Tooltip>

                              <span className="span">
                                {errors.contaBancaria !== undefined ? errors.contaBancaria : ''}
                              </span>
                            </div>
                            <input
                              className="inputField"
                              type="text"
                              value={contaDv}
                              onChange={(e) => setContaDv(e.target.value)}
                              placeholder="Digito Verificador"
                              style={{ width: '100%' }}
                            />
                          </div>
                        </div>
                        <div
                          style={{ display: 'flex', width: '100%', gap: '0.5rem' }}
                          className="formFields"
                        >
                          <div style={{ width: '60%' }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginLeft: '8px',
                                marginRight: '8px',
                                marginBottom: '5px',
                              }}
                            >
                              <label htmlFor="conta">Agência</label>
                              <span className="span">
                                {errors.agencia !== undefined ? errors.agencia : ''}
                              </span>
                            </div>
                            <input
                              className="inputField"
                              type="text"
                              value={agencia}
                              onChange={(e) => setAgencia(e.target.value)}
                              placeholder="Digite sua Agência"
                              style={{ width: '100%' }}
                            />
                          </div>
                          <div style={{ width: '40%' }}>
                            <div
                              style={{
                                display: 'flex',
                                marginLeft: '8px',
                                marginRight: '8px',
                                marginBottom: '5px',
                                gap: '0.5rem',
                              }}
                            >
                              <label htmlFor="conta">Verificador</label>
                              <Tooltip title="caso houver números após o hifen">
                                <Question size={20} color="#ffffff" weight="fill" />
                              </Tooltip>
                            </div>
                            <input
                              className="inputField"
                              type="text"
                              value={agenciaDv}
                              onChange={(e) => setAgenciaDv(e.target.value)}
                              placeholder="Digito Verificador"
                              style={{ width: '100%' }}
                            />
                          </div>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <label htmlFor="conta">Banco</label>
                              <SearchInput
                                label="Filtro de Bancos"
                                placeholder="bancos..."
                                value={filterBank}
                                onChange={(search) => setFilterBank(search)}
                              />
                            </div>
                            <span className="span">
                              {errors.banco !== undefined ? errors.banco : ''}
                            </span>
                          </div>
                          <select
                            id="banco"
                            value={banco?.ispb}
                            className="inputField"
                            placeholder="seleciona uma opção"
                            onChange={(e) => {
                              handleChangeBank(e.target.value);
                            }}
                          >
                            <option value="" disabled>
                              Escolha um banco
                            </option>
                            {isFiltred
                              ? filterBankList.map((item, index) => (
                                  <option
                                    key={item.ispb !== '' ? item.ispb : index}
                                    value={item.ispb}
                                  >
                                    {item.name}
                                  </option>
                                ))
                              : bankList.map((item, index) => (
                                  <option
                                    key={item.ispb !== '' ? item.ispb : index}
                                    value={item.ispb}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                          </select>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="conta">Tipo de Conta</label>
                            <span className="span">
                              {errors.tipoConta !== undefined ? errors.tipoConta : ''}
                            </span>
                          </div>
                          <select
                            className="inputField"
                            type="text"
                            value={tipoConta}
                            onChange={(e) => setTipoConta(e.target.value)}
                          >
                            <option>Selecione o tipo de Conta</option>
                            <option key="contaCorrente" value="c">
                              Conta Corrente
                            </option>
                            <option key="contaPoupanca" value="p">
                              Conta Poupança
                            </option>
                          </select>
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="conta">Chave Pix</label>
                            <span className="span">
                              {errors.pix !== undefined ? errors.pix : ''}
                            </span>
                          </div>
                          <input
                            className="inputField"
                            type="text"
                            value={pix}
                            onChange={(e) => setPix(e.target.value)}
                            placeholder="Digite sua chave pix"
                          />
                        </div>
                      </div>
                    </Tabs.Content>
                    <Tabs.Content className="TabsContent" value="tab2">
                      <div action="test" className="resaleFormGrid">
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="login">Login: </label>
                          </div>
                          <input
                            className={
                              errors.nome !== undefined ? 'inputField error' : 'inputField'
                            }
                            type="text"
                            id="nome"
                            onChange={(e) => setLogin(e.target.value)}
                            defaultValue={login}
                          />
                        </div>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                          className="formFields"
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginLeft: '8px',
                              marginRight: '8px',
                              marginBottom: '5px',
                            }}
                          >
                            <label htmlFor="endereco">Senha: </label>
                          </div>
                          <input
                            type="text"
                            defaultValue={password}
                            onChange={(e) => setPassword(e.target.value)}
                            id="endereco"
                            className="inputField"
                          />
                        </div>
                      </div>
                    </Tabs.Content>
                    <div className="resaleButtonsCreateResale">
                      <MDButton
                        className=""
                        color="error"
                        variant="contained"
                        onClick={() => handleNavigationBack()}
                      >
                        Cancelar
                      </MDButton>
                      <button
                        type="submit"
                        color="success"
                        className="customerCreateBtn"
                        style={{ paddingRight: '26px', paddingLeft: '26px' }}
                        onClick={(e) => {
                          e.preventDefault();
                          onSubmit();
                        }}
                      >
                        {isLoading === true ? (
                          <CircleNotch size={18} weight="bold" className="isLoading" />
                        ) : (
                          'Salvar'
                        )}
                      </button>
                    </div>
                  </form>
                </Tabs.Root>
              </MDBox>
            </Card>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}
