/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable */
import axios from 'axios';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'context/auth';
import { baseUrl } from '../../utils/base_url';

import ConfirmModal from './components/ConfirmModal';
import MDButton from '../MDButton';

import './styles.css';

export default function SpecModal() {
  const navigate = useNavigate();
  const { setToken } = useContext(AuthContext);

  const handleExitModSpec = () => {
    axios.post(`${baseUrl}/logout/resale`).then((res) => {
      navigate('/');
      setToken(res.data.access_token);
      navigate(0);
    });
  };

  return (
    <div className="specHeader">
      <span>Você está no modo de visualização da resale</span>
      <ConfirmModal handleExitModSpec={() => handleExitModSpec()} inSpec />
    </div>
  );
}
