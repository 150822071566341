/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-associated-control */
// import MDTypography from 'components/MDTypography';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckCircle, CircleNotch, Trash, WarningOctagon, XCircle } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, useStepContext } from '@mui/material';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import Footer from '../../examples/Footer';

import MDButton from '../../components/MDButton';
import MyHeader from './MyHeader';

import './styles.css';
import { baseUrl } from '../../utils/base_url';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import { changeUpdate } from '../../redux/resaleSlice';
import { changeUser } from '../../redux/userSlice';

const schema = yup.object({
  login: yup.string().required().email(),
});

export default function Logins() {
  const storeUpdate = useSelector((state) => state.resale.updateResale);
  const auth = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { state } = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState(state.login?.login ? state.login.login : '');
  const [password, setPassword] = useState(state.login?.password ? state.login.password : '');

  const [devices, setDevices] = useState([]);
  const [update, setUpdate] = useState(false);

  const allow = user ? 'not-allowed': 'auto'

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  useEffect(() => {
    if (state.login.login) {
      axios
        .get(`${baseUrl}/tb-account/devices/${state.login.external_id}`)
        .then((data) => setDevices(data.data))
        .catch((err) => console.log(''));
    }
  }, [storeUpdate]);

  const handleDeleteDevices = async (id) => {
    await axios
      .delete(`${baseUrl}/tb-account/device/${id}`)
      .then((data) => successNotify(data.data.message));
    dispatch(changeUpdate(!storeUpdate));
  };

  // async function handleCustomer(value) {
  // await axios.get(`${baseUrl}/customer/${value.id}`).then((data) => {
  //   dispatch(changeUser(data.data));
  // });
  // }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const changeLoginData = async (id) => {
    setIsLoading(true);
    await axios
      .patch(`${baseUrl}/tb-account/alter-plan/${id}`, {
        id_contrato: state.login.customer.contractId,
        login_id_ixc: state.login.login_id,
        password: password === state.login.password ? null : password,
      })
      .then((data) => {
        successNotify('Atualizado com sucesso');
        dispatch(changeUser(state.customerId));
        setTimeout(() => {
          setIsLoading(false);
          navigate('/clientes');
        }, 500);
      })
      .catch((error) => {
        setIsLoading(false);
        errorNotify(error.response.data.message);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <MyHeader data={state}>
        <form>
          <div className="loginsBody">
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                color: '#fff',
              }}
            >
              <label htmlFor="usuario" style={{ marginBottom: '8px' }}>
                Usuario
              </label>
              <input
                id="usuario"
                value={user}
                onChange={(e) => setUser(e.target.value)}
                type="text"
                style={{ color: '#bbb',  cursor: allow }}
                disabled={user}
              />

              <label htmlFor="usuario" style={{ marginBottom: '8px', marginTop: '18px' }}>
                Senha
              </label>
              <input value={password} onChange={(e) => setPassword(e.target.value)} type="text" />
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', color: '#fff' }}>
              <label htmlFor="usuario" style={{ marginBottom: '8px' }}>
                Dispositivos
              </label>
              {devices.length !== 0 && devices !== undefined ? (
                devices.map((device) => (
                  <Card
                    key={device.id}
                    style={{
                      backgroundColor: '#181B26',
                      marginBottom: '15px',
                      marginTop: '12px',
                      color: '#fff',
                      padding: '12px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      fontSize: '16px',
                      alignItems: 'center',
                    }}
                    className="card"
                  >
                    <div style={{ display: 'flex', gap: '6px' }}>
                      <p>IP: </p>
                      <td>{device.ip}</td>
                    </div>
                    <div style={{ display: 'flex', gap: '6px' }}>
                      <p>Nome: </p>
                      <td>{device.name}</td>
                    </div>
                    <div style={{ display: 'flex', gap: '6px' }}>
                      <p>Tipo: </p>
                      <td>{device.type}</td>
                    </div>
                    <div style={{ display: 'flex', gap: '6px' }}>
                      <MDButton
                        variant="outlined"
                        color="error"
                        iconOnly
                        onClick={() => {
                          handleDeleteDevices(device.id);
                        }}
                      >
                        <Trash color="red" weight="bold" />
                      </MDButton>
                    </div>
                  </Card>
                ))
              ) : (
                <div className="emptyIcon">
                  <WarningOctagon size={180} weight="bold" color="#344767" />
                  <span style={{ color: '#344767' }}>
                    Este Login não possui dispositivos vinculados
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="profileBtns">
            <MDButton variant="gradient" color="error">
              <Link
                to="/cliente"
                style={{
                  color: '#fff',
                }}
                state={state}
              >
                Cancelar
              </Link>
            </MDButton>
            <MDButton
              variant="gradient"
              color="success"
              onClick={() => changeLoginData(state.login.product_id)}
            >
              {isLoading === true ? (
                <CircleNotch size={18} weight="bold" className="isLoading" />
              ) : (
                'Salvar'
              )}
            </MDButton>
          </div>
        </form>
      </MyHeader>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}
