/* eslint-disable react/prop-types */
import * as Switch from '@radix-ui/react-switch';
import { useState } from 'react';
import './styles.css';

export default function MySwitch({ update, checked }) {
  const [status, setStatus] = useState(checked);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Switch.Root
        checked={status}
        onClick={() => {
          update();
          setStatus(!status);
        }}
        className="SwitchRoot"
        id="airplane-mode"
      >
        <Switch.Thumb className="SwitchThumb" />
      </Switch.Root>
    </div>
  );
}
