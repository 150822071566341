/* eslint-disable react/self-closing-comp */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components

// Material Dashboard 2 React examples
import { Card } from '@mui/material';
// import { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircle, Circle, CircleNotch, XCircle } from 'phosphor-react';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { changeUser } from 'redux/userSlice';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { changeUpdate } from '../../redux/resaleSlice';
import MDTypography from '../../components/MDTypography';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import Footer from '../../examples/Footer';
import MDBox from '../../components/MDBox';

import './style.css';
import { baseUrl } from '../../utils/base_url';
import MDButton from '../../components/MDButton';

const schema = yup.object({
  nome: yup.string().required('Campo obrigatório!').min(5, 'Nome deve ter no minimo 5 caracteres'),
  endereco: yup.string().required('Campo obrigatório!'),
  bairro: yup.string().required('Campo obrigatório!'),
  numero: yup.string().required('Campo obrigatório!'),
  complemento: yup.string(),
  email: yup.string().required('Campo obrigatório!').email('Necessario inserir um E-mail válido'),
  telefone: yup.string().required('Campo obrigatório!'),
  cep: yup.string().required('Campo obrigatório!'),
  cnpj_cpf: yup.string().required('Campo obrigatório!'),
  tipo_pessoa: yup.string().required('Campo obrigatório!'),
  localidade: yup.string().required('Campo obrigatório!'),
});

export default function CreateResaleDocs() {
  const testUpdate = useSelector((state) => state.resale.updateResale);
  const role = useSelector((state) => state.auth.user.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [cnpjCpf, setCnpjCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [resaleCep, setResaleCep] = useState('');
  const [tipoLocalidade, setTipoLocalidade] = useState('');
  const [tipoPessoa, setTipoPessoa] = useState('');

  const formatCpfCnpj = (inputValue) => {
    let formattedValue = inputValue.replace(/\D/g, ''); // remove todos os caracteres que não são números

    if (formattedValue.length <= 11) {
      // CPF
      formattedValue = formattedValue.replace(/(\d{3})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/(\d{3})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      // CNPJ
      formattedValue = formattedValue.replace(/^(\d{2})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
      formattedValue = formattedValue.replace(/\.(\d{3})(\d)/, '.$1/$2');
      formattedValue = formattedValue.replace(/(\d{4})(\d)/, '$1-$2');
    }

    return formattedValue;
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatCpfCnpj(inputValue);
    setCnpjCpf(formattedValue);
  };

  const handlePhoneChange = (event) => {
    let formattedPhone = event.target.value.replace(/\D/g, '');
    formattedPhone = formattedPhone.replace(/^(\d{2})(\d)/g, '($1) $2');
    formattedPhone = formattedPhone.replace(/(\d)(\d{4})$/, '$1-$2');
    setPhone(formattedPhone);
  };

  const handleCepChange = (event) => {
    let formattedCep = event.target.value.replace(/\D/g, '');
    formattedCep = formattedCep.replace(/^(\d{5})(\d)/g, '$1-$2');
    setResaleCep(formattedCep);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    await axios.get(`${baseUrl}/location/countries`).then((data) => setCountries(data.data));
  }, []);

  const handleNavigationBack = () => {
    navigate('/revendas');
  };

  const findState = async (id) => {
    setStates([]);
    await axios.get(`${baseUrl}/location/states/${id}`).then((data) => setStates(data.data));
  };

  const findCities = async (id) => {
    await axios.get(`${baseUrl}/location/cities/${id}`).then((data) => setCities(data.data));
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };
  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    await axios
      .post(`${baseUrl}/resale`, {
        name: data.nome,
        cityId: data.cidade,
        address: data.endereco,
        address_number: Number(data.numero),
        email: data.email,
        neighborhood: data.bairro,
        telefone_celular: data.telefone,
        cnpj_cpf: data.cnpj_cpf,
        localidade: data.localidade,
        tipo_pessoa: data.tipo_pessoa,
        cep: data.cep,
      })
      .then((thisData) => {
        successNotify(thisData.data.message);
        setIsLoading(false);
        navigate('/revendas');
      })
      .catch((error) => {
        if (
          error.response.data.message[0] ===
          'address_number must be a number conforming to the specified constraints'
        ) {
          errorNotify('Numero Invalido!');
        } else {
          errorNotify(error.response.data.message[0]);
        }
        setIsLoading(false);
      });
    dispatch(changeUpdate(!testUpdate));
  };

  return (
    <>
      <div>
        <SnackbarProvider
          iconVariant={{
            success: (
              <CheckCircle
                size={16}
                color="#ffffff"
                weight="fill"
                style={{ marginRight: '10px' }}
              />
            ),
            error: (
              <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
            ),
          }}
          dense
          autoHideDuration={1500}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
      </div>
      <DashboardLayout>
        <MDBox mt={6} mb={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                mb={4}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
                display={{ sm: 'flex' }}
                alignItems={{ sm: 'center' }}
                justifyContent={{ sm: 'space-between' }}
              >
                <MDTypography variant="h6" color="white">
                  Revenda
                </MDTypography>
              </MDBox>
              <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <h1 style={{ fontSize: 24, marginBottom: 12, color: '#fff' }}>Dados</h1>
                  <form onSubmit={handleSubmit(onSubmit)} className="">
                    <div action="test" className="resaleFormGrid">
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="nome">Nome: </label>
                          <span className="span">
                            {errors.nome !== undefined ? errors.nome.message : ''}
                          </span>
                        </div>
                        <input
                          className={errors.nome !== undefined ? 'inputField error' : 'inputField'}
                          type="text"
                          id="nome"
                          defaultValue=""
                          {...register('nome')}
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="endereco">Endereço: </label>
                          <span className="span">
                            {errors.endereco !== undefined ? errors.endereco.message : ''}
                          </span>
                        </div>
                        <input
                          {...register('endereco')}
                          type="text"
                          defaultValue=""
                          id="endereco"
                          className="inputField"
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="bairro">Bairro:</label>
                          <span className="span">
                            {errors.bairro !== undefined ? errors.bairro.message : ''}
                          </span>
                        </div>
                        <input
                          {...register('bairro')}
                          type="text"
                          id="bairro"
                          defaultValue=""
                          className="inputField"
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="numero">Numero:</label>
                          <span className="span">
                            {errors.numero !== undefined ? errors.numero.message : ''}
                          </span>
                        </div>
                        <input
                          {...register('numero')}
                          type="tel"
                          defaultValue=""
                          id="numero"
                          className="inputField"
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="email">Email: </label>
                          <span className="span">
                            {errors.email !== undefined ? errors.email.message : ''}
                          </span>
                        </div>
                        <input
                          {...register('email')}
                          type="email"
                          defaultValue=""
                          id="email"
                          className="inputField"
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="telefone">Telefone:</label>
                          <span className="span">
                            {errors.telefone !== undefined ? errors.telefone.message : ''}
                          </span>
                        </div>
                        <input
                          className="inputField"
                          id="telefone"
                          {...register('telefone')}
                          type="text"
                          placeholder="(00) 0000-0000"
                          value={phone}
                          onChange={handlePhoneChange}
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="cpfcnpj">Cpf/cnpj:</label>
                          <span className="span">
                            {errors.cnpj_cpf !== undefined ? errors.cnpj_cpf.message : ''}
                          </span>
                        </div>
                        <input
                          className="inputField"
                          {...register('cnpj_cpf')}
                          type="text"
                          value={cnpjCpf}
                          onChange={handleInputChange}
                          placeholder="Digite seu CPF ou CNPJ"
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="cep">CEP:</label>
                          <span className="span">
                            {errors.cep !== undefined ? errors.cep.message : ''}
                          </span>
                        </div>
                        <input
                          className="inputField"
                          type="text"
                          placeholder="00000-000"
                          id="cep"
                          value={resaleCep}
                          {...register('cep')}
                          onChange={handleCepChange}
                        />
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="nome">País:</label>
                          <span className="span">
                            {errors.cidade !== undefined ? errors.cidade.message : ''}
                          </span>
                        </div>
                        <div className="select">
                          <select
                            id="standard-select"
                            placeholder="seleciona uma opção"
                            onChange={(e) => findState(e.target.value)}
                            style={{ cursor: 'pointer' }}
                          >
                            <option selected disabled value="">
                              Selecione uma opção
                            </option>
                            {countries.map((countri) => (
                              <option key={countri.id} value={countri.id}>
                                {countri.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="nome">Estado:</label>
                          <span className="span">
                            {errors.cidade !== undefined ? errors.cidade.message : ''}
                          </span>
                        </div>
                        <div className="select">
                          <select
                            id="standard-select"
                            defaultValue=""
                            placeholder="seleciona uma opção"
                            onChange={(e) => findCities(e.target.value)}
                          >
                            <option disabled selected>
                              Selecione um país primeiro
                            </option>
                            {states.map((state) => (
                              <option key={state.id} value={state.id}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="nome">Cidade:</label>
                          <span className="span">
                            {errors.cidade !== undefined ? errors.cidade.message : ''}
                          </span>
                        </div>
                        <div className="select">
                          <select
                            id="standard-select"
                            defaultValue=""
                            placeholder="seleciona uma opção"
                            {...register('cidade')}
                          >
                            <option disabled selected>
                              Selecione um país primeiro
                            </option>
                            {cities.map((city) => (
                              <option key={city.id} value={city.id}>
                                {city.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="nome">Tipo de Localidade: </label>
                        </div>
                        <div className="select">
                          <select
                            id="standard-select"
                            onChange={(e) => setTipoLocalidade(e.target.value)}
                            {...register('localidade')}
                          >
                            <option selected disabled value="">Selecione uma opção</option>
                            <option value="U">Urbana</option>
                            <option value="R">Rural</option>
                          </select>
                        </div>
                      </div>
                      <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="formFields"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            marginRight: '8px',
                            marginBottom: '5px',
                          }}
                        >
                          <label htmlFor="nome">Tipo pessoa: </label>
                        </div>
                        <div className="select">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 4,
                              marginLeft: 10,
                            }}
                          >
                            <label
                              htmlFor=""
                              style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                            >
                              <input
                                type="checkbox"
                                value="F"
                                checked={tipoPessoa === 'F'}
                                onChange={(e) => {
                                  setTipoPessoa(e.target.value);
                                }}
                              />
                              Física
                            </label>
                            <label
                              htmlFor=""
                              style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                            >
                              <input
                                type="checkbox"
                                value="J"
                                checked={tipoPessoa === 'J'}
                                onChange={(e) => {
                                  setTipoPessoa(e.target.value);
                                }}
                              />
                              Jurídica
                            </label>
                          </div>
                        </div>
                      </div>
                      <div />
                    </div>
                    <div className="resaleButtonsCreateResale">
                      <MDButton
                        className=""
                        color="error"
                        variant="contained"
                        onClick={() => handleNavigationBack()}
                      >
                        Cancelar
                      </MDButton>
                      <Link to="/createResale/docs" state={{}}>
                        <MDButton
                          type="button"
                          color="success"
                          className="customerCreateBtn"
                          style={{ paddingRight: '26px', paddingLeft: '26px' }}
                        >
                          Avançar
                        </MDButton>
                      </Link>
                    </div>
                  </form>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}
