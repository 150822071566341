/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Modal } from '@mui/material';
import axios from 'axios';
import { Buildings, CheckCircle, User, X, XCircle } from 'phosphor-react';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import MDTypography from '../MDTypography';
import { AuthContext } from '../../context/auth';
import { baseUrl } from '../../utils/base_url';
import { changeUpdate } from '../../redux/resaleSlice';
import MDButton from '../MDButton';

import './resaleInfo.css';

export function ResaleInfos({ data, title, variant, color, iconVariant, className, onClose }) {
  const [resale, setResale] = useState();

  const user = useSelector((state) => state.auth.user);

  const [email, setEmail] = useState(data.email);
  const [password, setPassword] = useState('');
  const [name, setName] = useState(data.username === undefined ? data.full_name : data.username);
  const [permission, setPermission] = useState(data.type === undefined ? data.role : data.type);

  const [open, setOpen] = useState(false);

  // useEffect(() => {}, []);

  const handleOpen = async () => {
    setOpen(true);
    await axios
      .get(`${baseUrl}/resale/unique`)
      .then((resaleData) => {
        setResale(resaleData.data);
      })
      .catch((error) => console.log(error));
  };
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <>
      <MDButton
        onClick={() => {
          handleOpen();
        }}
        // variant="text"
        className={className}
        variant={variant}
        color={color}
        size="small"
        sx={{ gap: '16px' }}
      >
        {iconVariant === 'user' ? <User /> : <Buildings />}

        {title}
      </MDButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      >
        <form action="" className="resaleInfoForm">
          <SnackbarProvider
            iconVariant={{
              success: (
                <CheckCircle
                  size={16}
                  color="#ffffff"
                  weight="fill"
                  style={{ marginRight: '10px' }}
                />
              ),
              error: (
                <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
              ),
            }}
            dense
            autoHideDuration={2500}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
          <div className="formHeader">
            <div className="resaleFormLogo">
              <Buildings size={28} color="#ffffff" />
            </div>
            <X
              weight="bold"
              size={24}
              style={{
                right: '0',
                position: 'absolute',
                marginRight: '8px',
                marginTop: '8px',
                cursor: 'pointer',
              }}
              color="#fff"
              onClick={() => {
                handleClose();
              }}
            />
          </div>
          <h2 className="operatorModalTitle" style={{ marginTop: '0px' }}>
            Revenda
          </h2>
          <div action="test" className="fieldsBox">
            <div className="resaleFormFields">
              <label htmlFor="nome">Razão:</label>
              <p className="resaleInfoSpan">{resale === undefined ? '' : resale.name}</p>
            </div>
            <div className="resaleFormFields">
              <label htmlFor="nome">Endereço:</label>
              <p className="resaleInfoSpan">
                {resale === undefined
                  ? ''
                  : `${resale.address}, ${resale.address_number}, ${resale.neighborhood}`}
              </p>
            </div>
            <div className="resaleFormFields">
              <label htmlFor="nome">CNPJ/CPF:</label>
              <p className="resaleInfoSpan">{resale === undefined ? '' : resale.cnpj_cpf}</p>
            </div>
            <div className="resaleFormFields">
              <label htmlFor="nome">Email:</label>
              <p className="resaleInfoSpan">{resale === undefined ? '' : resale.email}</p>
            </div>
            <div className="resaleFormFields">
              <label htmlFor="nome">Telefone:</label>
              <p className="resaleInfoSpan">
                {resale === undefined ? '' : resale.telefone_celular}
              </p>
            </div>
            <div className="resaleFormFields">
              <label htmlFor="nome">Status Contrato:</label>
              <p className="resaleInfoSpan">
                {resale !== undefined && resale.status === true ? 'Ativo' : 'Desativado'}
              </p>
            </div>
          </div>
          {/* <div className="buttonInfoResale" style={{ marginTop: '20px' }}>
            <MDButton
              onClick={() => {
                handleClose();
              }}
              color="error"
              variant="contained"
            >
              Fechar
            </MDButton>
          </div> */}
        </form>
      </Modal>
    </>
  );
}
