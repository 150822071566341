/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import './styles.css';
// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components
// import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React base styles

// Images
import { ArrowLeft, X } from 'phosphor-react';
import { Link } from 'react-router-dom';
import breakpoints from '../../../assets/theme-dark/base/breakpoints';
import MDBox from '../../../components/MDBox';

function MyHeader({ children, data }) {
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="8.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient } }) =>
            `${linearGradient(rgba('#FA7209', 1), rgba('#FA8C01', 1))}, url()`,
          backgroundSize: 'cover',
          backgroundPosition: '50%',
          overflow: 'hidden',
        }}
      />
      <Card
        sx={{
          position: 'relative',
          mt: -8,
          mx: 3,
          py: 5,
          px: 2,
          pt: 1,
        }}
      >
        <Grid container alignItems="center" display="flex" justifyContent="space-between">
          <Grid item display="flex" alignItems="center">
            <button type="button" className="myBtn">
              <Link
                to="/cliente"
                style={{
                  color: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                state={data}
              >
                <ArrowLeft size={24} />
              </Link>
            </button>
          </Grid>
          <Grid item>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Link to="/clientes" style={{ color: '#fff' }} state={data}>
                <X color="#FFF" />
              </Link>
            </div>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
MyHeader.defaultProps = {
  children: '',
};

// Typechecking props for the MyHeader
MyHeader.propTypes = {
  children: PropTypes.node,
};

export default MyHeader;
