import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'dashboard',
  initialState: {},
  reducers: {
    changeDashboard(state, { payload }) {
      return { ...state, dashboard: payload };
    },
    cleanDashboard(state) {
      return { ...state, dashboard: {} };
    },
  },
});

export const { changeDashboard, cleanDashboard } = slice.actions;

export const selectDashboard = (state) => state.dashboard;

export default slice.reducer;
//
