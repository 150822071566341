/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Modal } from '@mui/material';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { CircleNotch, Trash } from 'phosphor-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeUser } from '../../redux/userSlice';
import { baseUrl } from '../../utils/base_url';
import { changeUpdate } from '../../redux/resaleSlice';
import MDButton from '../MDButton';

import './deleteLoginModal.css';

/* eslint-disable jsx-a11y/label-has-associated-control */
export default function ConfirmDeleteLoginModal({ tbAccountId, product }) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const storeUpdate = useSelector((state) => state.resale.updateResale);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateCustomer = async () => {
    await axios
      .get(`${baseUrl}/customer/${tbAccountId.customerId}`)
      .then((data) => dispatch(changeUser(data.data)));
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };
  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    if (product) {
      await axios
        .delete(`${baseUrl}/tb-account/delete-product/${id}`)
        .then((thisData) => {
          successNotify(thisData.data.message);
          handleUpdateCustomer();
          handleClose();
        })
        .catch((error) => {
          console.log(error);
          // errorNotify(error);
        });
    } else {
      await axios
        .delete(`${baseUrl}/tb-account/delete-product/${id}`)
        .then((thisData) => {
          successNotify(thisData.data.message);
          handleUpdateCustomer();
          handleClose();
        })
        .catch((error) => {
          console.log(error);
          // errorNotify(error);
        });
    }
    setIsLoading(false);
  };

  return (
    <>
      <MDButton size="medium" variant="outlined" color="error" onClick={handleOpen} iconOnly>
        <Trash weight="fill" />
      </MDButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form action="" className="deleteform" style={{ width: '300px' }}>
          <h2 className="modalTitle" style={{ marginTop: '20px', marginBottom: '-10px' }}>
            Atenção
          </h2>
          <div>
            <div
              style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}
              className="formFields"
            >
              <h1 style={{ color: '#ccc', fontSize: '18px' }}>
                Deseja deletar este {product ? 'produto' : 'login'}
              </h1>
            </div>
          </div>
          <div className="confirmDeleteBtn">
            <MDButton className="formBtn" color="error" variant="contained" onClick={handleClose}>
              Cancelar
            </MDButton>

            <MDButton
              className="formBtn"
              color="success"
              variant="contained"
              onClick={() => {
                handleDelete(tbAccountId.accountId);
              }}
            >
              {isLoading === true ? (
                <CircleNotch size={16} weight="bold" className="isLoading" />
              ) : (
                'Confirmar'
              )}
            </MDButton>
          </div>
        </form>
      </Modal>
    </>
  );
}
