import { useEffect, useState } from 'react';
import { Card, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { CheckCircle, XCircle } from 'phosphor-react';
import axios from 'axios';
import BasicLayout from '../authentication/components/BasicLayout';
import MDBox from '../../components/MDBox';
import logoImg from '../../assets/images/tubaplayLogo.svg';
import singBackImg from '../../assets/images/BackTubaPlay.png';
import MDInput from '../../components/MDInput';
import MDButton from '../../components/MDButton';
import { baseUrl } from '../../utils/base_url';

export default function ForgotPassword() {
  const [validateToken, setValidateToken] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [payload, setPayload] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const successNotify = (status) => {
    enqueueSnackbar(status, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };
  useEffect(() => {
    axios
      .post(`${baseUrl}/validate-token`, {
        token: window.location.pathname.split('/')[2],
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 404 || res.data.status === 401) {
          setPayload(res.data);
        }
        if (res.data.payload.token === window.location.pathname.split('/')[2]) {
          setValidateToken(true);
          setPayload(res.data.payload);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  function handlePasswordForgot() {
    if (password === confirmPassword) {
      if (password.length >= 8) {
        setLoading(true);
        axios
          .put(`${baseUrl}/operators/forgot-password/${payload.operator.email}`, {
            forgotPassword: true,
            token: payload.token,
            email: payload.operator.email,
            password: confirmPassword,
          })
          .then((res) => {
            if (res.data.status === 200) {
              successNotify('Senha alterada com sucesso');
              setLoading(false);
              setTimeout(() => {
                navigate('/sign-in');
              }, 1000);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        errorNotify('A senha deve conter no mínimo 8 caracteres');
      }
    } else {
      errorNotify('As senhas não coincidem');
    }
  }

  return (
    <BasicLayout image={singBackImg}>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <Card>
        {loading ? (
          <div className="loading">
            <CircularProgress color="warning" size={60} />
          </div>
        ) : (
          <MDBox pt={4} pb={3} px={3}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#ccc',
              }}
            >
              <img src={logoImg} alt="" style={{ width: '180px', marginBottom: '22px' }} />
              {validateToken ? (
                <span style={{ fontSize: '16px', marginBottom: '-10px' }}>
                  Digite sua nova senha
                </span>
              ) : null}
            </div>
            <MDBox
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="dark"
              mx={2}
              p={0}
              mt={2}
              mb={2}
              textAlign="center"
            />
            {validateToken ? (
              <form action="forgotPassowrd" onSubmit={handlePasswordForgot}>
                <MDBox mb={2} style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                  <MDInput
                    type="password"
                    label="Senha"
                    fullWidth
                    error={false}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <MDInput
                    type="password"
                    label="Confirme a senha"
                    fullWidth
                    error={false}
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="warning"
                    fullWidth
                    onClick={() => {
                      handlePasswordForgot();
                    }}
                  >
                    Enviar
                  </MDButton>
                </MDBox>
              </form>
            ) : (
              <h1 style={{ color: 'white', fontSize: '1rem', textAlign: 'center' }}>
                {payload.status === 401 ? 'Pagina Expirada! ' : null}
                {payload.status === 404 ? 'Pagina Não Encontrada 404!' : null}
              </h1>
            )}
          </MDBox>
        )}
      </Card>
    </BasicLayout>
  );
}
