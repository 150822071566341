/* eslint-disable no-unused-vars */
import axios from 'axios';
import { createContext, useEffect, useMemo, useState } from 'react';
import { decodeToken, isExpired } from 'react-jwt';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { CheckCircle, XCircle } from 'phosphor-react';
import { changeDashboard } from '../redux/dashboardSlice';
import { baseUrl } from '../utils/base_url';
import {
  setIsLoggedState,
  setTokenState,
  setUserState,
} from '../features/authentication/authSlice';

export const AuthContext = createContext();

// eslint-disable-next-line react/prop-types
export default function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const [signInResponse, setSignInResponse] = useState();

  function clearToken() {
    localStorage.removeItem('token');
    dispatch(setIsLoggedState(false));
    dispatch(setTokenState(null));
    dispatch(setUserState(null));
  }

  function setToken(token) {
    dispatch(setTokenState(token));
    dispatch(setIsLoggedState(true));
    const getDecodedToken = decodeToken(token);
    dispatch(setUserState(getDecodedToken));

    // eslint-disable-next-line dot-notation
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (isExpired(token)) {
      clearToken();
    } else if (token === null) {
      clearToken();
    } else {
      setToken(token);
    }
  }, []);

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  async function signIn(username, password) {
    const url = `${baseUrl}/login`;
    await axios
      .post(url, { username, password })
      .then((res) => {
        localStorage.setItem('token', res.data.access_token);
        setToken(res.data.access_token);
      })
      .catch((error) => {
        errorNotify(error.response.data);
        console.log(error);
      });
  }

  const value = useMemo(() => ({ signIn, clearToken, setToken }), []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
