/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { Modal } from '@mui/material';
import { CheckCircle, CircleNotch, TrashSimple, XCircle } from 'phosphor-react';
import { useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { baseUrl } from '../../utils/base_url';
import MDButton from '../MDButton';

/* eslint-disable jsx-a11y/label-has-associated-control */
export default function DeleteProgrammerGroupChannelModal({
  data,
  programadora = false,
  grupoDeCanais = false,
  canais = false,
  handleChannelListChange,
  handleListGroupsChange,
  handleListProgrammsChange,
}) {
  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setIsLoading(true);
    setIsLoading(false);
    setOpen(false);
  };

  const handleDelete = async () => {
    if (canais) {
      axios.delete(`${baseUrl}/channels/${data.id}`).then((response) => {
        handleChannelListChange({
          message: response.data.message,
          success: true,
        });
        setTimeout(() => {
          handleClose();
        }, 500);
      });
    } else if (grupoDeCanais) {
      axios.delete(`${baseUrl}/channel-groups/${data.id}`).then((response) => {
        if (response.status === 200) {
          handleListGroupsChange({
            message: response.data,
            success: true,
          });
        } else {
          handleListGroupsChange({
            message: response.response.data.message,
            success: false,
          });
        }
        setTimeout(() => {
          handleClose();
        }, 500);
      });
    } else if (programadora) {
      axios.delete(`${baseUrl}/tv-programmer/${data.id}`).then((response) => {
        if (response.status === 200) {
          handleListProgrammsChange({
            message: response.data,
            success: true,
          });
        } else {
          handleListProgrammsChange({
            message: response.response.data.message,
            success: false,
          });
        }
        setTimeout(() => {
          handleClose();
        }, 500);
      });
    }
    // await axios.delete(`http://localhost:3001/channel/${id}`);
    // dispatch(changeUpdate(!storeUpdate));
  };
  return (
    <>
      <MDButton
        sx={{ ml: 0 }}
        size="medium"
        color="error"
        variant="gradient"
        onClick={handleOpen}
        iconOnly
      >
        <TrashSimple weight="fill" size={16} />
      </MDButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form action="" className="deleteform" style={{ width: '300px' }}>
          <h2 className="modalTitle" style={{ marginTop: '20px', marginBottom: '-10px' }}>
            Atenção
          </h2>
          <div>
            <div
              style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}
              className="formFields"
            >
              <h1 style={{ color: '#ccc', fontSize: '18px' }}>
                Deseja deletar {data.name}{' '}
                {grupoDeCanais ? `Você ira gerar ${data.Channel.length} grupos` : null}{' '}
              </h1>
            </div>
          </div>
          <div className="confirmDeleteBtn">
            <MDButton className="formBtn" color="error" variant="contained" onClick={handleClose}>
              Cancelar
            </MDButton>

            <MDButton
              className="formBtn"
              color="success"
              variant="contained"
              onClick={() => {
                handleDelete();
              }}
            >
              {isLoading === true ? (
                <CircleNotch size={18} weight="bold" className="isLoading" />
              ) : (
                'Confirmar'
              )}
            </MDButton>
          </div>
        </form>
      </Modal>
    </>
  );
}
