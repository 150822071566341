import axios from 'axios';
import { useEffect, useState } from 'react';

import { Card, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MDButton from '../../components/MDButton';
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import MyDataTable from '../../components/MyDataTable/index.jsx';
// import ResaleModal from '../../components/ResaleModal/index.jsx';
// import Footer from '../../examples/Footer';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import { baseUrl } from '../../utils/base_url';
// Data

function ResalePage() {
  const testUpdate = useSelector((state) => state.resale.updateResale);
  const [resales, setResales] = useState();
  const [typeSearch, setTypeSearch] = useState('nome');
  const [isFiltered, setIsFiltered] = useState(false);
  const [search, setSearch] = useState('');
  const [query, setQuery] = useState('');
  const role = useSelector((state) => state.auth.user.role);

  function handleSearchEvent(e) {
    if (e === 'Enter') {
      // axios.get(`${baseUrl}/resale/${query}`).then((data) => {
      //   if (!data.data.length <= 0) {
      //     setResales(data.data);
      //   }
      // });
      setQuery(search);
      setIsFiltered(true);
    }
  }

  useEffect(() => {
    axios.get(`${baseUrl}/resale`).then((data) => setResales(data.data));
  }, [testUpdate]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {role !== 'admin' ? (
        <MDButton color="success" variant="gradient" disabled={role !== 'admin'}>
          Nova Revenda
        </MDButton>
      ) : (
        <Link to="/createResale" style={{ color: '#fff' }}>
          <MDButton color="success" variant="gradient" disabled={role !== 'admin'}>
            Nova Revenda
          </MDButton>
        </Link>
      )}
      <MDBox mt={6} mb={3}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="info"
              display={{ sm: 'flex' }}
              alignItems={{ sm: 'center' }}
              justifyContent={{ sm: 'space-between' }}
            >
              <MDTypography variant="h6" color="white">
                Revenda
              </MDTypography>
              <div className="select" style={{ width: 'auto', marginRight: '30px' }}>
                <input
                  id="myInput"
                  className="myInput"
                  placeholder={`${typeSearch}...`}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={(e) => handleSearchEvent(e.key)}
                />
                <select
                  name="tipo"
                  id="tipo"
                  style={{
                    border: '1px solid #fff',
                    color: '#fff',
                    marginLeft: '5px',
                  }}
                  onChange={(e) => {
                    setTypeSearch(e.target.value);
                  }}
                >
                  <option value="nome">Nome</option>
                  <option value="cidade">Cidade</option>
                </select>
              </div>
            </MDBox>
            <MDBox pt={3}>
              <MyDataTable
                resales={resales}
                filtered={isFiltered}
                type={typeSearch}
                query={query}
              />
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default ResalePage;
