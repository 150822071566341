/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
// import axios from 'axios';
// import { useEffect, useState } from 'react';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';

import { CheckCircle, XCircle } from 'phosphor-react';
import { changeUpdate } from '../../redux/resaleSlice';
import { EditOperatorModal } from '../EditOperatorModal/index.jsx';
import DataTable from '../../examples/Tables/DataTable';
import MDTypography from '../MDTypography';
import MDAvatar from '../MDAvatar';
import MDBox from '../MDBox';
import MySwitch from '../MySwitch/MySwitch.jsx';
import { baseUrl } from '../../utils/base_url';

export default function MyUserTable({ users, filtered, query, type }) {
  const storeUpdate = useSelector((state) => state.resale.updateResale);
  const role = useSelector((state) => state.auth.user.role);
  const dispatch = useDispatch();
  const myRows = [];
  const spec = useSelector((state) => state.auth.user.spec);

  const successNotify = (status, name) => {
    const thisMessage =
      status === true
        ? `Operador ${name} ativado com sucesso`
        : `Operador ${name} desativado com sucesso`;
    enqueueSnackbar(thisMessage, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  async function handleUpdateUser(email, status) {
    await axios
      .patch(`${baseUrl}/operators/${email}`, {
        status: !status,
      })
      .then((data) => {
        // console.log(data);
        successNotify(data.data.payload.status, data.data.payload.full_name);
      })
      .catch((error) => console.log(error));
    dispatch(changeUpdate(!storeUpdate));
  }
  if (users === undefined) {
    console.log();
  } else if (filtered === true && query.length > 0) {
    if (type === 'usuario') {
      const filteredUser = Object.values(users).filter((user) =>
        user.full_name.toLowerCase().includes(query.toLowerCase())
      );
      filteredUser.forEach((user) => {
        myRows.push({
          full_name: (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              <MDAvatar size="sm" />
              <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                  {user.full_name}
                </MDTypography>
                <MDTypography variant="caption">{user.email}</MDTypography>
              </MDBox>
            </MDBox>
          ),
          revenda: (
            <MDBox lineHeight={1} textAlign="left">
              <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {user.resale.name}
              </MDTypography>
            </MDBox>
          ),
          function: (
            <MDBox lineHeight={1} textAlign="left">
              <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {user.type}
              </MDTypography>
            </MDBox>
          ),
          status: (
            <MDBox ml={-1}>
              <MySwitch
                checked={user.status}
                update={() => {
                  handleUpdateUser(user.email, user.status);
                }}
              />
            </MDBox>
          ),
          employed: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.createdAt.toString().slice(0, -14).split('-').reverse().join('/')}
            </MDTypography>
          ),
          action: (
            <EditOperatorModal
              className="openModalBtn"
              data={user}
              color="info"
              title="Editar"
              iconVariant="user"
              variant="gradient"
            />
          ),
        });
      });
    } else if (type === 'permissão') {
      const filteredUser = Object.values(users).filter((user) =>
        user.type.toLowerCase().includes(query.toLowerCase())
      );
      filteredUser.forEach((user) => {
        myRows.push({
          full_name: (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              <MDAvatar size="sm" />
              <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                  {user.full_name}
                </MDTypography>
                <MDTypography variant="caption">{user.email}</MDTypography>
              </MDBox>
            </MDBox>
          ),
          revenda: (
            <MDBox lineHeight={1} textAlign="left">
              <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {user.resale.name}
              </MDTypography>
            </MDBox>
          ),
          function: (
            <MDBox lineHeight={1} textAlign="left">
              <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {user.type}
              </MDTypography>
            </MDBox>
          ),
          status: (
            <MDBox ml={-1}>
              <MySwitch
                checked={user.status}
                update={() => {
                  handleUpdateUser(user.email, user.status);
                }}
              />
            </MDBox>
          ),
          employed: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.createdAt.toString().slice(0, -14).split('-').reverse().join('/')}
            </MDTypography>
          ),
          action: (
            <EditOperatorModal
              className="openModalBtn"
              data={user}
              color="info"
              title="Editar"
              iconVariant="user"
              variant="gradient"
            />
          ),
        });
      });
    } else {
      const filteredUser = Object.values(users).filter((user) =>
        user.resale.name.toLowerCase().includes(query.toLowerCase())
      );
      filteredUser.forEach((user) => {
        myRows.push({
          full_name: (
            <MDBox display="flex" lineHeight={1}>
              <MDAvatar size="sm" />
              <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                  {user.full_name}
                </MDTypography>
                <MDTypography variant="caption">{user.email}</MDTypography>
              </MDBox>
            </MDBox>
          ),
          revenda: (
            <MDBox lineHeight={1} textAlign="left">
              <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {user.resale.name}
              </MDTypography>
            </MDBox>
          ),
          function: (
            <MDBox lineHeight={1} textAlign="left">
              <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {user.type}
              </MDTypography>
            </MDBox>
          ),
          status: (
            <MDBox ml={-1}>
              <MySwitch
                checked={user.status}
                update={() => {
                  handleUpdateUser(user.email, user.status);
                }}
              />
            </MDBox>
          ),
          employed: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {user.createdAt.toString().slice(0, -14).split('-').reverse().join('/')}
            </MDTypography>
          ),
          action: (
            <EditOperatorModal
              className="openModalBtn"
              data={user}
              color="info"
              title="Editar"
              iconVariant="user"
              variant="gradient"
            />
          ),
        });
      });
    }
  } else {
    Object.values(users).forEach((user) => {
      myRows.push({
        full_name: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDAvatar size="sm" />
            <MDBox ml={2} lineHeight={1}>
              <MDTypography display="block" variant="button" fontWeight="medium">
                {user.full_name}
              </MDTypography>
              <MDTypography variant="caption">{user.email}</MDTypography>
            </MDBox>
          </MDBox>
        ),
        revenda: (
          <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
              {user.resale.name}
            </MDTypography>
          </MDBox>
        ),
        function: (
          <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
              {user.type}
            </MDTypography>
          </MDBox>
        ),
        status: (
          <MDBox ml={-1}>
            {role === 'operator' ? null : (
              <MySwitch
                checked={user.status}
                update={() => {
                  handleUpdateUser(user.email, user.status);
                }}
              />
            )}
          </MDBox>
        ),
        employed: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {user.createdAt.toString().slice(0, -14).split('-').reverse().join('/')}
          </MDTypography>
        ),
        action: (
          <EditOperatorModal
            className="openModalBtn"
            data={user}
            color="info"
            iconVariant="edit"
            title="Editar"
            variant="gradient"
          />
        ),
      });
    });
  }
  function myTableData() {
    if (role === 'operatorTubaron') {
      return {
        columns: [
          { Header: 'Usuario', accessor: 'full_name', width: '20%', align: 'left' },
          { Header: 'Permissão', accessor: 'function', align: 'left' },
          { Header: 'Criado em', accessor: 'employed', align: 'left' },
        ],
        rows: myRows,
      };
    }
    if (role === 'admin') {
      return {
        columns: [
          { Header: 'Usuario', accessor: 'full_name', width: '20%', align: 'left' },
          { Header: 'Permissão', accessor: 'function', align: 'left' },
          { Header: 'Criado em', accessor: 'employed', align: 'left' },
          { Header: 'Status', accessor: 'status', align: 'left' },
          { accessor: 'action', align: 'left' },
        ],
        rows: myRows,
      };
    }
    if (role === 'operator') {
      return {
        columns: [
          { Header: 'Usuario', accessor: 'full_name', width: '20%', align: 'left' },
          { Header: 'Permissão', accessor: 'function', align: 'left' },
          { Header: 'Criado em', accessor: 'employed', align: 'left' },
        ],
        rows: myRows,
      };
    }
    return {
      columns: [
        { Header: 'Usuario', accessor: 'full_name', width: '20%', align: 'left' },
        { Header: 'Permissão', accessor: 'function', align: 'left' },
        { Header: 'Criado em', accessor: 'employed', align: 'left' },
        { Header: 'Status', accessor: 'status', align: 'left' },
        { accessor: 'action', align: 'left' },
      ],
      rows: myRows,
    };
  }

  return (
    <>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <DataTable
        table={myTableData()}
        isSorted={false}
        entriesPerPage={false}
        noEndBorder
        pagination={{ variant: 'gradient', color: 'warning' }}
      />
    </>
  );
}
