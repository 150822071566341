/* eslint-disable no-extend-native */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import { Modal } from '@mui/material';
import { Buildings, CheckCircle, Pencil, PencilSimple, XCircle } from 'phosphor-react';
import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// // import { setModaState } from '../../features/common/modalSlice';
// import {
//   // setIsSearch,
//   // setIsUpdateTariff,
//   setTariffFormState,
// } from '../../features/tariff/tariffFormSlice';
import axios from 'axios';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { baseUrl } from '../../utils/base_url';
import {
  useFindAllTariffsQuery,
  useUpdateTariffMutation,
} from '../../features/tariff/tariffService';
import MDButton from '../MDButton';

import './edittariffStyle.css';

export default function EditTariffModal({ data, updateNotify }) {
  const [planName, setPlanName] = useState(data.name);
  const [planIdIxc, setPlanIdIxc] = useState(data.plan_id_ixc);
  const [salePrice, setSalePrice] = useState(
    `${data.sale.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`
  );

  const [groups, setGroups] = useState([]);
  const [planGroups, setPlanGroups] = useState(data.channels_group);
  const [categoryOptions, setCategoryOptions] = useState([
    { id: 'L', name: 'Lifeline' },
    { id: 'SB', name: 'Sub Básico' },
    { id: 'B', name: 'Básico' },
    { id: 'E', name: 'Estendido' },
  ]);
  const [planCategory, setPlanCategory] = useState(data.category);
  // const tariffForm = useSelector((state) => state.tariffForm.tariffForm);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const [updateTariff] = useUpdateTariffMutation();
  const { refetch } = useFindAllTariffsQuery();

  const handleClose = () => {
    setOpen(false);
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const validator = (state) => {
    errorNotify(state.message);
    document.getElementById(state.id).classList.replace('inputField', 'inputFieldError');
  };

  function handleUpdateTariff(thisName, thisPlanIdIxc, thisSalePrice) {
    if (thisName?.length < 3) {
      validator({ message: 'Nome deve conter ao menos 3 caracteres', id: 'planName' });
    } else {
      document.getElementById('planName').classList.replace('inputFieldError', 'inputFieldSuccess');
    }

    if (!thisPlanIdIxc > 0) {
      validator({ message: 'id do IXC deve ser entre 1 e 9999', id: 'planIXCId' });
    } else {
      document
        .getElementById('planIXCId')
        .classList.replace('inputFieldError', 'inputFieldSuccess');
    }

    if (!thisSalePrice > 0) {
      validator({ message: 'Valor deve ser maior que 0', id: 'planPrice' });
    } else {
      document
        .getElementById('planPrice')
        .classList.replace('inputFieldError', 'inputFieldSuccess');
    }

    if (thisName?.length < 3 || !thisPlanIdIxc > 0 || !thisSalePrice > 0) {
      return;
    }
    const { id } = data;
    updateTariff({
      id,
      name: thisName,
      plan_id_ixc: thisPlanIdIxc,
      sale: Number(thisSalePrice.slice(2, -3)),
      channels_group: planGroups,
      category: planCategory,
    })
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          updateNotify(res.message);
        } else {
          updateNotify(res.message);
          setTimeout(() => [handleClose()], 500);
        }
      })
      .catch((err) => {
        errorNotify(err.data.message);
      });
  }

  const handleSalePriceChange = (event) => {
    const rawValue = event.target.value;
    const onlyNumbers = rawValue.replace(/\D/g, ''); // remove tudo que não for número
    const formattedValue = Number(onlyNumbers) / 100; // divide por 100 para transformar em número decimal
    setSalePrice(formattedValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
  };

  function handleAddChannelToGroup(items) {
    const newList = groups.filter((item) => item.id !== items.id);
    setGroups(newList);
    setPlanGroups((state) => [...state, items]);
  }

  function handleRemoveChannelToGroup(items) {
    const newList = planGroups.filter((item) => item.id !== items.id);
    setPlanGroups(newList);
    setGroups((state) => [...state, items]);
  }

  useEffect(async () => {
    axios.get(`${baseUrl}/channel-groups`).then((res) => {
      if (planGroups?.length > 0) {
        const ListGroupsOutPlan = res.data.payload.filter((item) =>
          planGroups.every((element) => element.id !== item.id)
        );
        setGroups(ListGroupsOutPlan);
      } else {
        setGroups(res.data.payload);
      }
    });
  }, [open === true]);

  return (
    <>
      <MDButton
        style={{ display: 'flex', gap: 10 }}
        size="small"
        variant="contained"
        color="info"
        onClick={handleOpen}
      >
        <Pencil color="#fff" weight="fill" size={14} />
        Editar
      </MDButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form action="" className="operatorForm">
          <div className="formHeader">
            <div className="formLogo">
              <Buildings size={28} color="#ffffff" />
            </div>
          </div>
          <h2 className="modalTitle">{`EDITAR ${data.name}`}</h2>
          <div className="tariffFormGrid">
            <SnackbarProvider
              iconVariant={{
                success: (
                  <CheckCircle
                    size={16}
                    color="#ffffff"
                    weight="fill"
                    style={{ marginRight: '10px' }}
                  />
                ),
                error: (
                  <XCircle
                    size={16}
                    color="#ffffff"
                    weight="fill"
                    style={{ marginRight: '10px' }}
                  />
                ),
              }}
              dense
              autoHideDuration={2500}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="nome">Nome: </label>
              <input
                value={planName}
                type="text"
                id="planName"
                className="inputField"
                onChange={
                  (e) => setPlanName(e.target.value)
                  // dispatch(setTariffFormState({ ...tariffForm, name: e.target.value }))
                }
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="planIdIxc">Plano Id Ixc:</label>
              <input
                value={planIdIxc}
                id="planIXCId"
                className="inputField"
                onChange={
                  (e) => setPlanIdIxc(e.target.value)
                  // dispatch(setTariffFormState({ ...tariffForm, plan_id_ixc: e.target.value }))
                }
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="price">Preço: </label>
              <input
                value={`${salePrice}`}
                id="planPrice"
                placeholder="R$"
                className="inputField"
                onChange={handleSalePriceChange}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="groupSelect">Categoria:</label>
              <select
                id="groupSelect"
                value={planCategory}
                className="inputField"
                placeholder="seleciona uma opção"
                defaultChecked
                onChange={(e) => {
                  setPlanCategory(e.target.value);
                }}
              >
                <option value="">Escolha uma Categoria</option>
                {categoryOptions?.map((categ) => (
                  <option key={categ.id} value={categ.id}>
                    {categ.name}
                  </option>
                ))}
              </select>
            </div>
            <div
              style={{ display: 'flex', marginTop: '1rem', flexDirection: 'column' }}
              className="formFields"
            >
              <label>Grupos Disponiveis: {groups.length} </label>
              <div className="channels">
                {groups?.map((item) => (
                  <button
                    key={item.id}
                    type="button"
                    value={item.id}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      display: 'flex',
                      gap: '1rem',
                    }}
                    onClick={() => handleAddChannelToGroup(item)}
                  >
                    <span style={{ color: 'white' }}>{item.name}</span>
                  </button>
                ))}
              </div>
            </div>
            <div
              style={{ display: 'flex', marginTop: '1rem', flexDirection: 'column' }}
              className="formFields"
              id="groupChannels"
            >
              <label>Grupos que pertecem ao plano: {planGroups?.length} </label>
              <div className="channels">
                {planGroups?.map((item) => (
                  <button
                    key={item.id}
                    type="button"
                    value={item.id}
                    style={{
                      background: 'transparent',
                      border: 'none',
                      display: 'flex',
                      gap: '1rem',
                    }}
                    onClick={() => handleRemoveChannelToGroup(item)}
                  >
                    <span style={{ color: 'white' }}>{item.name}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="buttonsCreateTariff">
            <MDButton
              onClick={() => setOpen(false)}
              className="formBtn"
              color="error"
              variant="contained"
            >
              Cancelar
            </MDButton>

            <MDButton
              onClick={() => {
                handleUpdateTariff(planName, planIdIxc, salePrice);
              }}
              className="formBtn"
              color="success"
              variant="contained"
            >
              Atualizar
            </MDButton>
          </div>
        </form>
      </Modal>
    </>
  );
}
