/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { Modal } from '@mui/material';
import { CircleNotch, TrashSimple } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MDButton from '../../../components/MDButton';

export function NotificationResale({resale, modalOpen }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    navigate('/revendas');
  };

  useEffect(() => {
    if (modalOpen === true) {
      handleOpen();
    }
  }, [modalOpen]);
  return (
    <>
      <MDButton
        sx={{ ml: 0 }}
        size="medium"
        color="error"
        variant="gradient"
        onClick={handleOpen}
        iconOnly
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form action="" className="deleteform" style={{ width: '300px' }}>
          <h2 className="modalTitle" style={{ marginTop: '20px', marginBottom: '-10px' }}>
            Atenção
          </h2>
          <div>
            <div
              style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}
              className="formFields"
            >
              <h1 style={{ color: '#ccc', fontSize: '18px' }}>Dados Logins Resale</h1>
              <h2 style={{ color: '#ccc', fontSize: '18px' }}>Login: {resale} </h2>
              <h2 style={{ color: '#ccc', fontSize: '18px' }}>Senha: 12345@Trocar </h2>
            </div>
          </div>
          <div className="confirmDeleteBtn">
            <MDButton className="formBtn" color="error" variant="contained" onClick={handleClose}>
              fechar
            </MDButton>
          </div>
        </form>
      </Modal>
    </>
  );
}
