/* eslint-disable no-unused-vars */
import { Card, Grid, Tooltip } from '@mui/material';
// import CustomerTableByResale from 'components/CustomerTableByResale';
import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { CheckCircle, XCircle } from 'phosphor-react';
import { useLocation } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { ConstructionOutlined } from '@mui/icons-material';
import MDBox from '../../components/MDBox';
import MDButton from '../../components/MDButton';
import { baseUrl } from '../../utils/base_url';
import MDTypography from '../../components/MDTypography';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

import './styles.css';

export default function Parameters() {
  const role = useSelector((state) => state.auth.user.role);
  const [parameters, setParameters] = useState([]);
  const [report, setReport] = useState(parameters?.report ? parameters.report : '');
  const [tax, setTax] = useState(parameters?.tax ? parameters.tax : '');
  const [operation, setOperation] = useState(parameters?.operation ? parameters.operation : '');
  const [infomir, setInfomir] = useState(parameters?.infomir ? parameters.infomir : '');
  const [commision, setCommision] = useState(parameters?.commision ? parameters.commision : '');
  const [cotacao, setCotacao] = useState('');
  const [valorEmReais, setValorEmReais] = useState(
    parameters?.infomir ? parameters.infomir * cotacao : ''
  );
  const [update, setupdate] = useReducer((x) => x + 1, 0);

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  function adjustValues(value) {
    if (value < 1) {
      return (value / 10).toFixed(2);
    }
    if (value >= 1) {
      return (value / 10).toFixed(2);
    }
    if (value >= 10) {
      return (value / 1).toFixed(2);
    }
    return value;
  }

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  useEffect(() => {
    axios.get(`${baseUrl}/parameters`).then((data) => {
      setParameters(data?.data[0]);
      setReport(data.data[0]?.report);
      setTax(data.data[0]?.tax);
      setCommision(data.data[0]?.commision_seller);
      setInfomir(data.data[0]?.infomir);
      setOperation(data.data[0]?.operational);
    });
  }, [update]);

  const validator = (state) => {
    errorNotify(state.message);
    document.getElementById(state.id).classList.replace('inputField', 'inputFieldError');
  };

  const reset = () => {
    document.getElementById('report').classList.replace('inputFieldSuccess', 'inputField');
    document.getElementById('tax').classList.replace('inputFieldSuccess', 'inputField');
    document.getElementById('operation').classList.replace('inputFieldSuccess', 'inputField');
    document.getElementById('infomir').classList.replace('inputFieldSuccess', 'inputField');
    document.getElementById('commision').classList.replace('inputFieldSuccess', 'inputField');
  };

  const handleSubmit = (e) => {
    const data = {
      report: typeof report === 'string' ? Number(report?.split('%')[0]) : report,
      tax: typeof tax === 'string' ? Number(tax?.split('%')[0]) : tax,
      operational: typeof operation === 'string' ? Number(operation?.split('%')[0]) : operation,
      infomir: typeof infomir === 'string' ? Number(infomir.split(' ')[1]) : infomir,
      commision_seller:
        typeof commision === 'string' ? Number(commision?.split('%')[0]) : commision,
    };
    if (parameters.length === 0) {
      axios.post(`${baseUrl}/parameters`, data).then((res) => {
        successNotify('Parâmetros criados com sucesso!');
        reset();
        setupdate();
      });
    } else {
      data.id = parameters.id;
      axios.patch(`${baseUrl}/parameters/${data.id}`, data).then((res) => {
        successNotify('Parâmetros editados com sucesso!');
        reset();
        setupdate();
      });
    }
  };

  useEffect(() => {
    axios.get(`https://economia.awesomeapi.com.br/json/last/USD-BRL`).then((res) => {
      setCotacao(res.data.USDBRL.bid);
      if (infomir < 10) {
        setValorEmReais(res.data.USDBRL.bid * (infomir / 10));
      } else {
        setValorEmReais(res.data.USDBRL.bid * infomir);
      }
    });
  }, [infomir]);

  function handleInformirValue(value) {
    const temp = value.split('$')[1];
    setInfomir(value);
    if (temp < 10) {
      setValorEmReais((temp * cotacao) / 10);
    } else {
      setValorEmReais(temp * cotacao);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {role !== 'operatorTubaron' && (
        <div className="btnContainer">
          <SnackbarProvider
            iconVariant={{
              success: (
                <CheckCircle
                  size={16}
                  color="#ffffff"
                  weight="fill"
                  style={{ marginRight: '10px' }}
                />
              ),
              error: (
                <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
              ),
            }}
            dense
            autoHideDuration={2500}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
        </div>
      )}

      <MDBox mt={6} mb={3}>
        <Grid item xs={12}>
          <Card className="cardParameters">
            <MDBox
              mx={1}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="info"
              display={{ sm: 'flex' }}
              alignItems={{ sm: 'center' }}
              justifyContent={{ sm: 'space-between' }}
            >
              <MDTypography variant="h6" color="white">
              Parâmetros
              </MDTypography>
            </MDBox>
            <MDBox pt={-3} pb={2} px={2}>
              <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                <form action="" className="F">
                  <div className="parametersContainer">
                    <div className="parametersFormGrid">
                      <div style={{ display: 'flex', flexDirection: 'column' }} className="">
                        <label htmlFor="report" className="parametersFormLabel">
                          {' '}
                          Report:
                          <InputMask
                            mask="**.*%"
                            maskChar=""
                            value={adjustValues(report)}
                            id="report"
                            className="inputField"
                            onChange={(e) => setReport(e.target.value)}
                          />
                        </label>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }} className="">
                        <label htmlFor="tax" className="parametersFormLabel">
                          {' '}
                          Imposto:
                          <InputMask
                            mask="**.*%"
                            maskChar=""
                            value={adjustValues(tax)}
                            id="tax"
                            className="inputField"
                            onChange={(e) => setTax(e.target.value)}
                          />
                        </label>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }} className="">
                        <label htmlFor="tax" className="parametersFormLabel">
                          {' '}
                          Custo operacional:
                          <InputMask
                            mask="**.*%"
                            maskChar=""
                            value={adjustValues(operation)}
                            id="operation"
                            className="inputField"
                            onChange={(e) => {
                              setOperation(e.target.value);
                            }}
                          />
                        </label>
                      </div>
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <label htmlFor="tax" className="parametersFormLabel">
                          {' '}
                          Infomir:
                          <InputMask
                            mask="$ **.**"
                            maskChar=""
                            value={infomir < 10 ? infomir / 10 : infomir}
                            id="infomir"
                            className="inputField"
                            onChange={(e) => handleInformirValue(e.target.value)}
                          />
                        </label>
                        <label htmlFor="tax" className="parametersFormLabel">
                          {' '}
                          Cotação:
                          <InputMask
                            value={valorEmReais}
                            mask="R$ **.**"
                            maskChar=""
                            disabled
                            className="inputField"
                          />
                        </label>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }} className="">
                        <label htmlFor="tax" className="parametersFormLabel">
                          {' '}
                          Comissão Vendedor:
                          <InputMask
                            mask="**.*%"
                            maskChar=""
                            value={adjustValues(commision)}
                            id="commision"
                            className="inputField"
                            onChange={(e) => setCommision(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="buttonsCreateTariff">
                      <MDButton
                        onClick={(e) => handleSubmit(e)}
                        className="formBtn"
                        color="info"
                        variant="contained"
                      >
                        Editar
                      </MDButton>
                    </div>
                  </div>
                </form>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
