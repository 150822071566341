/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import { Card, Grid } from '@mui/material';
// import CustomerTableByResale from 'components/CustomerTableByResale';
import { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { CheckCircle, Handshake, Prohibit, XCircle } from 'phosphor-react';
import MDButton from '../../components/MDButton';
import { baseUrl } from '../../utils/base_url';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';

import './styles.css';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { FilterGeneric } from 'layouts/RelatoriesFinancial/FilterGeneric';

export default function RelatoriesClients() {
  const [relatorie, setRelatorie] = useState({ message: '', payload: [] });
  const [gerado, setGerado] = useState(true);
  const [relatorieType, setRelatorieType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const role = useSelector((state) => state.auth.user.role);

  async function handleRelatorie(route) {
    setIsLoading(true);
    setGerado(false);
    await axios
      .get(`${baseUrl}/relatories/${route}`)
      .then((data) => {
        setRelatorie(data.data);
        setGerado(true);
        console.log(data.data.message)
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid item xs={12}>
          <Card>
            <div>
              <SnackbarProvider
                iconVariant={{
                  success: (
                    <CheckCircle
                      size={16}
                      color="#ffffff"
                      weight="fill"
                      style={{ marginRight: '10px' }}
                    />
                  ),
                  error: (
                    <XCircle
                      size={16}
                      color="#ffffff"
                      weight="fill"
                      style={{ marginRight: '10px' }}
                    />
                  ),
                }}
                dense
                autoHideDuration={2500}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              />
              <MDBox
                sx={{ mx: 3, mb: -1, position: 'relative' }}
                mx={2}
                mt={-5}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
                display={{ sm: 'flex' }}
                alignItems={{ sm: 'center' }}
                justifyContent={{ sm: 'space-between' }}
              >
                <MDTypography variant="h6" color="white">
                  Relatório Clientes
                </MDTypography>
              </MDBox>
              <MDBox pt={-3} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" pt={1} p={0} m={0}>
                  <MDBox pt={-3} pb={2} px={2}>
                    <MDBox component="ul" display="flex" flexDirection="column" ml={-1} p={0} m={0}>
                      <div className="relatorieButtons">
                        <MDButton
                          sx={{ height: '40px' }}
                          size="small"
                          color="secondary"
                          onClick={() => {
                            handleRelatorie('blockeds');
                            setRelatorieType('blockeds');
                          }}
                        >
                          <p
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 3,
                            }}
                          >
                            <Prohibit size={18} color="#fff" weight="fill" />
                            Clientes Bloqueados
                          </p>
                        </MDButton>
                        <MDButton
                          sx={{ height: '40px' }}
                          size="small"
                          color="secondary"
                          onClick={() => {
                            setRelatorieType('signature');
                          }}
                        >
                          <p
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 3,
                            }}
                          >
                            <Handshake size={18} color="#fff" weight="fill" />
                            Aguardando assinatura
                          </p>
                        </MDButton>
                      </div>
                      {relatorieType === 'signature' ? (
                        <FilterGeneric filterByResale filterByCity route={'signature'} />
                      ) : null}
                      {/* {relatorieType === 'blockeds' ? <ReportFilter /> : null} */}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </div>
          </Card>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
