/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable*/
// @mui material components
import './profile.css';

// Material Dashboard 2 React example components
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Overview page components
// import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import { Link, useNavigate } from 'react-router-dom';
// import LoginsList from "examples/Lists/LoginsList";
import { Card, Tooltip } from '@mui/material';
import axios from 'axios';
import {
  CheckCircle,
  CircleNotch,
  Pencil,
  Question,
  Trash,
  WarningOctagon,
  XCircle,
} from 'phosphor-react';
import { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import * as Switch from '@radix-ui/react-switch';
import { changeUser } from '../../redux/userSlice';
import ConfirmDeleteLoginModal from '../../components/ConfirmDeleteLoginModal/index.jsx';
import ProductModal from '../../components/ProductModal/index.jsx';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import { changeUpdate } from '../../redux/resaleSlice';
import MDButton from '../../components/MDButton';
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import TbAccountModal from '../../components/TbAccountModal/index.jsx';
import Footer from '../../examples/Footer';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import { baseUrl } from '../../utils/base_url';
import Header from './components/Header';
import CustomerBillModal from 'components/CustomerBillModal';
import LoginForChtech from 'components/LoginForChtech';

export default function Overview() {
  const user = useSelector((state) => state.user.user);
  const auth = useSelector((state) => state.auth.user);
  const storeUpdate = useSelector((state) => state.resale.updateResale);
  const role = useSelector((state) => state.auth.user.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [contractValidate, setContractValidate] = useState(true);
  const [razao, setRazao] = useState(user === undefined ? '' : user.razao);
  const [email, setEmail] = useState(user === undefined ? '' : user.email);
  const [celular, setCelular] = useState(user === undefined ? '' : user.telefone_celular);
  const [endereco, setEndereco] = useState(user === undefined ? '' : user.endereco);
  const [bairro, setBairro] = useState(user === undefined ? '' : user.bairro);
  const [numero, setNumero] = useState(user === undefined ? '' : user.numero);
  const [complemento, setComplemento] = useState(user === undefined ? '' : user.complemento);
  const [cep, setCep] = useState(user === undefined ? '' : user.cep);
  const [cpfCnpj, setCpfCnpj] = useState(user === undefined ? '' : user.cnpj_cpf);
  const [tipoPessoa, setTipoPessoa] = useState(user === undefined ? '' : user.tipo_pessoa);
  const [tipoLocalidade, setTipoLocalidade] = useState(
    user === undefined ? '' : user.tipo_localidade
  );
  const [ativo, setAtivo] = useState(user === undefined ? '' : user.ativo);

  const [update, setUpdate] = useReducer((x) => x + 1, 0);
  const [logins, setLogin] = useState(user === undefined ? '' : user.tb_account);

  const [isChanged, setIsChanged] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedState, setSelectedState] = useState(user === undefined ? '' : user.city.stateId);
  const [selectedCity, setSelectedCity] = useState(user === undefined ? '' : user.cityId);
  const [selectedCountrie, setSelectedCountrie] = useState(
    user === undefined ? '' : user.city.State.countryId
  );
  const [active, setActive] = useState(user === undefined ? '' : user.ativo);
  const [products, setProducts] = useState(
    user === undefined ? '' : user.tb_account[0]?.tariff_plan.name
  );

  console.log(products)

  const [disableProduct, setDesableProduct] = useState(user.tb_account.length !== 0 ? false : true);

  const [disableLogin, setDisableLogin] = useState(
    !user.tb_account[0]?.login === null || contractValidate ? true : false
  );

  // TODO: CHUMBAR O ID DA RESALE CHTECH
  const userIsCHTECH = user.resale.id == 'd1aa388c-6ab0-4fad-843e-ce25b0e5cca6';

  useEffect(async () => {
    await axios.get(`${baseUrl}/location/countries`).then((data) => setCountries(data.data));

    await axios
      .get(`${baseUrl}/location/states/${selectedCountrie}`)
      .then((data) => setStates(data.data));

    await axios
      .get(`${baseUrl}/location/cities/${selectedState}`)
      .then((data) => setCities(data.data));
  }, [storeUpdate]);

  useEffect(async () => {
    if (user.status_contrato != 'A') {
      await axios.get(`${baseUrl}/tb-account/check-contract/${user.id}`).then((data) => {
        if (data.data === true) {
          setContractValidate(true);
        } else {
          setContractValidate(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    axios.get(`${baseUrl}/customer/${user.id}`).then((data) => {
      if (data.data.payload.tb_account[0]) {
        setProducts(data.data.payload.tb_account[0].tariff_plan.name);
      }
    });
  }, [update]);

  const formatCpfCnpj = (inputValue) => {
    let formattedValue = inputValue.replace(/\D/g, ''); // remove todos os caracteres que não são números

    if (formattedValue.length <= 11) {
      // CPF
      formattedValue = formattedValue.replace(/(\d{3})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/(\d{3})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      // CNPJ
      formattedValue = formattedValue.replace(/^(\d{2})(\d)/, '$1.$2');
      formattedValue = formattedValue.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
      formattedValue = formattedValue.replace(/\.(\d{3})(\d)/, '.$1/$2');
      formattedValue = formattedValue.replace(/(\d{4})(\d)/, '$1-$2');
    }

    return formattedValue;
  };

  const editLogin = (login) => {
    return navigate('/logins', {
      state: { login },
    });
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatCpfCnpj(inputValue);
    setCpfCnpj(formattedValue);
  };

  const handlePhoneChange = (event) => {
    let formattedPhone = event.target.value.replace(/\D/g, '');
    formattedPhone = formattedPhone.replace(/^(\d{2})(\d)/g, '($1) $2');
    formattedPhone = formattedPhone.replace(/(\d)(\d{4})$/, '$1-$2');
    setCelular(formattedPhone);
  };

  const handleCepChange = (event) => {
    let formattedCep = event.target.value.replace(/\D/g, '');
    formattedCep = formattedCep.replace(/^(\d{5})(\d)/g, '$1-$2');
    setCep(formattedCep);
  };

  const findState = async (id) => {
    setSelectedCountrie(id);
    if (id) {
      await axios.get(`${baseUrl}/location/states/${id}`).then((data) => {
        setStates(data.data);
      });
    }
  };

  const findCities = async (id) => {
    setSelectedState(id);
    await axios.get(`${baseUrl}/location/cities/${id}`).then((data) => {
      setCities(data.data);
      setSelectedCity(data.data[0].id);
    });
  };

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };
  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  const handleProductAdd = (payload) => {
    setUpdate();
  };

  const handleLoginStatusChange = async (id, status, accountId) => {
    await axios
      .put(`${baseUrl}/tb-account/${id}`, {
        status,
        accountId,
      })
      .then((data) => {
        dispatch(changeUser(data.data.thisResponse.customer));
        successNotify(data.data.message);
      })
      .catch((error) => errorNotify(error.response.data.message));
  };

  const handleUpdateCustomer = async (id) => {
    setIsLoading(true);
    await axios
      .patch(`${baseUrl}/customer/infos/${id}`, {
        razao,
        email,
        ativo,
        telefone_celular: celular,
        cnpj_cpf: cpfCnpj,
        cidade: selectedCity,
        complemento,
        endereco,
        bairro,
        numero: Number(numero),
        cep,
        id,
        tipo_pessoa: tipoPessoa,
        tipo_localidade: tipoLocalidade,
      })
      .then((data) => {
        successNotify(data.data.message);
        setIsLoading(false);
      })
      .catch((error) => {
        errorNotify(error.response.data.message.split('<')[0]);
        setIsLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={3500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <Header name={user === undefined ? '' : user.razao} id="title">
        <div className="profileBody">
          <div className="profileInfoCard" sx={{ height: '100%', width: '500px' }}>
            <div display="flex" className="profileInfoHeader">
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Informações Pessoais
              </MDTypography>
              {!userIsCHTECH && (
                <MDBox ml={1}>
                  <CustomerBillModal
                    iconVariant="bill"
                    btnText="Faturas"
                    variant="medium"
                    customer={user}
                  />
                </MDBox>
              )}
            </div>
            <MDBox p={2}>
              <form className="personalInformation">
                <div className="field">
                  <span>Nome:</span>
                  <input
                    type="text"
                    value={razao}
                    className="profileInputField"
                    onChange={(e) => setRazao(e.target.value)}
                  />
                </div>
                <div className="field">
                  <span>Email:</span>
                  <input
                    type="text"
                    value={email}
                    className="profileInputField"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="field">
                  <span>Celular:</span>
                  <input
                    type="text"
                    placeholder="(00) 00000-0000"
                    value={celular}
                    className="profileInputField"
                    onChange={(e) => handlePhoneChange(e)}
                  />
                </div>
                <div className="field" style={{}}>
                  <span>País:</span>
                  <div className="profileSelect">
                    <select
                      id="standard-select"
                      value={selectedCountrie}
                      placeholder="seleciona uma opção"
                      onChange={(e) => {
                        findState(e.target.value);
                      }}
                    >
                      <option disabled>Selecione uma opção</option>
                      {countries.map((countri) => (
                        <option key={countri.id} value={countri.id}>
                          {countri.name}
                        </option>
                      ))}
                    </select>
                    <span style={{ fontSize: '12px', color: '#f01202' }} />
                  </div>
                </div>
                <div className="field">
                  <span htmlFor="nome">Estado:</span>
                  <div className="profileSelect">
                    <select
                      id="standard-select"
                      value={selectedState}
                      placeholder="seleciona uma opção"
                      onChange={(e) => {
                        findCities(e.target.value);
                      }}
                    >
                      <option disabled>Selecione um país primeiro</option>
                      {states.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                    <span style={{ fontSize: '12px', color: '#f01202' }} />
                  </div>
                </div>
                <div className="field">
                  <span htmlFor="nome">Cidade:</span>
                  <div className="profileSelect">
                    <select
                      id="standard-select"
                      value={selectedCity}
                      placeholder="seleciona uma opção"
                      onChange={(e) => {
                        setSelectedCity(e.target.value);
                      }}
                    >
                      <option disabled>Selecione um país primeiro</option>
                      {cities.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="field">
                  <span>Endereço:</span>
                  <input
                    type="text"
                    value={endereco}
                    className="profileInputField"
                    onChange={(e) => setEndereco(e.target.value)}
                  />
                </div>
                <div className="field">
                  <span>Bairro:</span>
                  <input
                    type="text"
                    value={bairro}
                    className="profileInputField"
                    onChange={(e) => setBairro(e.target.value)}
                  />
                </div>
                <div className="field">
                  <span>Número:</span>
                  <input
                    type="text"
                    value={numero}
                    className="profileInputField"
                    onChange={(e) => setNumero(e.target.value)}
                  />
                </div>
                <div className="field">
                  <span>Complemento:</span>
                  <input
                    type="text"
                    value={complemento}
                    className="profileInputField"
                    onChange={(e) => setComplemento(e.target.value)}
                  />
                </div>
                <div className="field">
                  <span>CEP:</span>
                  <input
                    type="text"
                    placeholder="00000-000"
                    value={cep}
                    className="profileInputField"
                    onChange={(e) => handleCepChange(e)}
                  />
                </div>
                <div className="field">
                  <span>CPF/CNPJ:</span>
                  <input
                    type="text"
                    placeholder="CPF ou CNPJ"
                    value={cpfCnpj}
                    className="profileInputField"
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="field">
                  <span>Tipo-localidade:</span>
                  <div className="profileSelect">
                    <select
                      id="standard-select"
                      value={tipoLocalidade}
                      onChange={(e) => setTipoLocalidade(e.target.value)}
                    >
                      <option value="U">Urbana</option>
                      <option value="R">Rural</option>
                    </select>
                  </div>
                </div>
              </form>
            </MDBox>
          </div>
          <div className="separator" />
          <div className="profileInfoCard loginsCard" sx={{ height: '100%' }}>
            <div>
              <MDBox pt={1} px={2} display="flex" justifyContent="space-between">
                <div
                  style={{
                    display: 'flex',
                    gap: '0.5rem',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <MDTypography
                    variant="h5"
                    fontWeight="medium"
                    textTransform="capitalize"
                    mb={1.3}
                  >
                    Produtos
                  </MDTypography>
                  <Tooltip title="https://crm.tubaron.net/central_assinante_web/login">
                    <Question size={24} weight="fill" color="white" />
                  </Tooltip>
                </div>
                {userIsCHTECH && !products && (
                  <LoginForChtech
                    customerContractId={user === undefined ? '' : user.contractId}
                    customerId={user === undefined ? '' : user.id}
                  />
                )}
                {auth.role !== 'admin' && auth.role !== 'operator' && !userIsCHTECH ? (
                  <ProductModal
                    size="small"
                    iconVariant="add"
                    btnText="Adicionar Produto"
                    title="Adicionar Produto"
                    canAddProduct={true}
                    data={user}
                    handleProductAdd={handleProductAdd}
                  />
                ) : null}
              </MDBox>
              <MDBox>
                {user !== undefined && user.tb_account.length >= 1 ? (
                  <div className="productContainer">
                    <span>{products}</span>
                  </div>
                ) : (
                  <div className="emptyIcon">
                    <WarningOctagon size={200} weight="bold" />
                    <span>Este cliente não possui produtos</span>
                  </div>
                )}
              </MDBox>
            </div>
            <div>
              <MDBox
                pt={1}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="1rem"
              >
                <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize" mb={1.3}>
                  Logins
                </MDTypography>
                {auth.role !== 'admin' &&
                auth.role !== 'operatorTubaron' &&
                products &&
                !userIsCHTECH ? (
                  <TbAccountModal
                    customerContractId={user === undefined ? '' : user.contractId}
                    customerId={user === undefined ? '' : user.id}
                    canCreateLogin={disableLogin}
                    disabled={disableLogin}
                    contractValidation={contractValidate}
                  />
                ) : null}
              </MDBox>
              <MDBox>
                {user !== undefined && user.tb_account.length >= 1 ? (
                  user.tb_account.map((login) => (
                    <div style={{ cursor: 'pointer' }} className="card" key={login.customerId}>
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                            width: '100%',
                            color: '#fff',
                          }}
                        >
                          <MDTypography variant="body2" textTransform="uppercase" fontWeight="bold">
                            Usuario:
                          </MDTypography>
                          <MDTypography variant="button">
                            {login.login ? login.login : 'Não Possui Login'}
                          </MDTypography>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                            width: '100%',
                            color: '#fff',
                          }}
                        >
                          <MDTypography variant="body2" fontWeight="bold" textTransform="uppercase">
                            Plano:
                          </MDTypography>
                          <MDTypography variant="button">{login.tariff_plan.name}</MDTypography>
                          <MDTypography variant="body2" fontWeight="bold" textTransform="uppercase">
                            Preço:
                          </MDTypography>
                          <MDTypography variant="button">
                            {Number(login.tariff_plan.sale?.toFixed(2))?.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                              minimumFractionDigits: 2,
                            })}
                          </MDTypography>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: '15px',
                        }}
                      >
                        {role === 'admin' ? (
                          <>
                            <Switch.Root
                              checked={login.status && login.login}
                              className="SwitchRoot"
                              id="airplane-mode"
                              disabled={login.login === null ? true : false}
                              onClick={() => {
                                handleLoginStatusChange(
                                  login.external_id,
                                  login.status,
                                  login.accountId
                                );
                              }}
                            >
                              <Switch.Thumb className="SwitchThumb" />
                            </Switch.Root>
                            <MDButton
                              sx={{ ml: 0.5 }}
                              ize="medium"
                              variant="gradient"
                              color={`info`}
                              onClick={() => editLogin(login)}
                              iconOnly
                            >
                              <Pencil weight="fill" size={16} />
                            </MDButton>
                          </>
                        ) : (
                          <>
                            <MDButton
                              sx={{ ml: 0.5 }}
                              ize="medium"
                              variant="gradient"
                              color={`info`}
                              onClick={() => editLogin(login)}
                              iconOnly
                            >
                              <Pencil weight="fill" size={16} />
                            </MDButton>
                          </>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="emptyIcon">
                    <WarningOctagon size={200} weight="bold" />
                    <span>Este cliente não possui logins</span>
                  </div>
                )}
              </MDBox>
            </div>
          </div>
        </div>
        <div className="profileBtns">
          <button
            type="button"
            className="saveProfileBtn"
            onClick={() => {
              handleUpdateCustomer(user.id);
            }}
          >
            {isLoading === true ? (
              <CircleNotch size={18} weight="bold" className="isLoading" />
            ) : (
              'Salvar'
            )}
          </button>
        </div>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}
