/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable */
// Images
import DataTable from 'examples/Tables/DataTable/index.js';
import ChannelModal from '../../../components/ChannelModal/index.jsx';
import DeleteProgrammerGroupChannelModal from '../../../components/DeleteProgrammerGroupChannelModal/index.jsx';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { CheckCircle, XCircle } from 'phosphor-react';
// import MDBox from '../../../components/MDBox';

export default function ChannelTable({ channels, loading, setupdate, isDetails = false }) {
  const myRows = [];

  function handleChannelListChange(data) {
    if (data.success === true) {
      successNotify(data.message);
      setTimeout(() => {
        setupdate();
      }, 1500);
    } else {
      errorNotify(data.message);
    }
  }

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  function renderMyRows(item) {
    if (isDetails) {
      return myRows.push({
        name: (
          <MDBox>
            <MDTypography variant="caption" color="text">
              Canal:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
                {item.name}
              </MDTypography>
            </MDTypography>
          </MDBox>
        ),
        logo: (
          <MDBox>
            <MDTypography>
              <img src={item.url} alt="" className={isDetails ? 'thumbChannelDetails' : 'thumbChannel'} />
            </MDTypography>
          </MDBox>
        ),
        number: (
          <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: 'flex-start', sm: 'center' }}
              flexDirection={{ xs: 'row', sm: 'row' }}
              flex={1}
            >
              <div className="numberBlock">
                <span className="number">{item.number}</span>
              </div>
            </MDBox>
          </MDBox>
        ),
      });
    }

    return myRows.push({
      number: (
        <MDBox width="100%" display="flex" flexDirection="column" alignItems="left">
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            flexDirection={{ xs: 'row', sm: 'row' }}
            flex={1}
          >
            <div className="numberBlock">
              <span className="number">{item.number}</span>
            </div>
          </MDBox>
        </MDBox>
      ),
      logo: (
        <MDBox>
          <MDTypography>
            <img src={item.url} alt="" className="thumbChannel" />
          </MDTypography>
        </MDBox>
      ),
      name: (
        <MDBox>
          <MDTypography variant="caption" color="text">
            Canal:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
              {item.name}
            </MDTypography>
          </MDTypography>
        </MDBox>
      ),
      group: (
        <MDBox>
          <MDTypography variant="caption" color="text">
            Grupo de canais:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
              {item.channelGroup.name}
            </MDTypography>
          </MDTypography>
        </MDBox>
      ),
      action: (
        <MDBox width="100%" display="flex" alignItems="flex-end" flexDirection="column" border>
          <MDBox display="flex" alignItems="right" gap={1}>
            <ChannelModal
              size="small"
              data={item}
              iconVariant="edit"
              title={`Editar Canal ${item.name}`}
              handleChannelListChange={handleChannelListChange}
            />
            <DeleteProgrammerGroupChannelModal
              data={item}
              canais={true}
              handleChannelListChange={handleChannelListChange}
            />
          </MDBox>
        </MDBox>
      ),
    });
  }

  if (channels.length > 0) {
    channels.forEach((item) => {
      renderMyRows(item);
    });
  }

  function myTableData() {
    if (isDetails) {
      return {
        columns: [
          { Header: 'Número', accessor: 'number', width: '10%', align: 'center' },
          { Header: 'Logo', accessor: 'logo', width: '10%', align: 'center' },
          { Header: 'Nome', accessor: 'name', width: '35%', align: 'left' },
        ],
        rows: myRows,
      };
    }

    return {
      columns: [
        { Header: 'Número', accessor: 'number', width: '10%', align: 'center' },
        { Header: 'Logo', accessor: 'logo', width: '10%', align: 'center' },
        { Header: 'Nome', accessor: 'name', width: '35%', align: 'left' },
        { Header: 'Grupo de Canais', accessor: 'group', width: '35%', align: 'left' },
        { Header: '', accessor: 'action', width: '10%', align: 'center' },
      ],
      rows: myRows,
    };
  }

  return (
    <>
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <DataTable
        table={myTableData()}
        isSorted={true}
        entriesPerPage={isDetails ? false : true}
        noEndBorder
        pagination={{ variant: 'gradient', color: 'warning' }}
      />
    </>
  );
}
