/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState, useEffect } from 'react';
import axios from 'axios';
// Material Dashboard 2 React example components
import { Card, Grid } from '@mui/material';
// import ResaleModal from 'components/ResaleModal';
import { useSelector } from 'react-redux';
import MyUserTable from '../../components/MyUserTable/index.jsx';

// import Footer from '../../examples/Footer';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import MDTypography from '../../components/MDTypography';
import MDBox from '../../components/MDBox';

import './style.css';
import OperatorModal from '../../components/OperatorModal/index.jsx';
import { baseUrl } from '../../utils/base_url';

function UsersPage() {
  const storeUpdate = useSelector((state) => state.resale.updateResale);
  const [isFiltered, setIsFiltered] = useState(false);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [query, setQuery] = useState('');
  const [typeSearch, setTypeSearch] = useState('usuario');

  function handleSearchEvent(e) {
    if (e === 'Enter') {
      // axios.get(`http://localhost:3001/operators/${query}`).then((data) => {
      //   if (!data.data.length <= 0) {
      //     setUsers(data.data);
      //   }
      // });
      setQuery(search);
      setIsFiltered(true);
    }
  }
  useEffect(() => {
    axios.get(`${baseUrl}/operators`).then((data) => setUsers(data.data));
  }, [storeUpdate]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <OperatorModal />
      <MDBox mt={6} mb={3}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="info"
              display={{ sm: 'flex' }}
              alignItems={{ sm: 'center' }}
              justifyContent={{ sm: 'space-between' }}
            >
              <MDTypography variant="h6" color="white">
                Usuarios
              </MDTypography>
              <div className="select" style={{ width: 'auto', marginRight: '30px' }}>
                <input
                  id="myInput"
                  className="myInput"
                  placeholder={`${typeSearch}...`}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={(e) => handleSearchEvent(e.key)}
                />
                <select
                  name="tipo"
                  id="tipo"
                  style={{
                    border: '1px solid #fff',
                    color: '#fff',
                    marginLeft: '5px',
                  }}
                  defaultValue=""
                  onChange={(e) => {
                    setTypeSearch(e.target.value);
                  }}
                >
                  <option value="">
                    Selecione uma opção
                  </option>
                  <option value="usuario">
                    usuario
                  </option>
                  <option value="permissão">permissão</option>
                </select>
              </div>
            </MDBox>
            <MDBox pt={3}>
              <MyUserTable users={users} filtered={isFiltered} type={typeSearch} query={query} />
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default UsersPage;
