/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { Modal } from '@mui/material';
import { CircleNotch, TrashSimple } from 'phosphor-react';
import { useState } from 'react';
import MDButton from '../../../components/MDButton';

/* eslint-disable jsx-a11y/label-has-associated-control */
export default function DeleteModal({ data, handleDeletePlan }) {
  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setIsLoading(false);
    setOpen(false);
  };

  const handleDelete = async () => {
    handleDeletePlan(data.id);
    handleClose();
  };

  return (
    <>
      <button
        type="button"
        style={{
          padding: '0.4rem 0.5rem',
          borderRadius: '8px',
          backgroundColor: '#F44335',
          display: 'flex',
          alignItems: 'center',
          border: '0',
        }}
        onClick={handleOpen}
      >
        <TrashSimple weight="fill" size={16} color='white' />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form action="" className="deleteform" style={{ width: '300px' }}>
          <h2 className="modalTitle" style={{ marginTop: '20px', marginBottom: '-10px' }}>
            Atenção
          </h2>
          <div>
            <div
              style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}
              className="formFields"
            >
              <h1 style={{ color: '#ccc', fontSize: '18px' }}>Deseja deletar {data.name} </h1>
            </div>
          </div>
          <div className="confirmDeleteBtn">
            <MDButton className="formBtn" color="error" variant="contained" onClick={handleClose}>
              Cancelar
            </MDButton>

            <MDButton
              className="formBtn"
              color="success"
              variant="contained"
              onClick={() => {
                handleDelete(data.id);
              }}
            >
              {isLoading === true ? (
                <CircleNotch size={18} weight="bold" className="isLoading" />
              ) : (
                'Confirmar'
              )}
            </MDButton>
          </div>
        </form>
      </Modal>
    </>
  );
}
