/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable */
import { Modal } from '@mui/material';
import axios from 'axios';
import { CheckCircle, Package, Pencil, XCircle } from 'phosphor-react';
import { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import ProgrammerModal from 'components/ProgrammerModal';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import SearchInput from './components/SearchInput.jsx';
import { baseUrl } from '../../utils/base_url';

import MDButton from '../MDButton';

import './channelGroupStyle.css';

export default function ChannelGroupModal({
  data,
  title,
  variant,
  color,
  iconVariant,
  className,
  btnText,
  showListChannels = true,
  handleListGroupsChange,
  modalOpen = false,
  handleEditGroup = () => ({}),
}) {
  const role = useSelector((state) => state.auth.user.role);
  const isEditing = data?.id;
  const [groupName, setGrouplName] = useState('');
  const [value, setValue] = useState('');
  const [description, setDescription] = useState('');
  const [programmerList, setProgrammerList] = useState([]);
  const [programmer, setProgrammer] = useState('');
  const [groupChannels, setGroupChannel] = useState([]);
  const [channels, setChannels] = useState([]);
  const [update, setupdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterChannels, setFilterChannels] = useState('');
  const [fillChannels, setFillChannels] = useState([]);
  const [fillChannelsGroup, setFillChannelsGroup] = useState([]);

  const isFiltred = filterChannels.length > 0;

  const handleOpen = () => {
    setOpen(true);
    setValue(
      data?.value
        ? `${data.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`
        : ''
    );
    setGrouplName(data?.name ? data.name : '');
    setDescription(data?.description ? data.description : '');
    setProgrammer(data?.tvProgrammerId ? data.tvProgrammerId : '');
    setGroupChannel(data?.Channel ? data.Channel : []);
    setupdate(false);
    setChannels([]);
  };

  const handleSalePriceChange = (event) => {
    const rawValue = event.target.value;
    const onlyNumbers = rawValue.replace(/\D/g, ''); // remove tudo que não for número
    const formattedValue = Number(onlyNumbers) / 100; // divide por 100 para transformar em número decimal
    setValue(formattedValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));
  };

  const handleClose = () => {
    setOpen(false);
    setValue('');
    setGrouplName('');
    setDescription('');
    setProgrammer('');
    setGroupChannel([]);
    setChannels([]);
    handleEditGroup();
    setFilterChannels('');
  };

  function handleListProgrammsChange() {
    setupdate(!update);
  }

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const validator = (state) => {
    errorNotify(state.message);
    document.getElementById(state.id).classList.replace('inputField', 'inputFieldError');
  };

  function handleChannelGroup() {
    if (groupName?.length < 3) {
      validator({ message: 'Nome deve conter ao menos 3 caracteres', id: 'groupName' });
    } else {
      document
        .getElementById('groupName')
        .classList.replace('inputFieldError', 'inputFieldSuccess');
    }

    if (!programmer) {
      validator({ message: 'Necessario possui uma programadora', id: 'programmer' });
    } else {
      document
        .getElementById('programmer')
        .classList.replace('inputFieldError', 'inputFieldSuccess');
    }

    if (groupName?.length < 3 || !programmer) {
      return;
    }

    if (isEditing !== undefined) {
      const payload = {
        id: data.id,
        name: groupName,
        value: Number(value.split('R$')[1].split(',').join('.')),
        description: description,
        tvProgrammerId: programmer,
        channels: groupChannels.map((item) => item.id),
      };
      axios
        .patch(`${baseUrl}/channel-groups`, payload)
        .then((res) => {
          if (res.data.status === 400) {
            handleListGroupsChange({
              message: res.data.message,
              success: false,
            });
          } else {
            handleListGroupsChange({
              message: 'Grupo de Canais atualizado com sucesso',
              success: true,
            });
          }
          setTimeout(() => {
            handleClose();
          }, 500);
        })
        .catch((error) => console.log(error.response));
    } else {
      axios
        .post(`${baseUrl}/channel-groups`, {
          name: groupName,
          value: Number(value.split('R$')[1].split(',').join('.')),
          description,
          tvProgrammerId: programmer,
          channels: groupChannels.map((item) => item.id),
        })
        .then((res) => {
          if (res.data.status === 400) {
            handleListGroupsChange({
              message: res.data.message,
              success: false,
            });
          } else {
            handleListGroupsChange({
              message: 'Grupo de Canais atualizado com sucesso',
              success: true,
            });
          }
          setTimeout(() => {
            handleClose();
          }, 500);
        })
        .catch((error) => console.log(error));
    }
    setTimeout(() => {
      handleClose();
    }, 500);
  }

  function handleAddChannelToGroup(items) {
    const newListChannels = channels.filter((item) => item.id !== items.id);
    const newListFillChannels = fillChannels.filter((item) => item.id !== items.id);
    setFillChannels(newListFillChannels);
    setChannels(newListChannels);
    setGroupChannel([...groupChannels, items]);
    setFillChannelsGroup([...fillChannelsGroup, items]);
  }

  function handleRemoveChannelToGroup(items) {
    const newListChannels = groupChannels.filter((item) => item.id !== items.id);
    const newListFillChannelsGroup = fillChannelsGroup.filter((item) => item.id !== items.id);
    setGroupChannel(newListChannels);
    setFillChannelsGroup(newListFillChannelsGroup);
    setChannels([...channels, items]);
    setFillChannels([...fillChannels, items]);
  }

  useEffect(() => {
    axios.get(`${baseUrl}/channels`).then((channel) => {
      if (groupChannels?.length > 0) {
        const ListWithoutChannelIncludesInGrup = channel.data.filter((item) =>
          groupChannels.every((element) => element.id !== item.id)
        );
        setChannels(ListWithoutChannelIncludesInGrup);
      } else {
        setChannels(channel.data);
      }
    });
  }, [open === true]);

  useEffect(() => {
    axios.get(`${baseUrl}/tv-programmer`).then((res) => {
      setProgrammerList(res.data.payload);
    });
  }, [update]);

  useEffect(() => {
    channels?.sort((a, b) => a.number - a.number);
  }, [groupChannels]);

  useEffect(() => {
    groupChannels?.sort((a, b) => a.number - b.number);
  }, [groupChannels]);

  useEffect(() => {
    const channelsFiltred = channels.filter((item) => {
      return item.name.toLowerCase().includes(filterChannels.toLowerCase());
    });
    const channelsGroupFiltred = groupChannels.filter((item) => {
      return item.name.toLowerCase().includes(filterChannels);
    });
    setFillChannels(channelsFiltred);
    setFillChannelsGroup(channelsGroupFiltred);
  }, [filterChannels]);

  return (
    <div style={{ position: 'relative' }}>
      <span>
        {role !== 'operatorTubaron' && (
          <MDButton
            sx={{ ml: 0.5 }}
            ize="medium"
            variant="gradient"
            color={color ? color : 'info'}
            onClick={handleOpen}
            iconOnly={iconVariant === 'edit'}
          >
            {iconVariant === 'edit' ? <Pencil weight="fill" size={16} /> : null}
            {btnText}
          </MDButton>
        )}
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ position: 'absolute', top: '0' }}
      >
        <form action="" className="operatorForm">
          <SnackbarProvider
            iconVariant={{
              success: (
                <CheckCircle
                  size={16}
                  color="#ffffff"
                  weight="fill"
                  style={{ marginRight: '10px' }}
                />
              ),
              error: (
                <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
              ),
            }}
            dense
            autoHideDuration={2500}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
          <div className="formHeader">
            <div className="formLogo">
              <Package size={28} color="#ffffff" weight="fill" />
            </div>
          </div>
          <h2 className="modalTitle">{title}</h2>
          <div className="tariffFormGrid">
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="nome">Nome: </label>
              <input
                value={groupName}
                type="text"
                id="groupName"
                className="inputField"
                onChange={(e) => setGrouplName(e.target.value)}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="nome">Descrição: </label>
              <input
                value={description}
                type="text"
                id="description"
                className="inputField"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="formFields">
              <label htmlFor="nome">Valor: </label>
              <input
                value={`${value}`}
                id="price"
                placeholder="R$"
                className="inputField"
                onChange={handleSalePriceChange}
              />
            </div>

            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
              className="formFields"
            >
              <label>Programadoras</label>
              <select
                id="programmer"
                value={programmer}
                className="inputField"
                placeholder="seleciona uma opção"
                defaultChecked
                onChange={(e) => {
                  setProgrammer(e.target.value);
                }}
              >
                <option value="" disabled>
                  Escolha uma Programadora
                </option>
                {programmerList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <ProgrammerModal
                size="small"
                iconVariant="add"
                btnText="Criar Programadora"
                title="Criar Programadora"
                showListGroups={false}
                handleListProgrammsChange={() => handleListProgrammsChange()}
              />
            </div>

            {showListChannels ? (
              <>
                <div
                  style={{ display: 'flex', marginTop: '1rem', flexDirection: 'column' }}
                  className="formFields"
                >
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <SearchInput
                      label="Filtro de Canais"
                      placeholder="canais..."
                      value={filterChannels}
                      onChange={(search) => setFilterChannels(search)}
                    />
                    <label>Canais Disponíveis: {channels?.length} </label>
                  </div>
                  <div className="channels">
                    {isFiltred
                      ? fillChannels?.map((item) => (
                          <button
                            key={item.id}
                            type="button"
                            value={item.id}
                            style={{
                              background: 'transparent',
                              border: 'none',
                              display: 'flex',
                              gap: '1rem',
                            }}
                            onClick={() => handleAddChannelToGroup(item)}
                          >
                            <img
                              src={item.url}
                              alt={item.name}
                              style={{ width: '30px', height: '30px' }}
                            />
                            <span style={{ color: 'white' }}>{item.number}</span>
                            <span style={{ color: 'white' }}>{item.name}</span>
                          </button>
                        ))
                      : channels?.map((item) => (
                          <button
                            key={item.id}
                            type="button"
                            value={item.id}
                            style={{
                              background: 'transparent',
                              border: 'none',
                              display: 'flex',
                              gap: '1rem',
                            }}
                            onClick={() => handleAddChannelToGroup(item)}
                          >
                            <img
                              src={item.url}
                              alt={item.name}
                              style={{ width: '30px', height: '30px' }}
                            />
                            <span style={{ color: 'white' }}>{item.number}</span>
                            <span style={{ color: 'white' }}>{item.name}</span>
                          </button>
                        ))}
                  </div>
                </div>
                <div
                  style={{ display: 'flex', marginTop: '1rem', flexDirection: 'column' }}
                  className="formFields"
                  id="groupChannels"
                >
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <br />
                    <label>Canais que Pertecem ao grupo: {groupChannels?.length} </label>
                  </div>
                  <div className="channels">
                    {isFiltred
                      ? fillChannelsGroup?.map((item) => (
                          <button
                            key={item.id}
                            type="button"
                            value={item.id}
                            style={{
                              background: 'transparent',
                              border: 'none',
                              display: 'flex',
                              gap: '1rem',
                            }}
                            onClick={() => handleRemoveChannelToGroup(item)}
                          >
                            <img
                              src={item.url}
                              alt={item.name}
                              style={{ width: '30px', height: '30px' }}
                            />
                            <span style={{ color: 'white' }}>{item.number}</span>
                            <span style={{ color: 'white' }}>{item.name}</span>
                          </button>
                        ))
                      : groupChannels?.map((item) => (
                          <button
                            key={item.id}
                            type="button"
                            value={item.id}
                            style={{
                              background: 'transparent',
                              border: 'none',
                              display: 'flex',
                              gap: '1rem',
                            }}
                            onClick={() => handleRemoveChannelToGroup(item)}
                          >
                            <img
                              src={item.url}
                              alt={item.name}
                              style={{ width: '30px', height: '30px' }}
                            />
                            <span style={{ color: 'white' }}>{item.number}</span>
                            <span style={{ color: 'white' }}>{item.name}</span>
                          </button>
                        ))}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="buttonsCreateTariff">
            <MDButton
              onClick={() => handleClose()}
              className="formBtn"
              color="error"
              variant="contained"
            >
              Cancelar
            </MDButton>

            <MDButton
              onClick={() => handleChannelGroup()}
              className="formBtn"
              color="success"
              variant="contained"
            >
              {isEditing ? 'Editar' : 'Criar'}
            </MDButton>
          </div>
        </form>
      </Modal>
    </div>
  );
}
