import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tariffForm: {},
  isUpdateTariff: false,
  isSearch: false,
};

export const tariffFormSlice = createSlice({
  name: 'tariffForm',
  initialState,
  reducers: {
    setTariffFormState: (state, action) => {
      state.tariffForm = action.payload;
    },
    setIsUpdateTariff: (state, action) => {
      state.isUpdateTariff = action.payload;
    },
    setIsSearch: (state, action) => {
      state.isSearch = action.payload;
    },
  },
});

export const { setTariffFormState, setIsUpdateTariff, setIsSearch } = tariffFormSlice.actions;

export default tariffFormSlice.reducer;
