/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
// Material Dashboard 2 React example components
import { Card, CircularProgress, Grid, Tooltip } from '@mui/material';
// import ResaleModal from 'components/ResaleModal';
import { useSelector } from 'react-redux';

// import Footer from '../../examples/Footer';
import { Link, useLocation } from 'react-router-dom';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import './style.css';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { CalendarBlank, CheckCircle, FolderNotch, XCircle } from 'phosphor-react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { baseUrl } from 'utils/base_url';
import DataTable from 'examples/Tables/DataTable';
import MDButton from 'components/MDButton';

export default function TableWithPaymentList() {
  const [search, setSearch] = useState('');
  const role = useSelector((state) => state.auth.user.role);
  const [location, setLocation] = useState();
  const [paymentListFiltred, setPaymentListFiltred] = useState([]);
  const [isFiltred, setIsFiltred] = useState(false);
  const [typeSearch, setTypeSearch] = useState('status');
  const myRows = [];
  let locat = useLocation();

  useEffect(() => {
    if (role === 'resaleAdmin') {
      axios.get(`${baseUrl}/resale-payment`).then((res) => {
        setLocation(res.data.payload[0]);
      });
    }

    if (role === 'admin') {
      if (locat.state?.resaleID) {
        axios.get(`${baseUrl}/resale-payment`).then((res) => {
          res.data.payload.filter((item) => {
            if (item.id === locat.state.resaleID) {
              setLocation(item);
            }
          });
        });
      } else {
        setLocation(locat.state);
      }
    }
  }, []);

  const errorNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#bd2727',
      },
      variant: 'error',
    });
  };

  const successNotify = (message) => {
    enqueueSnackbar(message, {
      style: {
        backgroundColor: '#419744',
      },
      variant: 'success',
    });
  };

  function statusChanged(status) {
    if (status === 'analise') {
      return { message: 'Análise', class: 'payment pagamentoAnalise' };
    }

    if (status === 'pendente') {
      return { message: 'Pendente', class: 'payment pagamentoPendente' };
    }

    if (status === 'reprovado') {
      return { message: 'Recusado', class: 'payment pagamentoRecusado' };
    }

    if (status === 'confirmado') {
      return { message: 'Confirmado', class: 'payment pagamentoConfirmado' };
    }
    if (status === 'pago') {
      return { message: 'Pago', class: 'payment pagamentoPago' };
    }
    if (status === 'contestado') {
      return { message: 'Contestado', class: 'payment pagamentoRecusado' };
    }
    return { message: '', class: 'payment pagamentoPendente' };
  }

  function handleSearchEvent(e) {
    if (e === 'Enter' && location?.ResalePayment?.length > 0) {
      let paymentsFiltred = [];
      if (typeSearch === 'status') {
        paymentsFiltred = location?.ResalePayment.filter((payment) => {
          return (paymentsFiltred = payment.status.toLowerCase().includes(search.toLowerCase()));
        });
      }
      if (typeSearch === 'referencia') {
        paymentsFiltred = location?.ResalePayment.filter((payment) => {
          return (paymentsFiltred = payment.month_ref.toLowerCase().includes(search.toLowerCase()));
        });
      }

      if (search !== '') {
        setIsFiltred(true);
        successNotify('Filtro aplicado com sucesso!');
      } else {
        setIsFiltred(false);
      }
      setPaymentListFiltred(paymentsFiltred);
    }
  }

  function myTableData() {
    return {
      columns: [
        { Header: 'Refêrencia', accessor: 'month_ref', width: '10%', align: 'left' },
        { Header: 'Status', accessor: 'status', width: '10%', align: 'left' },
        { Header: 'Anotações', accessor: 'note', width: '30%', align: 'left' },
        { Header: 'Valor', accessor: 'value', width: '10%', align: 'left' },
        { Header: 'Protocolo', accessor: 'protocol', width: '10%', align: 'left' },
        { Header: '', accessor: 'action', width: '10%', align: 'center' },
      ],
      rows: myRows,
    };
  }

  if (location?.ResalePayment?.length > 0 && isFiltred === false) {
    location.ResalePayment.forEach((item) => {
      renderMyRows(item);
    });
  }

  if (isFiltred) {
    paymentListFiltred.forEach((item) => {
      renderMyRows(item);
    });
  }

  function renderMyRows(item) {
    return myRows.push({
      month_ref: (
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
            {item.month_ref.split(' ')[0].split('-').reverse().join('/')}
          </MDTypography>
        </MDBox>
      ),
      note: (
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
            {item?.note ? item.note : 'Sem anotações'}
          </MDTypography>
        </MDBox>
      ),
      status: (
        <MDBox>
          <div
            style={{ fontWeight: 'bold', variant: 'caption', fontSize: '16px', color: 'white' }}
            className={statusChanged(item.status).class}
          >
            {statusChanged(item.status).message}
          </div>
        </MDBox>
      ),
      protocol: (
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
            {item.protocol}
          </MDTypography>
        </MDBox>
      ),
      value: (
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" fontSize={16}>
            {Number(item.value?.toFixed(2))?.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
            })}
          </MDTypography>
        </MDBox>
      ),
      action: (
        <MDBox width="100%" display="flex" alignItems="flex-end" flexDirection="column" border>
          <MDBox display="flex" alignItems="right" gap={1}>
            <Link to="/resale/pagamentos/historico" state={item}>
              <MDButton sx={{ ml: 0.5 }} ize="medium" variant="gradient" color="info">
                <CalendarBlank weight="fill" size={16} style={{ marginRight: '8px' }} /> Histórico
              </MDButton>
            </Link>
          </MDBox>
        </MDBox>
      ),
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SnackbarProvider
        iconVariant={{
          success: (
            <CheckCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
          error: (
            <XCircle size={16} color="#ffffff" weight="fill" style={{ marginRight: '10px' }} />
          ),
        }}
        dense
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <MDBox mt={6} mb={3}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={1}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="warning"
              borderRadius="lg"
              coloredShadow="info"
              display={{ sm: 'flex' }}
              alignItems={{ sm: 'center' }}
              justifyContent={{ sm: 'space-between' }}
            >
              <MDTypography variant="h6" color="white">
                Pagamentos {location?.name}
              </MDTypography>
              <div className="select" style={{ width: 'auto', marginRight: '30px' }}>
                <input
                  id="myInput"
                  className="myInput"
                  placeholder="Pagamentos..."
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={(e) => handleSearchEvent(e.key)}
                />
                <select
                  name="tipo"
                  id="tipo"
                  style={{
                    border: '1px solid #fff',
                    color: '#fff',
                    marginLeft: '5px',
                  }}
                  onChange={(e) => {
                    setTypeSearch(e.target.value);
                  }}
                >
                  <option value="status">Status</option>
                  <option value="referencia">Refêrencia</option>
                </select>
              </div>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={myTableData()}
                isSorted={true}
                entriesPerPage={false}
                noEndBorder
                pagination={{ variant: 'gradient', color: 'warning' }}
              />
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
