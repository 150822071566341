/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import { Modal, Tooltip } from '@mui/material';

import axios from 'axios';
import { Eye, FileSearch } from 'phosphor-react';
import { useState, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import ChannelGroupModal from 'components/ChannelGroupModal';
import { baseUrl } from '../../utils/base_url';

import MDButton from '../MDButton';

import './programmerDetailStyle.css';

export default function ProgrammerDetailsModal({
  data,
  title,
  variant,
  color,
  iconVariant,
  className,
  btnText,
  handleListGroupsChange,
  handleListProgrammsChange,
  showListGroups = true,
}) {
  const role = useSelector((state) => state.auth.user.role);
  const [programmerGroups, setProgrammerGroups] = useState([]);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [channelGroup, setChannelGroup] = useState([]);
  
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEditGroup = (group) => {
    if (group) {
      setOpenModal(true);
    } else {
      setOpenModal(!openModal);
    }
    setChannelGroup(group);
  };

  useEffect(() => {
    axios.get(`${baseUrl}/channel-groups`).then((group) => {
      const arrayFiltred = group.data.payload.filter((item) => item.tvProgrammerId === data.id);
      setProgrammerGroups(arrayFiltred);
    });
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <span>
        {role !== 'operatorTubaron' && (
          <MDButton
            sx={{ ml: 0.5 }}
            ize="medium"
            variant="gradient"
            color="secondary"
            onClick={handleOpen}
            iconOnly={iconVariant === 'see'}
          >
            {iconVariant === 'see' ? <Eye size={18} /> : null}
            {btnText}
          </MDButton>
        )}
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ position: 'absolute', top: '0' }}
      >
        <form action="" className="operatorForm">
          <div className="formHeader">
            <div className="formLogo">
              <FileSearch size={36} color="#ffffff" weight="fill" />
            </div>
          </div>
          <h2 className="modalTitle">{title}</h2>
          <div className="programmerDetailsModal">
            <div className="containerProgrammerLogo">
              <img src={data?.url} alt={data.name} className="programmerLogo" />
            </div>
            <div className="channelGroupGrid">
              {programmerGroups?.map((item) => (
                <div type="button" className="containerGroup" key={item.id}>
                  {openModal ? (
                    <ChannelGroupModal
                      data={channelGroup}
                      title={`Editar Grupo ${channelGroup?.name}`}
                      handleListGroupsChange={handleListGroupsChange}
                      modalOpen={openModal}
                      handleEditGroup={handleEditGroup}
                    />
                  ) : (
                    <>
                    </>
                  )}
                  <div className="channelGruopContainer">
                    <div className="channelGroupDetails">
                      <div className="channelGroupText">
                        <h3>{item.name}</h3>
                        <span className="groupValue">
                          {Number(item.value?.toFixed(2))?.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      </div>
                    </div>
                    <div>
                      <p className="channelGroupDescription">{item.description}</p>
                      <span className="channelQuantityText">Quantidade de Canais: </span>
                      <span className="channelQuantity">{item.Channel.length}</span>
                    </div>
                  </div>
                  <div className="channelsDetailsText">
                    <span>Canais associados ao grupo: </span>
                  </div>
                  <div className="channelsContainer">
                    {item.Channel.map((channel) => (
                      <div key={channel.id} className="groupChannels">
                        <img src={channel.url} alt="" className="channelLogo" />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="buttonsCreateTariff">
            <MDButton
              onClick={() => handleClose()}
              className="formBtn"
              color="error"
              variant="contained"
            >
              Fechar
            </MDButton>
          </div>
        </form>
      </Modal>
    </div>
  );
}
